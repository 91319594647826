import React from 'react'
import { Formik, Form } from 'formik'
import { Button, Typography, Box, Container, Grid, useMediaQuery, Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { useCalendarManager } from '..'
import ScheduleIcon from '@material-ui/icons/Schedule'
import LockIcon from '@material-ui/icons/Lock'
import PublicIcon from '@material-ui/icons/Public'
import DescriptionIcon from '@material-ui/icons/Description'

import { FormikFieldGroup, CreateEditTopBar } from '../../shared'
import CalendarImgUpload from './CalendarImgUpload'

const CreateEditCalendarForm = () => {
    const { t } = useTranslation('', i18n)
    const { hasChanged, setHasChanged, validateCreateCal, croppedImg, updateCalendar, submitNewCalendar,
        isEditMode, initialValsCreate, initialValsEdit, calendar, selectedCalendar, validateEditCal } = useCalendarManager()
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const changeVisibilityValue =
        (value, setFieldValue, option1, option2) => value === option1 ? setFieldValue('visibility', value && option2) : null

    const isPrivate = visibilityValue => visibilityValue === 'private'
    const isPublic = visibilityValue => visibilityValue === 'public'

    return (selectedCalendar || !isEditMode) && (<Formik
        initialValues={selectedCalendar ? initialValsEdit : initialValsCreate}
        validate={selectedCalendar ? validateEditCal : validateCreateCal}
        onSubmit={isEditMode ? updateCalendar : submitNewCalendar}
    >{({
        setFieldValue, errors, values, dirty, isSubmitting, submitCount, submitForm
    }) => {
        setHasChanged((dirty || !!croppedImg))
        return <Form>
            <CreateEditTopBar name={isEditMode ? t('calendar.create.topbarEditTitle') : t('calendar.create.topbarCreateTitle')}
                hasChanged={hasChanged} saveChanges={submitForm} option={isEditMode ? 'save' : 'create'} />
            <Grid>
                <Container>
                    <Grid container >
                        <Grid item xs={12} md={6} lg={6}>
                            <LeftContainer disableGutters mobile={mobile ? 1 : 0} >

                                <CalendarNameInput
                                    disabled={selectedCalendar ? !calendar.currentUser.isOwner : false}
                                    name='name'
                                    type='text'
                                    placeholder={t('calendar.create.title')}
                                    defaultbackground={1}
                                    multiline
                                    rowsMax={2}
                                />

                                <CalendarVisibility>
                                    <VisibilityAction>
                                        <ClockIcon />
                                        <Typography component="h5" data-test-id='visibility-title'>
                                            {t('calendar.create.visibility')}
                                        </Typography>
                                        <div>
                                            <VisibilityBtn
                                                disableElevation
                                                variant="contained"
                                                disabled={selectedCalendar ? !calendar.currentUser.isOwner : false}
                                                color={isPrivate(values.visibility) ? 'primary' : 'default'}
                                                startIcon={<LockIcon size='small' />}
                                                onClick={e => changeVisibilityValue(values.visibility, setFieldValue, 'public', 'private')}
                                                data-test-id="switch-to-private"
                                                active={isPrivate(values.visibility) ? 1 : 0}
                                            >
                                                {t('calendar.create.private')} </VisibilityBtn>
                                            <Tooltip title={t('calendar.edit.visibilityTooltip')}
                                                disableHoverListener={selectedCalendar ? !selectedCalendar.isPersonal : true} placement="top-start" arrow >
                                                <span>
                                                    <VisibilityBtn
                                                        disableElevation
                                                        disabled={selectedCalendar ? (selectedCalendar.isPersonal || !selectedCalendar.isOwner) : false}
                                                        color={isPublic(values.visibility) ? 'primary' : 'default'}
                                                        active={isPublic(values.visibility) ? 1 : 0}
                                                        onClick={() => changeVisibilityValue(values.visibility, setFieldValue, 'private', 'public')}
                                                        variant="contained"
                                                        startIcon={<PublicIcon />}
                                                        data-test-id="switch-to-public"
                                                    >
                                                        {t('calendar.create.public')} </VisibilityBtn>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </VisibilityAction>

                                    <VisibilityDescription variant='body2' data-test-id="visibility-text">
                                        {isPublic(values.visibility) ? t('calendar.create.publicVisibility') : t('calendar.create.privateVisibility')}
                                    </VisibilityDescription>
                                </CalendarVisibility>

                                <CalendarDescription>
                                    <DescriptionIcon />
                                    <FormikFieldGroup
                                        name='details'
                                        type='text'
                                        placeholder={t('calendar.create.description')}
                                        defaultbackground={1}
                                        multiline
                                        rows="10"
                                        padding={12}
                                    />
                                </CalendarDescription>

                                {selectedCalendar ? <CalendarImgUpload imageUrl={calendar.coverPhotoLink} />
                                    : <CalendarImgUpload />}
                                {errors.image && !croppedImg && (<ErrorMsg>{errors.image}</ErrorMsg>)}
                                <EditCalendarBtn
                                    disabled={isSubmitting}
                                    color='primary'
                                    type='submit'
                                    size='small'
                                    variant='contained'
                                    data-test-id={isEditMode ? 'edit-calendar-btn' : 'create-calendar-btn'}
                                >
                                    {isEditMode ? t('button.saveChanges') : t('button.createCalendar')}
                                </EditCalendarBtn>

                            </LeftContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

        </Form>
    }}
    </Formik >)
}

const LeftContainer = styled(Container)`
    padding-top: 20px;
    width:${({ mobile }) => mobile ? 'unset' : '480px'};
    padding-bottom: 20px;
`
const CalendarNameInput = styled(FormikFieldGroup)`
    font-weight: bold;
    color: #1b1f21;

    &.MuiInputBase-root.Mui-disabled {
        color: #1b1f21;
    }
`

const CalendarVisibility = styled(Box)`
    margin-top: 20px;
`

const VisibilityDescription = styled(Typography)`
    margin-top: 24px;
    color: #9FA2A4;
`

const VisibilityBtn = styled(Button)`
    padding: 0px 10px;
    text-transform: none;
    border-radius: 0px;
    ${({ color, theme }) => color === 'primary' ? '' : {
        'background-color': `${theme.palette.background.default}`,
        'color': '#9FA2A4'
    }};

    &:disabled {
        ${({ active, theme }) => active && {
        'background-color': theme.palette.primary.main,
        'color': theme.palette.primary.contrastText
    }};
    }
`

const EditCalendarBtn = styled(Button)`
    margin-top: 20px;
    width: 100%;
    text-transform: none;
`

const VisibilityAction = styled(Box)`
    display: flex;
    align-content:center;

    div:last-child{
        margin-left: auto;
    }
`
const ClockIcon = styled(ScheduleIcon)`
    margin-right: 18px;
`

const CalendarDescription = styled(Box)`
    display:flex;
    margin-top: 40px;
    svg {
        margin-right: 18px;
    }
`

const ErrorMsg = styled(Typography)`
    color: ${({ theme }) => theme.palette.error.main};
    font-size: 12px;
    text-align: right;
`

export default CreateEditCalendarForm