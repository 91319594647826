import { REFRESH_EVENT_DETAILS, SET_EVENT_DETAILS, SET_LIVE_STREAMS } from '..'

const initialState = {
    data: {},
    streams: [],
    error: null
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_EVENT_DETAILS: {
            const payload = action.payload

            if (payload.error) {
                return {
                    ...state,
                    error: payload.error
                }
            }

            return {
                ...state,
                data: payload
            }
        }
        case REFRESH_EVENT_DETAILS: {
            return {
                ...state,
                data: {}
            }
        }
        case SET_LIVE_STREAMS: {
            return {
                ...state,
                streams: action.payload.streams
            }
        }
        default:
            return state
    }
}