/* eslint-disable */
import React from 'react'

export const ProfileIcon = ({
    width = 20,
    height = 20,
    filled
}) => <svg xmlns="http://www.w3.org/2000/svg" width={ width } height={ height } viewBox="0 0 20 20">
        { filled
            ? <path fill="#127AB3" fillRule="evenodd"
                d="M14.523 9.8A5.98 5.98 0 0 0 15.896 6c0-3.314-1.36-6-5.896-6-4.535 0-5.896 2.686-5.896 6a5.98 5.98 0 0 0 1.373 3.8h-.012l.03.022a6.18 6.18 0 0 0 1.17 1.087c.28.346.5.731.5 1.09 0 .152-.013.304-.035.452a99.153 99.153 0 0 0-3.535.843c-1.64.418-2.842 1.642-3.123 3.128l-.456 2.413C-.1 19.445.433 20 1.134 20h17.732c.7 0 1.233-.554 1.118-1.164l-.456-2.413c-.28-1.486-1.482-2.71-3.123-3.128a99.322 99.322 0 0 0-3.535-.843 3.07 3.07 0 0 1-.035-.452c0-.359.22-.744.5-1.09a6.18 6.18 0 0 0 1.17-1.087l.03-.023h-.012z" />
            : <path fill="#1B1F21" fillRule="evenodd"
                d="M15.896 5.999a5.98 5.98 0 0 1-1.373 3.8h.012l-.03.022a6.18 6.18 0 0 1-1.17 1.088c-.28.346-.5.73-.5 1.09 0 .152.013.303.035.452 1.156.248 2.438.562 3.535.842 1.64.419 2.842 1.642 3.123 3.128l.456 2.414c.115.61-.417 1.164-1.118 1.164H1.134c-.7 0-1.233-.555-1.118-1.164l.456-2.414c.28-1.486 1.482-2.709 3.123-3.128 1.097-.28 2.38-.594 3.535-.842.022-.149.035-.3.035-.452 0-.36-.22-.744-.5-1.09a6.18 6.18 0 0 1-1.17-1.088l-.03-.022h.012a5.98 5.98 0 0 1-1.373-3.8c0-3.314 1.36-6 5.896-6 4.535 0 5.896 2.686 5.896 6zm-1.36 0c0 2.048-.947 3.49-2.344 4.199-.239.374-.718 1.2-.718 1.801 0 .423.063.957.123 1.367l4.111.946c1.289.297 2.263 1.23 2.492 2.386l.37 1.866c.025.123-.082.235-.223.235H1.653c-.141 0-.248-.112-.223-.235l.37-1.866c.23-1.157 1.203-2.09 2.492-2.386l4.11-.946c.06-.41.124-.944.124-1.367 0-.6-.48-1.427-.718-1.801-1.397-.71-2.343-2.151-2.343-4.199 0-3 1.134-4.8 4.535-4.8 3.401 0 4.535 1.8 4.535 4.8z"
                opacity=".4" />
        }
    </svg>
