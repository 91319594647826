import React, { useEffect, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card, CardMedia, Container, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
const CalendarList = ({ calendars }) => {
    const location = useLocation()
    const { t } = useTranslation('', i18n)

    const scrollToCalendar = useCallback(() => {
        const locState = location.state
        const getCalendarIdIndex = () => locState?.from.includes('edit') ? 2 : 1
        const getCalendarId = () => {
            if (locState?.calendarId) {
                return locState.calendarId
            }

            if (locState?.from && locState.from.match(/([^/]+)/g)) {
                return locState.from.match(/([^/]+)/g)[getCalendarIdIndex()]
            }
        }

        const calendarCardElement = document.querySelector(
            `[data-test-id='calendar-card-${ getCalendarId() }']`
        )
        if (calendarCardElement) {
            const topBarElement = document.querySelector(
                '[data-test-id=\'top-bar\']'
            )
            if (topBarElement) {
                const calendarCardPosition = calendarCardElement.getBoundingClientRect().top
                const offsetPosition = calendarCardPosition - topBarElement.clientHeight
                window.scrollTo({ top: offsetPosition })
            }
        }
    }, [location])

    useEffect(() => {
        scrollToCalendar()
    }, [scrollToCalendar])

    return <ContainerList>
        { calendars.length > 0 ? calendars.map(item =>
            <CalendarLink
                to={ `calendars/${ item.calendarId }/events` }
                key={ item.calendarId }
            >
                <CalendarCard elevation={ 0 } data-test-id={ `calendar-card-${ item.calendarId }` }>
                    <Grid container>
                        <Grid item xs={ 12 } sm lg={ 4 }>
                            <StyledCardMedia
                                image={ item.coverPhotoLink }
                                title={ item.coverPhotoLink }
                                data-test-id='calendar-cover-photo'
                            />
                        </Grid>
                        <CalendarCardContent item xs={ 12 } sm lg={ 8 } container>
                            <Grid item xs={ 12 }>
                                <CalendarTitle gutterBottom variant='h5' component='span' data-test-id='calendar-title'>
                                    { item.name }
                                </CalendarTitle>
                            </Grid>
                            <CardDetails item xs={ 12 }>
                                <CalendarType component='span' data-test-id='calendar-type'>
                                    { item.visibility === 'public' ? <LanguageOutlinedIcon /> : <LockOutlinedIcon /> }
                                    { t(`calendar.type.${ item.visibility }Calendar`) }
                                </CalendarType>
                            </CardDetails>
                        </CalendarCardContent>
                    </Grid>
                </CalendarCard>
            </CalendarLink>
        ) : <NoResultsMessage data-test-id='no-calendars-message'>{ t('emptyMessages.calendarsList') }</NoResultsMessage>
        }
    </ContainerList>
}

const ContainerList = styled(Container)`
    height: 100%;
    padding-bottom: 16px;
`

const CalendarCard = styled(Card)`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    display: flex;
    margin-top: 20px;
`

const CalendarCardContent = styled(Grid)`
    display: flex;
    padding: 10px;
`

const StyledCardMedia = styled(CardMedia)`
    border-radius: 4px;
    height: 135px;
    margin: 10px;
    min-width: 240px;
`

const CalendarTitle = styled(Typography)`
    display: -webkit-box;
    font-family: ${ ({ theme }) => theme.typography.fontFamily };
    font-weight: 700;
    max-height: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: text-top;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
`

const CardDetails = styled(Grid)`
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
`

const CalendarType = styled(Typography)`
    align-items: center;
    color: ${ ({ theme }) => theme.palette.secondary.main };
    display: flex;
    font-size: 18px;
    text-transform: capitalize;
`

const NoResultsMessage = styled(Typography)`
    color: ${ ({ theme }) => theme.palette.secondary.main };
    margin-top: 24px;
    text-align: center;
`

const CalendarLink = styled(Link)`
    all: unset;
    cursor: pointer;
`

CalendarList.propTypes = {
    calendars: PropTypes.arrayOf(PropTypes.shape())
}

export default CalendarList