import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { SimpleDialog, FormikFieldGroup } from '../../shared'
import { Formik } from 'formik'
import { reportProfile } from '../services/profile.datasource'
import { useAlerts } from '../../alerts'

const ReportModal = ({ handleCloseDialog, isReportModalOpen, setIsReportModalOpen, userId }) => {
    const { t } = useTranslation('', i18n)
    const { showSnack } = useAlerts()

    const handleReportSubmit = async values => {
        const status = await reportProfile(userId, { reason: values.reason.trim() })

        if (status === 201) {
            setIsReportModalOpen(false)
            showSnack(t('profile.report.successText'))
        } else if (status === 429) {
            setIsReportModalOpen(false)
            showSnack(t('profile.report.alreadyReported'), 'error')
        } else {
            setIsReportModalOpen(false)
            showSnack(t('profile.report.fail'))
        }
    }

    const ReportField = () => <FormikFieldGroup
        name='reason'
        type='text'
        placeholder={ t('profile.report.reason') }
        elevation={ 2 }
        padding={ 12 }
        multiline
    />

    return <Formik
        initialValues={ {
            reason: '',
        } }
        onSubmit={ handleReportSubmit }
    >{ ({ values }) => <SimpleDialog
        open={ isReportModalOpen }
        title={ t('profile.report.title') }
        handleClose={ () => handleCloseDialog() }
        handleOk={ () => handleReportSubmit(values) }
        saveText={ t('button.submit') }
    >
        <ReportField />
    </SimpleDialog> }
    </Formik>
}

ReportModal.propTypes = {
    userId: PropTypes.string,
    handleCloseDialog: PropTypes.func,
    isReportModalOpen: PropTypes.bool,
    setIsReportModalOpen: PropTypes.func
}

export default ReportModal