/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const ArrowShrinkIcon = ({ size = 24, color = '#FFF' }) => (
    <svg aria-hidden="true"
         focusable="false" width={ size } height={ size }
         style={{
             transform: 'rotate(360deg)'
         }}
         preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
        <path d="M448 92.3l-81.9 81.8L416 224H288V96l49.9 49.9L419.7 64z" fill={ color }/>
        <path d="M448 419.7l-81.9-81.8L416 288H288v128l49.9-49.9 81.8 81.9z" fill={ color }/>
        <path d="M64 419.7l81.9-81.8L96 288h128v128l-49.9-49.9L92.3 448z" fill={ color }/>
        <path d="M64 92.3l81.9 81.8L96 224h128V96l-49.9 49.9L92.3 64z" fill={ color }/>
    </svg>
)

ArrowShrinkIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
}