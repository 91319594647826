import React from 'react'
import styled from 'styled-components'
import { Container, Divider, Grid } from '@material-ui/core'
import { CalendarContent, CalendarCover, CalendarProvider, CalendarTopBar } from '../'

const CalendarScreen = () => {
    return <CalendarProvider>
        <StyledGrid>
            <CalendarTopBar />
            <CalendarCover />
            <Container data-test-id='divider-wrapper'>
                <StyledDivider />
            </Container>
            <CalendarContent />
        </StyledGrid>
    </CalendarProvider>
}

const StyledDivider = styled(Divider)`
    margin-bottom: 16px;
`

const StyledGrid = styled(Grid)`
    height: 100%;
    padding-bottom: 40px;
`

export default CalendarScreen