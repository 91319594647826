import React, { useEffect, useRef, useCallback } from 'react'
import { getGoogleApiKey } from '../constants'
import PropTypes from 'prop-types'

const GOOGLE_MAP_API_KEY = getGoogleApiKey()

const mapStyles = {
    width: '100%',
    height: '400px',
}

function GoogleMaps(props) {

    const googleMapRef = React.createRef()
    const googleMap = useRef(null)
    const marker = useRef(null)
    const lat = props.location && props.location.coordinates && props.location.coordinates.split(',')[0]
    const lng = props.location && props.location.coordinates && props.location.coordinates.split(',')[1]

    const removeGoogleMapScript = useCallback(() => {
        let keywords = ['maps.googleapis'];
        window.google = undefined;
        let scripts = document.head.getElementsByTagName("script");
        for (let iter = scripts.length - 1; iter >= 0; iter--) {
            let scriptSource = scripts[iter].getAttribute('src');
            if (scriptSource != null) {
                if (keywords.filter(item => scriptSource.includes(item)).length) {
                    scripts[iter].remove();
                }
            }
        }
    }, [])

    const addGoogleMapScript = useCallback(() => {
        removeGoogleMapScript();
        let node = document.createElement('script');
        node.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
        node.type = 'text/javascript';
        node.async = false;
        node.id = 'location'
        node.charset = 'utf-8';
        document.head.appendChild(node);
        return node
    }, [removeGoogleMapScript])

    const createGoogleMap = useCallback(() =>
        new window.google.maps.Map(googleMapRef.current, {
            zoom: 17,
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            }
        }), [lat, lng, googleMapRef])

    const createMarker = useCallback(() =>
        new window.google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: googleMap.current
        }), [lat, lng, googleMap])

    const onLoadGoogleMap = useCallback(() => {
        if (googleMapRef.current) {
            googleMap.current = createGoogleMap()
            marker.current = createMarker()
        }
    }, [createGoogleMap, createMarker, googleMapRef])

    useEffect(() => {
        let googleMapScript;
        if (googleMapRef.current) {
            googleMapScript = addGoogleMapScript();
            googleMapScript.addEventListener('load', onLoadGoogleMap)
        }

    }, [onLoadGoogleMap, googleMapRef, addGoogleMapScript])

    return (
        lat && lng ? <div
            id='google-map'
            ref={googleMapRef}
            style={mapStyles}
        /> : <div>No Address found</div>
    )
}

GoogleMaps.propTypes = {
    location: PropTypes.object
}

export default GoogleMaps