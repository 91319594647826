import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Grid, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { UserSchema } from '..'
import UserAvatar from './UserAvatar'

const UserCard = ({ user }) => {
    const { t } = useTranslation('', i18n)

    return (
        <StyledListItem data-test-id='user-card-item' >
            <StyledListItemAvatar>
                <UserAvatar user={ user } size='52' />
            </StyledListItemAvatar>
            <StyledGrid container alignItems={ 'center' } >
                <StyledUserName primary={ user.name } disableTypography data-test-id='user-card-name' />
                { user.permissions.isOwner &&
                    <StyledUserCalendarRole primary={ t('people.owner') }
                        disableTypography data-test-id='user-card-calendar-role' />
                }
                { !user.isConfirmed && <StyledUserCalendarRole primary={ 'Invitation Sent' }
                    disableTypography data-test-id='confirmed-user' />
                }
            </StyledGrid>

            { user.role &&
                <StyledUserRole primary={ t(`people.${ user.role.name.toLowerCase() }`) }
                    disableTypography data-test-id='user-card-role' /> }
        </StyledListItem>
    )
}

const StyledListItem = styled(ListItem)`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    border-radius: 4px;
    height: 80px;
    margin-bottom: 20px;
`

const StyledListItemAvatar = styled(ListItemAvatar)`
    margin-right: 24px;
`

const StyledUserName = styled(ListItemText)`
    display: -webkit-box;
    font-size: 18px;
    flex: unset;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const StyledUserCalendarRole = styled(ListItemText)`
    font-size: 14px;
    flex: unset;
    opacity: 0.4;
`

const StyledUserRole = styled(ListItemText)`
    font-size: 14px;
    font-weight: bold;
    margin-right: 30px;
    text-align: end;
    text-transform: capitalize;
`

const StyledGrid = styled(Grid)`
    margin-right: 16px;
`

UserCard.propTypes = {
    user: PropTypes.shape(UserSchema)
}

export default UserCard