import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import propType from 'prop-types'
import { Routes } from './Routes'
import { fetchActivityFeed } from '../activity'
import { useAccount } from '../account'
import { fetchAccount } from '../profile'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import { Pusher } from '../sockets'

import debounce from '../shared/hooks/debounceHook'

const NavigatorContext = createContext({})

const NavigatorProvider = ({ children }) => {
    const location = useLocation()
    const [routes, setRoutes] = useState(Routes)
    const [unreadCount, setUnreadCount] = useState(0)
    const [update, setUpdate] = useState(true)
    const { account: { userId } } = useAccount()
    const [isCompleteProfile, setIsCompleteProfile] = useState(true)

    useAsyncEffect(function* () {
        if (!update) {
            return
        }

        const feed = yield fetchActivityFeed(0, 1)
        setUnreadCount(feed.unreadCount)
        setUpdate(false)
    }, [update])

    useAsyncEffect(function* () {
        const { hasUpdatedProfile } = yield fetchAccount(userId)
        setIsCompleteProfile(hasUpdatedProfile)
    }, [])

    useEffect(() => {
        const appendForBadge = theRoute => {
            if (theRoute.path === '/activity') {
                return { ...theRoute, showDot: unreadCount > 0 }
            } else if (theRoute.path === '/profile') {
                return { ...theRoute, showDot: !isCompleteProfile }
            } else {
                return theRoute
            }
        }
        setRoutes(prevState => prevState.map(route => appendForBadge(route)))
    }, [isCompleteProfile, unreadCount])

    const requestUpdate = debounce(() => setUpdate(true), 500)

    useEffect(() => {
        Pusher.bind('notification', requestUpdate)
        Pusher.bind('event', requestUpdate)

        return () => {
            Pusher.unbind(null, requestUpdate)
        }
    }, [requestUpdate])

    const clearNotifications = () => {
        setUnreadCount(0)
    }

    const decreaseNotification = () => {
        setUnreadCount(Math.max(unreadCount - 1, 0))
    }

    const isEventRelated = itemPath => {
        if (!location.pathname.includes('/event/edit') && !location.pathname.includes('/event/create')) {
            return false
        }

        const isFromCalendar = itemPath === 'calendars' && location.state && location.state.calendarId
        const isFromEvent = itemPath === 'events' && location.state && !location.state.calendarId
        return isFromCalendar || isFromEvent
    }

    const isNavigationTabActive = item => {
        const itemPath = item.path.substring(1)
        const path = location.pathname.substring(1)
        const from = location.state?.from ?? 'calendars'

        const isOnCalendars = itemPath === 'calendars' && path === ''
        const isEventDetailsOpenedFromCalendar = path.indexOf('events/') === 0
            && path.split('/')[1] !== ''
            && from !== '/events'
        const highlightCalendars = isEventDetailsOpenedFromCalendar && itemPath === 'calendars'
        const isCreatingCalendar = itemPath === 'calendars' && path === 'calendar/create'
        const isEditingCalendar = itemPath === 'calendars' && path.includes('calendar/edit')
        return isOnCalendars || isCreatingCalendar || isEditingCalendar || isEventRelated(itemPath) || highlightCalendars ||
            (!isEventDetailsOpenedFromCalendar && path.indexOf(itemPath) === 0 && itemPath.length > 0)
    }

    return <NavigatorContext.Provider value={ {
        routes,
        clearNotifications,
        decreaseNotification,
        isNavigationTabActive,
        setIsCompleteProfile
    } }>
        { children }
    </NavigatorContext.Provider>
}

NavigatorProvider.propTypes = {
    children: propType.object
}

export const useNavigation = () => useContext(NavigatorContext)

export default NavigatorProvider
