import { shape, number, string, bool, array } from 'prop-types'
import { CalendarPermissions } from './calendar.permission.schema'

const CalendarSchema = {
    ranking: shape({
        position: number,
        rank: number,
        activity: number,
        rating: number,
        isRecommended: bool,
        myRating: number
    }),
    users_count: number,
    isFeatured: bool,
    name: string,
    actionDate: string,
    lastActionType: string,
    details: string,
    color: string,
    visibility: string,
    coverPhotoLink: string,
    coverPhoto: shape({
        croppedUrl: string,
        url: string
    }),
    categories: array,
    attachments: array,
    allowsGlobalTimezone: bool,
    calendarId: string,
    defaultScreen: string,
    defaultPermissions: shape(CalendarPermissions),
    categoryIds: array,
    filters: array,
    calendarSettings: shape({
        enableETA: bool,
        enableNotices: bool,
        enableChatPushNotifications: bool,
        order: number
    }),
    currentUser: shape({
        name: string,
        email: string,
        status: string,
        userId: string,
        lastKnownLocation: shape({
            lat: number,
            lng: number,
            createdDate: string
        }),
        permissions: shape({
            canEdit: bool,
            canAdd: bool,
            canShare: bool,
            canView: bool
        }),
        isOwner: bool,
        lastActionType: string,
        actionDate: string,
        folderId: string
    }),
    isLimitedToOrganization: bool,
    owner: shape({
        name: string,
        email: string,
        status: string,
        userId: string,
        lastKnownLocation: shape({
            lat: number,
            lng: number,
            createdDate: string
        })
    }),
    linkedEntities: shape({
        chatId: string
    }),
    organizations: array,
    isOwner: bool,
    isPersonal: bool,
    folderId: string,
    permissions: shape(CalendarPermissions),
    calendarLink: shape({
        isOwner: bool,
        isPersonal: bool,
        lastActionType: string,
        actionDate: string,
        permissions: shape(CalendarPermissions),
        folderId: string,
        calendarId: string
    })
}

export {
    CalendarSchema
}
