import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import CategoryGroup from './CategoryGroup'
import CustomTextField from '../components/CustomTextField'

const ProfileUserDetails = ({ profile }) => {
    const { t } = useTranslation('', i18n)
    const { email, contact, privacy } = profile
    const showPhoneNumber = contact.phoneNumber && privacy?.phoneNumber
    const showUserDetails = privacy?.email || showPhoneNumber

    return <>{ showUserDetails ? <CategoryGroup id='profile-user-details' title={ t('profile.sections.userDetails') }>
        <>
            { privacy.email && <>
                <StyledLink
                    href={ `mailto:${ email }` }
                    target='_blank'
                    rel='noopener noreferrer'
                    data-test-id='email-link'
                >
                    <CustomTextField
                        label='Email'
                        defaultValue={ email }
                    />
                </StyledLink>

            </> }
            { showPhoneNumber && <CustomTextField
                label={ t('profile.properties.phone') }
                defaultValue={ contact.phoneNumber }
                placeholder={ t('profile.properties.empty.phone') }
            /> }
        </>
    </CategoryGroup> : null }</>
}

const StyledLink = styled.a`
    text-decoration: none;
    &, input {
        cursor: pointer;
    }
`

ProfileUserDetails.propTypes = {
    profile: PropTypes.shape({
        email: PropTypes.string,
        privacy: PropTypes.object,
        status: PropTypes.string,
        contact: PropTypes.shape({
            phoneNumber: PropTypes.string
        })
    }),
    resendConfEmail: PropTypes.func
}

export default ProfileUserDetails