import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Typography, useMediaQuery } from '@material-ui/core'

const IncompleteProfile = ({ isComplete }) => {
    const { t } = useTranslation('', i18n)
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

    return <>
        { !isComplete &&
            <IncompleteProfileText
                ismobile={ isMobile }
                align='center'
                variant='body1'
                data-test-id='incomplete-profile-text'>
                { t('profile.incompleteProfile') }
            </IncompleteProfileText> }
    </>
}

const IncompleteProfileText = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main };
    ${({ ismobile }) => ismobile ? {
        'position': 'absolute',
        'bottom': '15px',
        'margin': '5px'
    } : { 'margin-top': '35px' } }
`

IncompleteProfile.propTypes = {
    isComplete: PropTypes.bool
}

export default IncompleteProfile