import React from 'react'
import { useHistory } from 'react-router'

const Overrides = ({ children }) => {
    const history = useHistory()
    const originalPush = history.push

    history.push = (path, ...rest) => { //TODO proper implementation
        let pathname = path
        if (path instanceof Object) {
            pathname = path.pathname
            if (pathname === '/new') {
                pathname = '/chat/new'
            }
        }

        pathname = pathname.replace('conversations', 'chat/conversation')

        if (pathname === '/chat/conversation')
            pathname = '/chat'

        originalPush(pathname, ...rest)
    }

    return <>{children}</>
}

export default Overrides