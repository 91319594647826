import React, { useState, useCallback } from 'react'
import propType from 'prop-types'
import styled from 'styled-components'
import CircularImageUpload from './CircularImageUpload'
import { useProfile } from '../context/ProfileProvider'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { createCropPreview } from '../../shared/utils/imgUpload'
import CropImageModal from './CropImageModal'

const ProfilePictureUpload = ({ imageUrl }) => {
    const { croppedImg, setCroppedImg, handlePictureChange } = useProfile()
    const { t } = useTranslation('', i18n)
    const [imgSrc, setImgSrc] = useState(null)
    const [crop, setCrop] = useState({ aspect: 1 })
    const [imgRef, setImgRef] = useState(null)
    const [open, setOpen] = useState(false)

    const onLoadImage = useCallback(img => {
        setImgRef(img)
        setCrop({ unit: '%', width: 50, height: 50, aspect: 1 })
        return false
    }, [])

    const makeClientCrop = async crop => {
        if (imgRef && crop.width && crop.height) {
            const base64img = await createCropPreview(imgRef, crop)
            setCroppedImg(base64img)
        }
    }

    const handleModalClose = action => {
        if (action === 'save') {
            handlePictureChange()
        }
        setOpen(false)
    }

    return <ProfilePictureUploadWrapper>
        <CircularImageUpload
            croppedImg={ croppedImg }
            imageUrl={ imageUrl }
            setCroppedImg={ setCroppedImg }
            handlePictureChange={ handlePictureChange }
            setImgSrc={ setImgSrc }
            setCrop={ setCrop }
            setImgRef={ setImgRef }
            setOpen={ setOpen }
        />
        <CropImageModal
            title={ t('profile.edit.changePicture') }
            open={ open }
            setOpen={ setOpen }
            imgSrc={ imgSrc }
            crop={ crop }
            setCrop={ setCrop }
            onImageLoaded={ onLoadImage }
            makeClientCrop={ makeClientCrop }
            handleModalClose={ handleModalClose }
        />
    </ProfilePictureUploadWrapper>
}

const ProfilePictureUploadWrapper = styled.div`
    border-radius: 50%;
    height: 128px;
    overflow: hidden;
    margin: 0 auto;
    width: 128px;
`

ProfilePictureUpload.propTypes = {
    imageUrl: propType.string,
}

export default ProfilePictureUpload