import AgoraRTC from 'agora-rtc-sdk'
import { getAgoraSettings } from '../shared'
import { subscribeEvents } from './agora.events'
import { authAgoraChannel } from './service/livestream.datasource'
import { setLiveStreams } from '../eventDetails/redux/eventDetails.actions'

const initialState = {
    isInitialized: false,
    client: null,
    appKey: null,
    joining: false,

    // Object with keys: clientId: number, channelName: string
    channel: null,

    listeners: []
}

export let instance = {
    ...initialState
}

const configs = {
    dispatch: null
}

const storeAttach = store => {
    configs.dispatch = store.dispatch
}

// Since agora initializes fairly slow, we need to queue join commands
const initialize = async () => {
    if (instance.isInitialized)
        return true

    const { appKey } = getAgoraSettings()

    const result = await AgoraRTC.getSupportedCodec()
    const queryCodec = result && result.video.find(it => it.includes('264'))

    const client = await AgoraRTC.createClient({ mode: 'live', codec: queryCodec ? 'h264' : 'vp8' })
    await agoraPromise(client.init, appKey)

    instance.appKey = appKey
    instance.client = client
    instance.isInitialized = true

    subscribeEvents()

    console.debug('Agora.io initialized')
    return instance.isInitialized
}

const listen = fn => {
    instance.listeners.push(fn)
    return () => {
        const index = instance.listeners.findIndex(l => l === fn)
        instance.listeners.splice(index, 1)
    }
}

const join = async (channelName, userId) => {
    const { isInitialized, channel } = instance

    if (instance.joining) {
        return -1
    }

    if (channel) {
        return channel.clientId
    }

    instance.joining = true
    if (!isInitialized) {
        await initialize()
    }

    const { client } = instance

    console.log('Joining!', channelName)

    const { token } = await authAgoraChannel(channelName)
    const channelId = await agoraPromise(client.join, token, channelName, userId)
    const { uid } = await client.userAccountReq

    console.log('Joined channel!', channelId, uid)
    instance.queued = null

    instance.channel = {
        channelId,
        clientId: uid,
        channelName,
        streams: []
    }

    return uid
}

const leave = () => {
    const { client, channel } = instance

    if (channel) {
        client.leave()
        const localStream = channel.localStream
        if (localStream) {
            localStream.close()
            client.unpublish(localStream)
        }

        if (configs.dispatch)
            configs.dispatch(setLiveStreams([]))
    }

    instance = {
        ...initialState
    }
}

const getDevices = async () => {
    const tmpStream = AgoraRTC.createStream({
        audio: true,
        video: true,
        screen: false
    })

    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    const devices = await agoraPromise(AgoraRTC.getDevices)

    tmpStream.close()

    mediaStream.getTracks().forEach(track => {
        track.stop()
    })

    return devices
}

const setStreamSettings = (videoSource, audioSource) => {
    const { channel } = instance
    channel.cameraId = videoSource.deviceId
    channel.microphoneId = audioSource.deviceId
}

const createAndStart = async () => {
    const { microphoneId, cameraId } = instance.channel

    const stream = AgoraRTC.createStream({
        streamID: `${Date.now()}`,
        video: true,
        audio: true,
        microphoneId: microphoneId,
        cameraId: cameraId
    })

    await agoraPromise(stream.init)
    instance.channel.localStream = stream
    return stream
}

const publish = stream => {
    instance.client.publish(stream, err => {
        console.error(err)
    })
}

const startShareScreen = () => {
    // create stream with screen as criteria
    console.log('Share screens')
}

const stopStreaming = (stream) => {
    const { client, channel } = instance
    client.unpublish(stream)

    stream.close()

    channel.localStream = null
}

const checkShouldLeave = location => {
    const { channel } = instance

    if (!channel) {
        return false
    }

    // If we are out of the event, leave now
    if (location.pathname.lastIndexOf(channel.channelName) <= 0) {
        leave()
        return true
    }

    return false
}

const agoraPromise = async (fn2, ...params) =>
    await new Promise((resolve, reject) => {
        fn2(...params, result => resolve(result), error => reject(error))
    })


export default {
    storeAttach,
    initialize,
    listen,
    join,
    leave,
    getDevices,
    setStreamSettings,
    createAndStart,
    publish,
    startShareScreen,
    getChannel: () => instance.channel,
    stopStreaming,
    checkShouldLeave,
}