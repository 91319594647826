import React, { useCallback, useEffect, useState } from 'react'
import i18n from '../../../i18n'
import styled from 'styled-components'

import { Agora } from '../index'
import { Prompt, useHistory } from 'react-router'
import { ArrowBack } from '@material-ui/icons'
import { Button, Grid, IconButton } from '@material-ui/core'
import { ConfirmationDialog } from '../../shared'
import { useTranslation } from 'react-i18next'
import { MicIcon } from '../../shared/assets/MicIcon'
import { MicOffIcon } from '../../shared/assets/MicOffIcon'
import { StopIcon } from '../../shared/assets/StopIcon'

const StreamScreen = () => {
    const { t } = useTranslation('', i18n)

    const history = useHistory()
    const [audioMuted, setAudioMuted] = useState(false)
    const [showStop, setShowStop] = useState(false)

    const streamName = history.location.state?.streamName ?? 'No name'

    const returnToEvent = useCallback(() => {
        const pathname = history.location.pathname
        history.replace(pathname.slice(0, pathname.lastIndexOf('/')))
    }, [history])

    useEffect(() => {
        let stream = null
        Agora.createAndStart().then(e => {
            e.play('stream-player', { fit: 'contain' })
            Agora.publish(e)
            stream = e
        }).catch(() => {
            returnToEvent()
        })

        return () => {
            if (stream)
                Agora.stopStreaming(stream)
        }
    }, [history, returnToEvent])

    const muteAudio = () => {
        const { localStream } = Agora.getChannel()

        if (!audioMuted) {
            localStream.muteAudio()
        } else {
            localStream.unmuteAudio()
        }

        setAudioMuted(!audioMuted)
    }

    return (
      <FullscreenContainer>
          <PlayerContainer id='stream-player' />
          <ControlsBackground>
              <BackBtn onClick={ history.goBack } data-test-id='back-arrow'>
                  <ArrowBack/>
              </BackBtn>

              <ToolbarContent>
                  <h2>{ streamName }</h2>
                  {/* TODO Agora currently dose not support user count events, only getSessionStats for Chrome only */}
                  {/*<ViewerCount>*/}
                  {/*    <EyeIcon size={ 22 }/> { userCount }*/}
                  {/*</ViewerCount>*/}
              </ToolbarContent>
          </ControlsBackground>

          <ControlsBottom bottom={ 1 } container justify='center'>
              <Grid item>
                  <Button variant='contained' color='primary'
                          startIcon={ !audioMuted ? <MicIcon/> : <MicOffIcon/> }
                          onClick={ muteAudio }>{ audioMuted ? t('unmute') : t('mute') }</Button>
              </Grid>

              <Grid item>
                  <StopButton variant='contained' color='primary' startIcon={ <StopIcon/> }
                              onClick={ () => setShowStop(!showStop) }>{ t('stop') }</StopButton>
              </Grid>
          </ControlsBottom>

          <ConfirmationDialog open={ showStop } onClose={ () => setShowStop(false) }
                              title={ t('stream.stopMessage') } confirm={ returnToEvent }/>

          <Prompt when={ Agora.getChannel() && !showStop } message={ t('stream.backMessage') }/>
      </FullscreenContainer>
    )
}

export default StreamScreen

const FullscreenContainer = styled.div`
  height: (100% + (100vh - 100%));
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
  color: white;
`

const PlayerContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`
const ControlsBackground = styled(Grid)`
  display: flex;
  width: 100%;
  z-index: 3;
  padding: 24px;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.0));
`

const ControlsBottom = styled(ControlsBackground)`
  padding: 24px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.40));
`

const ToolbarContent = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px 0 0 0;
    margin: 0 0 0 24px;
  }
`

/*
const ViewerCount = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  svg {
    height: 100%;
    margin-right: 6px;
  }
`
*/

const StopButton = styled(Button)`
  background-color: ${ ({ theme }) => theme.palette.secondaryAccent.negativeFlat };
  :hover {
    background-color: ${ ({ theme }) => theme.palette.secondaryAccent.negativeHoverFlat };
  }
  
  svg {
    width: 30px;
  }
  
  margin-left: 12px;
`

const BackBtn = styled(IconButton)`
  color: white;
  padding: 0;
`
