
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { ResponsiveDialog, FormikPasswordField, PASSWORD_REGEX } from '../../shared'
import { changePassword } from '../services/profile.datasource'
import { useAlerts } from '../../alerts'

const ChangePasswordModal = ({ open, setOpen }) => {
    const { t } = useTranslation('', i18n)
    const { showSnack } = useAlerts()

    const initValues = {
        currentPassword: '',
        newPassword: '',
        confirmedPassword: ''
    }

    const handleModalClose = (resetForm) => {
        setOpen(!open)
        resetForm(initValues)
    }

    const validatePassword = (label, password) => {
        let errors = {}

        if (!password) {
            errors[label] = t('profile.passwordModal.requiredField')
        } else if (password.length < 6) {
            errors[label] = t('passwordValidations.passwordTooShort')
        } else if (password.length > 30) {
            errors[label] = t('passwordValidations.passwordTooLong')
        } else if (!PASSWORD_REGEX.test(password)) {
            errors[label] = t('passwordValidations.passwordInvalid')
        }

        return errors
    }

    const validate = values => {
        const errors = {}
        const currentError = validatePassword('currentPassword', values.currentPassword)
        const newError = validatePassword('newPassword', values.newPassword)
        const confirmedError = validatePassword('confirmedPassword', values.confirmedPassword)

        if (values.newPassword !== values.confirmedPassword &&
            values.newPassword !== '' && values.confirmedPassword !== '') {
            errors.confirmedPassword = t('passwordValidations.diffConfirmedPassword')
        }

        return { ...errors, ...currentError, ...newError, ...confirmedError }
    }

    const handlePasswordChange = async (values, { setFieldError, resetForm }) => {
        const { status } = await changePassword(values.currentPassword, values.newPassword)

        switch (true) {
            case (status === 204):
                handleModalClose(resetForm)
                showSnack(t('profile.passwordModal.changeSuccess'), 'success')
                break
            case (status === 400):
                setFieldError('currentPassword', t('passwordValidations.invalidCurrentPassword'))
                break
            case (status >= 500):
                setFieldError('confirmedPassword', t('passwordValidations.serverError'))
                break
            default:
                setFieldError('confirmedPassword', t('passwordValidations.somethingWrong'))
        }
    }

    return <Formik
        initialValues={ initValues }
        validate={ validate }
        onSubmit={ handlePasswordChange }
    >{ ({
        submitForm, resetForm
    }) => <Form>
            <ResponsiveDialog
                open={ open }
                title={ t('profile.passwordModal.title') }
                handleClose={ action => action === 'save' ? submitForm() : handleModalClose(resetForm) }
            >
                <PasswordsWrapper data-test-id='passwords-wrapper'>
                    <FormikPasswordField
                        name='currentPassword'
                        placeholder={ t('profile.passwordModal.currentPlaceholder') }
                    />
                    <FormikPasswordField
                        name='newPassword'
                        placeholder={ t('profile.passwordModal.newPlaceholder') }
                    />
                    <FormikPasswordField
                        name='confirmedPassword'
                        placeholder={ t('profile.passwordModal.confirmedPlaceholder') }
                    />
                </PasswordsWrapper>
            </ResponsiveDialog>
        </Form>
        }
    </Formik>

}

const PasswordsWrapper = styled.div`
    min-width: 280px;
`

ChangePasswordModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
}

export default ChangePasswordModal