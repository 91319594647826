import React from 'react'
import { CreateEditProvider, CalendarProvider } from '..'
import { useLocation } from 'react-router'
import CreateEditCalendarForm from '../components/CreateEditCalendarForm'

const CreateEditCalendar = () => <CreateEditProvider>
    <CreateEditCalendarForm />
</CreateEditProvider>

const EditCalendarScreen = () => {
    const location = useLocation()
    const isEdit = location.pathname.includes('/calendar/edit')

    return isEdit ? <CalendarProvider>
        <CreateEditCalendar />
    </CalendarProvider> : <CreateEditCalendar />
}


export default EditCalendarScreen
