import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import propType from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Button, useMediaQuery } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import debounce from '../../shared/hooks/debounceHook'
import { fetchCalendar, fetchCalendarUserList, getCalendarUser } from '../service/calendars.datasource'
import { CalendarAbout, CalendarEvents, CalendarPeople, CalendarSettings, fetchCalendarById, fetchCalendars } from '../'
import { Pusher } from '../../sockets'
import { useAlerts } from '../../alerts'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { getCalendarPermissions } from '../utils/calendars.utils'
import { queryEvents, reInitializeEvents } from '../../events'

const CalendarContext = createContext({})

const resultsPerPage = 30

const userListState = {
    userList: [],
    totalNumberOfUsers: 1,
    hasMoreUsers: false
}

const CalendarProvider = ({ children }) => {
    const { id } = useParams()
    const history = useHistory()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const [calendar, setCalendar] = useState({})
    const dispatch = useDispatch()

    const { t } = useTranslation('', i18n)

    const { showSnack } = useAlerts()
    const calendarPermissions = getCalendarPermissions(calendar)
    const calendarTabs = getCalendarTabs(calendar, calendarPermissions, history, t)

    const [searchString, setSearchString] = useState('')
    const [reInitEvents, setReInitEvents] = useState(false)
    const [calendarPeopleList, setCalendarPeopleList] = useState(userListState)
    const calendarUsers = calendarPeopleList.userList
    const searchFieldRef = useRef()

    const getActiveTabIndex = useCallback(() => {
        return calendarTabs.findIndex(tab =>
            history.location.pathname.indexOf(tab.label.toLowerCase()) > -1)
    }, [history, calendarTabs])

    const activeTab = Math.max(0, getActiveTabIndex())

    const requestUpdate = useCallback(notificationUpdate => {
        if (notificationUpdate && notificationUpdate.domain === 'calendarLink') {
            const calendarLink = notificationUpdate.payload
            const calendar = calendarLink.calendar

            if (calendarLink.calendarId === id && calendarLink.lastActionType === 'delete') {
                if (calendar.lastActionType !== 'delete') {
                    showSnack(t('calendar.alerts.calendarLinkRemoved'), 'error')
                } else {
                    showSnack(t('calendar.alerts.calendarRemoved'), 'error')
                    dispatch(fetchCalendars())
                }
                return history.replace('/calendars')
            }
        }

        dispatch(fetchCalendarById(id))
    }, [dispatch, history, id, showSnack, t])

    useEffect(() => {
        requestUpdate()
    }, [requestUpdate])

    useEffect(() => {
        Pusher.bind('calendar', requestUpdate)
        Pusher.bind('event', requestUpdate) // TODO this should be in the event list view
        Pusher.bind('calendarLink', requestUpdate)

        return () => {
            Pusher.unbind(null, requestUpdate)
        }
    }, [requestUpdate])

    useAsyncEffect(function* () {
        const response = yield fetchCalendar(id)
        if (response.error) {
            history.push('/404')
        }
        setCalendar(response)
    }, [id])

    const fetchUsers = useCallback(async () => {
        const data = await fetchCalendarUserList(id, resultsPerPage)
        const hasMoreUsers = () => data.totalNumberOfUsers > calendarPeopleList.userList.length
        setCalendarPeopleList({
            userList: data.users, totalNumberOfUsers: data.totalNumberOfUsers,
            hasMoreUsers: Boolean(hasMoreUsers)
        })

    }, [id, calendarPeopleList])

    const getUserByName = useCallback(async (userName) => {
        const data = await getCalendarUser(id, userName)
        setCalendarPeopleList({ userList: data.users, totalNumberOfUsers: data.totalNumberOfUsers })
        return data
    }, [id])

    const fetchMoreUsers = async () => {
        const userListLength = calendarUsers.length

        if (userListLength >= calendarPeopleList.totalNumberOfUsers) {
            setCalendarPeopleList({ ...calendarPeopleList, hasMoreUsers: false })
            return
        }

        const data = await fetchCalendarUserList(id, resultsPerPage, userListLength)
        const moreUsers = data.users
        setCalendarPeopleList({ ...calendarPeopleList, userList: calendarUsers.concat(moreUsers) })
    }

    const updateUsersList = useCallback(searchString => {
        if (searchString.length === 0) {
            fetchUsers()
            return
        }

        if (searchString.length < 4) {
            return
        }

        isMobile ? window.scrollTo(0, 304) : window.scrollTo(0, 0)
        getUserByName(searchString)

    }, [isMobile, getUserByName, fetchUsers])

    const searchUsers = debounce(ev => {
        const searchString = ev.target.value
        setSearchString(searchString)
        updateUsersList(searchString)
    }, 500)

    useEffect(() => {
        reInitEvents && dispatch(reInitializeEvents())
    }, [dispatch, reInitEvents])

    const searchEventsList = ev => calendar && calendar.calendarId && dispatch(queryEvents(ev.target.value, calendar.calendarId))

    return (
        <CalendarContext.Provider
            value={{
                calendarPeopleList, setCalendarPeopleList, fetchMoreUsers, getUserByName, fetchUsers,
                searchUsers, searchEventsList, calendar, dispatch, searchString, setSearchString,
                calendarTabs, activeTab, isMobile,
                searchFieldRef, setCalendar, setReInitEvents, calendarPermissions
            }}>
            {children}
        </CalendarContext.Provider>
    )
}

// TODO Refactor this, since CalendarProvider context is used for multiple screens,those without the CalendarDetails logic should not know of these tabs
export const getCalendarTabs = (calendar, permissions, history, t) => {
    const goToCreateEvent = () => history.push('/event/create', { calendarId: calendar.calendarId })


    const calendarTabs = [
        {
            label: 'Events',
            path: `/calendars/${calendar.calendarId}/events`,
            id: 'nav-tabpanel-events',
            'aria-controls': 'nav-tabpanel-events',
            component: <CalendarEvents calendar={calendar} />,
            hasSearch: true,
            hasActionButton: permissions.canAdd,
            actionComponent: <CreateEventButton
                color='primary'
                size='small'
                variant='contained'
                onClick={goToCreateEvent}
                data-test-id='create-event-btn'
            >{`+ ${t('button.createEvent')}`}</CreateEventButton>
        },

        {
            label: 'About',
            path: `/calendars/${calendar.calendarId}/about`,
            id: 'nav-tabpanel-about',
            'aria-controls': 'nav-tabpanel-about',
            component: <CalendarAbout calendar={calendar} />
        }
    ]

    if (permissions.canShare) {
        calendarTabs.splice(1, 0,
            {
                label: 'People',
                path: `/calendars/${calendar.calendarId}/people`,
                id: 'nav-tabpanel-people',
                'aria-controls': 'nav-tabpanel-people',
                component: <CalendarPeople />,
                hasSearch: true
            }, {
            label: 'Settings',
            path: `/calendars/${calendar.calendarId}/settings`,
            id: 'nav-tabpanel-settings',
            'aria-controls': 'nav-tabpanel-settings',
            component: <CalendarSettings />
        })


    }

    return calendarTabs
}

const CreateEventButton = styled(Button)`
    align-self: flex-end;
    margin-top: 20px;
    width: fit-content;
`



export const useCalendar = () => useContext(CalendarContext)

CalendarProvider.propTypes = {
    children: propType.object
}

export default CalendarProvider