import {
    apiService, dataURItoBlob, getApiUrl,
    S3_GALLERY, HttpError
} from '../../shared'

const prepareUploadToS3 = async () => {
    const { data, ok, status } = await apiService
        .put('/imageprocessor/authorise', {}, { baseURL: getApiUrl(), headers: { 'Content-Type': 'application/octet-stream' } })
    verifyOk(ok, status)
    return data
}

export const uploadImage = async (img, imgId) => {
    const credentials = await prepareUploadToS3()

    const fileName = imgId + '-size=original'
    const fd = new FormData()
    const type = 'image/jpeg'
    const fKey = 'photos/live/'
    const file = dataURItoBlob(img)

    fd.append('key', fKey + fileName)
    fd.append('acl', 'public-read')
    fd.append('Content-Type', type)
    fd.append('file', file)
    fd.append('AWSAccessKeyId', credentials.key)
    fd.append('policy', credentials.policy)
    fd.append('signature', credentials.signature)

    const { data, ok, status } = await apiService.post(S3_GALLERY, fd, { headers: {}, baseURL: {} })
    verifyOk(ok, status)
    return data
}

export const uploadCroppedImg = async (form, croppedImg) => {
    const imgId = new Date().getTime()
    await uploadImage(croppedImg, imgId)
    const formData = {
        ...form,
        coverPhoto: {
            croppedUrl: `${ S3_GALLERY }/photos/live/${ imgId }-size=medium`,
            url: `${ S3_GALLERY }/photos/live/${ imgId }-size=original`
        }
    }
    return formData
}

export const uploadProfilePicture = async (croppedImg) => {
    const imgId = new Date().getTime()
    await uploadImage(croppedImg, imgId)
    return `${ S3_GALLERY }/photos/live/${ imgId }-size=original`
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}