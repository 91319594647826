import React from 'react'
import PropTypes from 'prop-types'

function InfoIcon(props) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M-4-4h24v24H-4z' />
        <path
          fill={props.fill ? props.fill : '#49BEDB'}
          d='M7.99 6c-.51 0-.988-.5-.988-.999S7.501 4 
          8 4s.998.5.998 1.001C8.998 5.502 8.5 6 7.99 
          6zm1.008 5c0 .5-.498 1-.998 1s-.998-.5-.998-1V8c-.001-.5.498-.999.988-1 
          .51.001 1.008.5 1.008 1v3zM8 0C3.588 0 0 3.589 0 8s3.588 8 
          8 8c4.411 0 8-3.589 8-8s-3.589-8-8-8z'
        />
      </g>
    </svg>
  )
}

InfoIcon.propTypes = {
  fill: PropTypes.string
}

export default InfoIcon
