import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({

    button: props => ({
        width: '100%',
        padding: theme.spacing(1.25),
        fontSize: theme.spacing(2),
        textTransform: 'capitalize',
        fontWeight: 500,
        color: props.textColor,
        backgroundColor: props.bgColor,
        border: 0,
        '&:hover': {
            backgroundColor: props.bgColor,
            '@media (hover: none)': {
                backgroundColor: props.bgColor
            },
        },
    }),

}))

RegularButton.propTypes = {
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string
}

export default function RegularButton(props) {
    const { text, url } = props
    const classes = useStyles(props)

    return (
        <Button variant='contained' href={url} target="_blank" disableElevation={true} color='primary' disableRipple={true} className={classes.button} >
            {text}
        </Button>
    )
}