import React, { useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import styled from 'styled-components'
import { Box, useMediaQuery, Typography } from '@material-ui/core'
import { ResponsiveDialog, MiniUserCard, FormikFieldGroup, EMAIL_REGEX, Loader } from '..'
import debounce from '../../shared/hooks/debounceHook'
import { searchUser } from '../../calendars/index'
import { Formik } from 'formik'

const inviteReducer = (state, action) => {
    switch (action.type) {
        case 'pending':
            return {
                ...state,
                noResults: false,
                isFetching: true,
                selectedUser: undefined,
                hasError: false
            }
        case 'success':
            return action.payload.length === 0 ? {
                ...state,
                isFetching: false,
                people: action.payload,
                noResults: true
            } : {
                    ...state,
                    noResults: false,
                    isFetching: false,
                    people: action.payload
                }
        case 'fail':
            return {
                ...state,
                hasError: true,
                isFetching: false
            }
        case 'reset':
            return {
                ...state,
                ...action.payload
            }
        case 'select':
            return {
                ...state,
                selectedUser: action.payload
            }
        case 'unselect':
            return {
                ...state,
                selectedUser: undefined
            }
        default:
            return state

    }
}

const initialState = {
    people: [],
    noResults: false,
    isFetching: false,
    hasError: false,
    selectedUser: undefined
}

const InviteModal = ({ isInviteModalOpen, setIsInviteModalOpen, share }) => {
    const { t } = useTranslation('', i18n)
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const [list, dispatch] = useReducer(inviteReducer, initialState)

    const isValidAndUnregisteredEmail = email => list.noResults && EMAIL_REGEX.test(email)

    const getUser = async str => {
        dispatch({ type: 'pending' })
        const response = await searchUser(str)
        dispatch({ type: 'success', payload: response })
    }

    const updateUsersList = useCallback(txt => {
        if (txt.length === 0) {
            dispatch({ type: 'reset', payload: initialState })
            return
        }
        if (txt.length < 4) {
            return
        }
        getUser(txt)
    }, [])

    const searchUsers = debounce(txt => updateUsersList(txt), 500)

    return <Formik
        initialValues={ {
            name: '',      // name or email
        } }
    >{ ({ values, setFieldValue, resetForm }) => {

        const handleCloseModal = action => {
            action === 'save' && share(values, list)
            setIsInviteModalOpen(false)
            resetForm()
            dispatch({ type: 'reset', payload: initialState })
        }

        return <ResponsiveDialog
            open={ isInviteModalOpen }
            cancelText={ t('button.close') }
            title={ t('inviteUsers.title') }
            handleClose={ handleCloseModal }
            flexTitle={ true }
            confirmText={ t('button.continue') }
            disabledBtn={ list.selectedUser ? !list.selectedUser : !isValidAndUnregisteredEmail(values.name) }
        >
            <ModalChildren mobile={ isMobile ? 1 : 0 }>
                <FormikFieldGroup
                    name='name'
                    type='text'
                    placeholder={ t('inviteUsers.inputPlaceholder') }
                    onChange={ e => {
                        searchUsers(e.target.value)
                        setFieldValue('name', e.target.value)
                    } }
                    autoComplete='off'
                    elevation={ 2 }
                    padding={ 12 } />

                <>
                    { isValidAndUnregisteredEmail(values.name) ?
                        <NewUser>
                            <Typography>{ t('inviteUsers.newUser') }</Typography>
                            <MiniUserCard user={ { name: values.name } } />
                            <Typography variant='body2'>{ t('inviteUsers.inviteNewUser') }</Typography>
                            <EmailPreview variant='body2'>{ values.name }</EmailPreview>
                        </NewUser>
                        : <>
                            { !list.selectedUser && !(list.noResults && !EMAIL_REGEX.test(values.name)) && <Title>{ t('inviteUsers.select') }</Title> }
                            { list.noResults && !EMAIL_REGEX.test(values.name) && t('inviteUsers.notFound') }
                        </>
                    }
                    <div>
                        { !list.selectedUser ? <>
                            { list.isFetching ? <Loader /> :
                                <div>{ !list.noResults && list.people.map((user, i) => <MiniUserCard user={ user } key={ i } clickHandler={ () => dispatch({ type: 'select', payload: user }) } />) }</div> }
                        </> : <>
                                <Title variant='body1'>{ t('inviteUsers.inviteUserTitle') } </Title>
                                <MiniUserCard user={ list.selectedUser } clear={ () => dispatch({ type: 'unselect' }) } />
                                <Text variant='body2'>{ t('inviteUsers.inviteUser') } </Text>
                            </>
                        }
                    </div>
                </>
            </ModalChildren >
        </ResponsiveDialog>
    } }
    </Formik >
}

const ModalChildren = styled(Box)`
    width: ${({ mobile }) => mobile ? 'unset' : '400px' };
    max-height: 460px;
`

const NewUser = styled(Box)`
    margin-bottom: 10px;
`

const EmailPreview = styled(Typography)`
    font-weight: bold;
`

const Title = styled(Typography)`
    margin-bottom: 10px;
`

const Text = styled(Typography)`
    margin-top: 20px;
`

InviteModal.propTypes = {
    isInviteModalOpen: PropTypes.bool,
    setIsInviteModalOpen: PropTypes.func,
    share: PropTypes.func
}

export default InviteModal