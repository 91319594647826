import React, { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import styled from 'styled-components'
import { List, useMediaQuery, Box, Button } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'
import { ListInfiniteScroll, Loader, UserCard, InviteModal, getInvisibleRecaptchaSiteKey, EMAIL_REGEX } from '../../shared'
import { useCalendar, shareCalendar, shareMembers, removeUser } from '..'
import { useAlerts } from '../../alerts'

const CalendarPeople = () => {
    const { showSnack } = useAlerts()
    const { t } = useTranslation('', i18n)
    const { calendarPeopleList, fetchMoreUsers, fetchUsers, calendar } = useCalendar()
    const fetchUsersCallback = useCallback(fetchUsers, [])
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
    const [isUserValidated, setIsUserValidated] = useState(false)

    const shareCal = async (selectedUser, newUser) => {
        const permissionsObj = calendar.defaultPermissions

        const newMember = {
            calendarId: calendar.calendarId,
            email: newUser || selectedUser.email,
            name: newUser || selectedUser.name,
            permissions: permissionsObj
        }

        const { data } = await shareMembers(newMember)

        if (data) {
            const userInvite = {
                memberId: data.memberId,
                calendarId: data.calendarId,
                permissions: data.permissions
            }

            const { ok, status } = await shareCalendar(userInvite)

            if (status === 429) {
                removeUser({ calendarId: userInvite.calendarId, memberId: userInvite.memberId })
            }

            // fetching the user list until the realtime update is implemented
            await fetchUsers()
            return { ok, status }
        }
    }

    const shareCalendarHandler = async (values, list) => {
        const { name } = values
        const isNewUser = list.noResults && EMAIL_REGEX.test(name)
        let response

        if (isNewUser) {
            if (calendarPeopleList.userList.some(el => el.email === name)) { // check if the new user is already invited and still not registered
                showSnack(t('inviteUsers.notif.alreadyInvited'), 'error')
                return
            }
            response = await shareCal(list.selectedUser, name)
        } else {
            if (calendarPeopleList.userList.some(el => el.userId === list.selectedUser.userId)) { // check if the selected user is already invited
                showSnack(t('inviteUsers.notif.alreadyInvited'), 'error')
                return
            }
            response = await shareCal(list.selectedUser)
        }

        if (response.status === 429) {
            showSnack(t('event.errors.shareLimitReached'), 'error')
            return
        }

        response.ok ? showSnack(t('inviteUsers.notif.success')) : showSnack(t('inviteUsers.notif.fail'), 'error')
    }

    useEffect(() => {
        fetchUsersCallback()
    }, [fetchUsersCallback])

    const hasMoreUsers = calendarPeopleList.hasMoreUsers
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const recaptchaRef = React.createRef()

    const openShareModal = e => {

        // some recaptcha random reload triggers the onChange handler
        if (e === null) {
            return
        }

        setIsInviteModalOpen(true)
    }

    const validate = () => {
        if (isUserValidated) {
            openShareModal()
            return
        }

        recaptchaRef.current.execute()
        setIsUserValidated(true)
    }

    return (
        <>
            <Box display='flex' flexDirection='column'>
                <InviteButton
                    color='primary'
                    size='small'
                    variant='contained'
                    onClick={ validate }
                    data-test-id='invite-people-btn'
                > { t('button.shareCalendar') }</InviteButton>

                <StyledList ismobile={ isMobile ? 1 : 0 } data-test-id='calendar-people-list' >
                    <ListInfiniteScroll
                        dataLength={ calendarPeopleList.userList.length }
                        endDelimiterHeight={ 32 }
                        hasMore={ hasMoreUsers }
                        next={ () => fetchMoreUsers() }
                    >
                        { calendarPeopleList.userList ?
                            (calendarPeopleList.totalNumberOfUsers === 0 ? <NotFound>{ t('emptyMessages.peopleList') }</NotFound> :
                                (calendarPeopleList.userList.map((user, i) => {
                                    return <UserCard user={ user } key={ i } />
                                }))
                            ) : <Loader />
                        }
                    </ListInfiniteScroll>
                </StyledList>

                <ReCAPTCHAComponent
                    ref={ recaptchaRef }
                    size='invisible'
                    onChange={ openShareModal }
                    sitekey={ getInvisibleRecaptchaSiteKey() }
                />
            </Box>
            <InviteModal isInviteModalOpen={ isInviteModalOpen }
                setIsInviteModalOpen={ setIsInviteModalOpen }
                share={ shareCalendarHandler } />
        </>
    )
}

const ReCAPTCHAComponent = styled(ReCAPTCHA)`
    margin-left: auto;
`

const NotFound = styled.div`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    border-radius: 4px;
    height: 80px;
    margin-bottom: 20px;
    text-align: center;
    padding: 40px;
`

const StyledList = styled(List)`
    margin-top: ${({ ismobile }) => ismobile ? '0' : '12px' };
    height: 100%;
`

const InviteButton = styled(Button)`
    align-self: flex-end;
    width: fit-content;
`

export default CalendarPeople