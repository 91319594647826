import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormik } from 'formik'

import { Button, Dialog, DialogContent, DialogTitle, Grid, Input, MenuItem, Select } from '@material-ui/core'
import { useAgoraDialog } from './AgoraDialogProvider'
import { NAME_REGEX } from '../../shared'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { useAccount } from '../../account'

const CreateStreamModal = ({ eventName, eventId, open, dismiss }) => {
    const { videoSources, querySources, setStreamSettings, agoraError, createLiveStream } = useAgoraDialog()
    const history = useHistory()

    const { account } = useAccount()

    const { t } = useTranslation('', i18n)

    useEffect(() => {
        querySources(eventId, account.userId)
    }, [querySources])

    const noSource = videoSources.length === 0

    const formik = useFormik({
        initialValues: {
            source: 0
        },
        validate: values => {
            let errors = {}

            if (!values.feedName) {
                errors.feedName = t('stream.errors.nameRequired')
            } else if (!NAME_REGEX.test(values.feedName)) {
                errors.feedName = t('stream.errors.nameTest')
            }

            if (agoraError) {
                errors.permission = t('stream.errors.permissions')
            }

            return errors
        },
        onSubmit: values => {
            createStream(values)
        }
    })

    const createStream = values => {
        createLiveStream(eventId, values.feedName).then(() => {
            setStreamSettings(values.source)
            history.push(history.location.pathname + '/stream', {
                streamName: values.feedName
            })
        }).catch(() => {
            formik.setFieldError('feedName', t('stream.errors.failedCreateStream'))
        })
    }

    const isShareScreen = formik.values.source >= 100

    return <Dialog data-test-id='live-stream-create-dialog' open={open} onClose={dismiss}>
        <form onSubmit={formik.handleSubmit}>
            <CreateStreamDialogTitle>Start Live Video Streaming</CreateStreamDialogTitle>
            <DialogContent>
                {t('stream.createBody1', { eventName })}
                <p>{t('stream.createBody2')}</p>

                <SectionTitle>Source</SectionTitle>
                <Grid
                    container
                    direction='row'
                    justify='space-between'
                    spacing={1}
                >
                    <Grid item xs={12} sm={6}>  { /* TODO The highlight on 'source' + icon */}
                        <SourceSelect
                            data-test-id='live-stream-selector'
                            name='source'
                            disableUnderline
                            active={isShareScreen || noSource ? 0 : 1}
                            value={isShareScreen ? 0 : formik.values.source}
                            disabled={noSource}
                            fullWidth
                            defaultValue={0}
                            onChange={formik.handleChange}
                        >
                            {
                                noSource && <MenuItem value={0}>{t('stream.noSource')}</MenuItem>
                            }
                            {videoSources.map((item, index) =>
                                <MenuItem key={index}
                                    value={index}>{item.label ? item.label : `Video ${index}`}</MenuItem>
                            )}
                        </SourceSelect>
                    </Grid>
                    {/*
                    <Grid item xs={ 12 } sm={ 6 }>
                        <OptionButton fullWidth
                                      color='primary'
                                      variant={ isShareScreen ? 'contained' : 'outlined' }
                                      onClick={ () => formik.setFieldValue('source', 100) }>
                            Share Your screen</OptionButton>
                    </Grid>
*/ }
                </Grid>
                <ErrorLabel>{formik.errors.permission}</ErrorLabel>
                <ErrorLabel>{formik.errors.source}</ErrorLabel>

                <SectionTitle>Feed name</SectionTitle>

                <FeedNameInput fullWidth
                    data-test-id='live-stream-feed-name'
                    name='feedName'
                    disableUnderline
                    onChange={formik.handleChange}
                    placeholder={t('stream.yourFeedPlaceholder')} />
                <ErrorLabel>{formik.errors.feedName}</ErrorLabel>
                <StarStreamButton data-test-id='live-stream-start' fullWidth variant='contained' color='primary'
                    type="submit">
                    {t('stream.startStream')}
                </StarStreamButton>
            </DialogContent>
        </form>
    </Dialog>
}

CreateStreamModal.propTypes = {
    eventName: PropTypes.string,
    eventId: PropTypes.string,
    open: PropTypes.bool,
    dismiss: PropTypes.func
}

export default CreateStreamModal

const CreateStreamDialogTitle = styled(DialogTitle)`
  width: 100%;
  font-weight: 700;
  text-align: center;
`

const SectionTitle = styled.div`
  margin: 12px 0 4px 0;
  color: ${ ({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
`

/*
const OptionButton = styled(Button)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
*/

const FeedNameInput = styled(Input)`
  border-radius: 6px;
  padding: 4px 8px 4px 8px;
  background-color: ${ ({ theme }) => theme.palette.background.label};
`
const StarStreamButton = styled(Button)`
  margin: 12px 0 12px 0;
`

const SourceSelect = styled(Select)`
  text-align: center;
  border-radius: 6px;
  border: 1px solid #0075b8;
  
  color: ${ ({ active }) => active ? 'white' : 'inherit'};
  svg {
    color: ${ ({ active }) => active ? 'white' : 'inherit'};  
  }
  background-color: ${ ({ active, theme }) => active ? theme.palette.primary.main : 'inherit'};
`

const ErrorLabel = styled.div`
  color: ${ ({ theme }) => theme.palette.error.main};
`