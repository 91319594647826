import React from 'react'
import PropTypes from 'prop-types'

function UsersIcon(props) {
  return (
    <svg width={25} height={13} viewBox='0 0 25 13' {...props}>
      <path
        fill={props.fill ? props.fill : '#49BEDB'}
        fillRule='evenodd'
        d='M16.37 3.641c0 1.008-.437 1.92-1.142 2.58 2.184.86 
        4.047 3.048 4.047 5.161 0 .617-.534 1.118-1.19 
        1.118H6.915c-.657 0-1.19-.501-1.19-1.118 0-2.114 
        1.863-4.301 4.047-5.161-.705-.66-1.142-1.572-1.142-2.58C8.63 1.633 10.365 
        0 12.5 0c2.136 0 3.872 1.633 3.872 3.641zm-1.547 
        0c0-1.205-1.043-2.185-2.323-2.185s-2.323.98-2.323 
        2.185 1.042 2.185 2.323 2.185c1.28 0 2.323-.98 
        2.323-2.185zM11.63 7.282c-1.917 0-4.087 1.936-4.334 
        3.762h10.407c-.247-1.826-2.416-3.762-4.334-3.762H11.63zm-6.932 
        1.22h.594A8.005 8.005 0 017.886 5.82a4.54 4.54 0 01-.542-2.173c0-.133 
        0-.267.026-.388a3.3 3.3 0 00-2-.668c-1.73 0-3.15 1.323-3.15 2.962 
        0 .717.297 1.372.749 1.894C1.329 8.175 0 9.838 0 11.477c0 .558.49 
        1.02 1.097 1.02h3.64a2.247 2.247 0 01-.31-1.117c0-.11 0-.219.013-.34H1.6c.284-1.263 
        1.782-2.537 3.098-2.537zm.671-4.455c.878 0 1.6.668 1.6 1.505 0 .826-.722 1.494-1.6 
        1.494-.878 0-1.6-.668-1.6-1.494 0-.837.722-1.505 1.6-1.505zM22.78 5.552c0 .717-.297 
        1.372-.748 1.894C23.67 8.175 25 9.838 25 11.477c0 .558-.49 1.02-1.097 1.02H20.25c.207-.328.31-.716.31-1.117 
        0-.11 0-.219-.013-.34H23.4c-.284-1.263-1.782-2.537-3.098-2.537h-.58a8.171 8.171 0 00-2.608-2.684 4.539 
        4.539 0 00.542-2.173c0-.133 0-.267-.026-.388a3.3 3.3 0 012-.668c1.73 0 3.15 
        1.323 3.15 2.962zm-4.75 0c0 .826.723 1.494 1.6 1.494.879 0 1.601-.668 
        1.601-1.494 0-.837-.722-1.505-1.6-1.505-.878 0-1.6.668-1.6 1.505z'
        opacity={0.4}
      />
    </svg>
  )
}

UsersIcon.propTypes = {
  fill: PropTypes.string
}

export default UsersIcon
