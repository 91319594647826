import { array, arrayOf, bool, number, object, shape, string } from 'prop-types'
import { CalendarSchema } from '../../calendars/schemas/calendar.schema'
import { CalendarPermissions } from '../../calendars/schemas/calendar.permission.schema'

const EventSchema = {
    name: string,
    actionDate: string,
    lastActionType: string,
    details: string,
    startTimezone: string,
    endTimezone: string,
    startDate: object,
    endDate: object,
    isAllDay: bool,
    exceptions: array,
    coverPhotos: arrayOf(shape(
        {
            url: string,
            croppedUrl: string
        })),
    importSource: shape({
        ownedByProvider: bool
    }),
    attachments: arrayOf(shape({
        _id: string,
        data: shape({
            text: string
        }),
        type: string
    })),
    owner: string,
    tags: arrayOf(string),
    colorSettings: shape({
        type: string
    }),
    kind: string,
    extendedProperties: shape({
        shouldHideDates: bool,
        shouldHideLocation: bool
    }),
    rsvpType: number,
    rsvpStatistics: shape({
        pending: number,
        attending: number,
        notAttending: number,
        none: number
    }),
    rsvpLocked: bool,
    eventId: string,
    timezone: string,
    location: shape({
        radius: number
    }),
    originatingCalendarId: string,
    originatingCalendar: shape({
        ...CalendarSchema,
        userLink: shape({
            isOwner: bool,
            isPersonal: bool,
            lastActionType: string,
            actionDate: string,
            permissions: shape(CalendarPermissions),
            folder: string
        })
    }),
    usersWithPermissions: arrayOf(shape({
        userId: string,
        isOwner: bool,
        lastActionType: string,
        actionDate: string,
        linkCalendarId: string
    })),
    numberOfLinkedCalendars: number,
    occurrenceDate: string,
    occurrenceId: string,
    livestreams: shape({
        count: number,
        max: number,
        streams: array
    })
}

export {
    EventSchema
}