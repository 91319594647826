/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const StopIcon = ({ size = 24, color = '#FFF' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={ size }
    height={ size }
    viewBox="0 0 512 512"
  >
      <path fill={color} d="M392 432H120a40 40 0 01-40-40V120a40 40 0 0140-40h272a40 40 0 0140 40v272a40 40 0 01-40 40z"/>
  </svg>
)

StopIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
}