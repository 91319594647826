import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
    FETCH_CALENDAR_BY_ID,
    FETCH_MY_CALENDARS,
    setCalendars,
    setCalendarsLoader,
    UPDATE_CALENDAR
} from './calendars.actions'
import { fetchCalendar, fetchMyCalendars, updateCalendarSettings, orderCalendarsAndMovePersonalCal } from '..'

function* fetchCalendars() {
    let isSilent = yield select(state => state.calendars.items.length > 0)

    if (!isSilent) {
        yield put(setCalendarsLoader(true))  // The loader state shouldn't be here to begin with, it should be component bound
    }

    const response = yield call(fetchMyCalendars) // TODO Store last request date for delta updates & RT

    const calendarLinks = response.data


    const calendarsFromLinks = calendarLinks.map(link => {
        const { calendar, ...calendarLink } = link
        return {
            ...calendar,
            // mixing objects like this is discouraged, it will lead to confusion between api or other platforms
            // api DTOs should be documented for the next developer, best way to do this is keep the original api DTO pristine.
            isPersonal: link.isPersonal,
            isOwner: link.isOwner,
            permissions: link.permissions,
            folderId: link.folderId,

            // added a link key to combat this
            calendarLink
        }
    })

    const orderedCalendarsFromLinks = orderCalendarsAndMovePersonalCal(calendarsFromLinks)
    yield put(setCalendars(orderedCalendarsFromLinks))

    if (!isSilent) {
        yield put(setCalendarsLoader(false))
    }
}

function* updateListWithCalendar(calendar, id) {
    let updatedCalendarsList = yield select(state => state.calendars.items)

    updatedCalendarsList = updatedCalendarsList.map(cal => cal.calendarId === id ? calendar : cal)

    yield put(setCalendars(updatedCalendarsList))
}

function* fetchCalendarById(action) {
    const calendarId = action.payload
    const response = yield call(fetchCalendar, calendarId)

    updateListWithCalendar(response, calendarId)
}

function* updateCalendar(action) {
    const calendarId = action.payload.id
    const newCalendar = action.payload.newCalendar
    const response = yield call(updateCalendarSettings, calendarId, newCalendar)
    updateListWithCalendar(response, calendarId)
}

export default function* calendarsSaga() {
    yield takeLatest(FETCH_MY_CALENDARS, fetchCalendars)
    yield takeLatest(FETCH_CALENDAR_BY_ID, fetchCalendarById)
    yield takeLatest(UPDATE_CALENDAR, updateCalendar)
}
