import React, { createContext, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Agora } from '../index'
import { useAccount } from '../../account'
import { createStream } from '../service/livestream.datasource'

const AgoraDialogContext = createContext({})

const AgoraDialogProvider = ({ children }) => {
    const { account } = useAccount()

    const [videoSources, setVideoSources] = useState([])
    const [audioSources, setAudioSources] = useState([])
    const [agoraError, setAgoraError] = useState()

    const querySources = useCallback((channelName, userId) => {
        Agora.join(channelName, userId).then(r => {
            Agora.initialize().then(() => { // Make sure we're initialized
                Agora.getDevices().then(devices => {
                    setVideoSources(devices.filter(item => item.kind === 'videoinput'))
                    setAudioSources(devices.filter(item => item.kind === 'audioinput'))
                }).catch(err => {
                    setAgoraError(err)
                })
            })

        });

    }, [])

    const setStreamSettings = (sourceIndex) => {
        if (sourceIndex === 100) {
            //TODO screen share
        } else {
            Agora.setStreamSettings(videoSources[sourceIndex], audioSources.length ? audioSources[0] : { deviceId: 'default' })
        }
    }

    const createLiveStream = async (eventId, name) => {
        return await createStream(eventId, account.userId, name)
    }

    return <AgoraDialogContext.Provider value={{
        videoSources, querySources, setStreamSettings, agoraError, createLiveStream
    }}>
        {children}
    </AgoraDialogContext.Provider>
}

export const useAgoraDialog = () => useContext(AgoraDialogContext)

AgoraDialogProvider.propTypes = {
    children: PropTypes.object
}

export default AgoraDialogProvider