import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Container, Tooltip, Button, Grid, Typography } from '@material-ui/core'
import { CreateEditTopBar, FormikFieldGroup, Loader, FormikSwitchFieldGroup } from '../../shared/'
import ProfileProvider, { useProfile } from '../context/ProfileProvider'
import FormikProfileField from '../components/FormikProfileField'
import ChangePasswordModal from '../components/ChangePasswordModal'
import ProfilePictureUpload from '../components/ProfilePictureUpload'
import CategoryGroup from '../components/CategoryGroup'

const EditProfileScreen = () => {
    const { t } = useTranslation('', i18n)
    const { profile, validate, logout, hasChanged,
        setHasChanged, resendConfEmail, handleProfileSave, handlePrivacySwitch } = useProfile()
    const [isChangePassModalOpened, setIsChangePassModalOpened] = useState(false)
    const isProfileActive = profile?.status === 'active'

    const initialValues = {
        imageLink: profile?.imageLink,
        name: profile?.name,
        description: profile?.description,
        email: profile?.email,
        phoneNumber: profile?.contact.phoneNumber,
        linkedIn: profile?.contact.linkedIn,
        facebook: profile?.contact.facebook,
        privacyEmail: profile?.privacy.email,
        privacyPhone: profile?.privacy.phoneNumber,
        privacyFacebook: profile?.privacy.facebook,
        privacyLinkedIn: profile?.privacy.linkedIn
    }

    return <div>
        { profile ?
            <Formik
                initialValues={ initialValues }
                validate={ validate }
                onSubmit={ handleProfileSave }
            >{ ({
                dirty, submitCount, submitForm, handleBlur
            }) => {
                setHasChanged(dirty && submitCount === 0)
                return <Form>
                    <CreateEditTopBar
                        name={ t('profile.edit.pageTitle') }
                        saveChanges={ submitForm }
                        hasChanged={ hasChanged }
                        option='save'
                    />
                    <Container>
                        <Grid item xs={ 12 } md={ 6 } lg={ 6 }>
                            <CategoryContainer>
                                <ProfilePictureUpload imageUrl={ profile.imageLink } />
                                <ProfileName
                                    name='name'
                                    type='text'
                                    alignment='center'
                                    multiline
                                    rowsMax='2'
                                    data-test-id='edit-name'
                                />
                            </CategoryContainer>
                            <CategoryGroup id='edit-about-me' title={ t('profile.sections.aboutMe') }>
                                <Description
                                    name='description'
                                    placeholder={ t('profile.edit.description') }
                                    multiline
                                    rows={ 3 }
                                    fullWidth
                                    onBlur={ () => handleBlur('description', true, false) }
                                    data-test-id='edit-description-text'
                                />
                            </CategoryGroup>
                            <CategoryGroup id='edit-user-details' title={ t('profile.sections.userDetails') }>
                                <><FormikProfileField
                                    label='Email'
                                    name='email'
                                    type='text'
                                    disabled
                                    data-test-id='edit-email'
                                />
                                    { !isProfileActive && <ResendConfirmation
                                        onClick={ resendConfEmail }
                                        data-test-id='resend-confirmation-email'>
                                        <span>{ t('profile.notVerified') }</span>
                                        <span>{ t('profile.resendConfirmation') }</span>
                                    </ResendConfirmation> }
                                    <FormikProfileField
                                        label={ t('profile.properties.phone') }
                                        name='phoneNumber'
                                        type='text'
                                        placeholder={ t('profile.edit.placeholders.phone') }
                                        data-test-id='edit-phone'
                                    /></>
                            </CategoryGroup>
                            <CategoryGroup id='edit-social-profiles' title={ t('profile.sections.socialProfiles') }>
                                <><FormikProfileField
                                    label='LinkedIn'
                                    name='linkedIn'
                                    type='text'
                                    placeholder={ t('profile.edit.placeholders.linkedIn') }
                                    data-test-id='edit-linkedIn'
                                />
                                    <FormikProfileField
                                        label='Facebook'
                                        name='facebook'
                                        type='text'
                                        placeholder={ t('profile.edit.placeholders.facebook') }
                                        data-test-id='edit-facebook'
                                    /></>
                            </CategoryGroup>
                            <CategoryGroup id='edit-privacy' title={ t('profile.sections.privacySettings') }>
                                <><PrivacyExplanation data-test-id='privacy-explanation'>
                                    { t('profile.edit.privacyExplanation') }
                                </PrivacyExplanation>
                                    <SwitchWrapper>
                                        <FormikSwitchFieldGroup
                                            label='Email'
                                            name='privacyEmail'
                                            color='primary'
                                            onChange={ ev => handlePrivacySwitch(ev.target.checked, 'email') }
                                            checked={ profile.privacy.email }
                                            inputProps={ { 'aria-labelledby': 'switch-privacy-email' } }
                                        />
                                    </SwitchWrapper>
                                    <SwitchWrapper>
                                        <FormikSwitchFieldGroup
                                            label={ t('profile.properties.phoneNumber') }
                                            name='privacyPhone'
                                            color='primary'
                                            onChange={ ev => handlePrivacySwitch(ev.target.checked, 'phoneNumber') }
                                            checked={ profile.privacy.phoneNumber }
                                            inputProps={ { 'aria-labelledby': 'switch-privacy-phone' } }
                                        />
                                    </SwitchWrapper>
                                    <SwitchWrapper>
                                        <FormikSwitchFieldGroup
                                            label='Facebook'
                                            name='privacyFacebook'
                                            color='primary'
                                            onChange={ ev => handlePrivacySwitch(ev.target.checked, 'facebook') }
                                            checked={ profile.privacy.facebook }
                                            inputProps={ { 'aria-labelledby': 'switch-privacy-facebook' } }
                                        />
                                    </SwitchWrapper>
                                    <SwitchWrapper>
                                        <FormikSwitchFieldGroup
                                            label='LinkedIn'
                                            name='privacyLinkedIn'
                                            color='primary'
                                            onChange={ ev => handlePrivacySwitch(ev.target.checked, 'linkedIn') }
                                            checked={ profile.privacy.linkedIn }
                                            inputProps={ { 'aria-labelledby': 'switch-privacy-linkedIn' } }
                                        />
                                    </SwitchWrapper>
                                    <SaveButton
                                        variant='contained'
                                        color='primary'
                                        size='small' fullWidth
                                        type='submit'
                                        data-test-id='edit-save-btn'
                                    >
                                        { t('button.saveChanges') }
                                    </SaveButton></>
                            </CategoryGroup>
                            <CategoryGroup id='edit-profile-settings' title={ t('profile.sections.profileSettings') }>
                                <>{ !profile.socialId && <Tooltip
                                    disableFocusListener={ isProfileActive }
                                    disableHoverListener={ isProfileActive }
                                    disableTouchListener={ isProfileActive }
                                    title={ t('profile.changePassword') }
                                    placement='top'>
                                    <ButtonWrapper>
                                        <ActionButton
                                            disabled={ profile.status !== 'active' }
                                            fullWidth
                                            variant='outlined'
                                            color='primary'
                                            onClick={ () => setIsChangePassModalOpened(true) }
                                            data-test-id='profile-changePassword-btn'>
                                            { t('button.changePassword') }
                                        </ActionButton>
                                    </ButtonWrapper>
                                </Tooltip> }
                                    <ActionButton
                                        fullWidth
                                        variant='outlined'
                                        color='primary'
                                        onClick={ logout }
                                        data-test-id='profile-logout-btn'
                                    >{ t('button.logout') }
                                    </ActionButton></>
                            </CategoryGroup>
                        </Grid>
                    </Container>
                </Form>
            } }
            </Formik> : <Loader /> }
        <ChangePasswordModal open={ isChangePassModalOpened } setOpen={ setIsChangePassModalOpened } />
    </div>
}

const ButtonWrapper = styled.span`
    width: 100%;
`

const ActionButton = styled(Button)`
    background-color: ${ ({ theme }) => theme.palette.background.paper };
    margin-top: 8px;
    margin-bottom: 12px;
`

const CategoryContainer = styled(Grid)`
    padding: 0 0 12px 0;
    width: 100%;
`

const Description = styled(FormikFieldGroup)`
    background: ${ ({ theme }) => theme.palette.background.default };
    border-radius: 4px;
    line-height: 1.36;
    padding: 12px;
`

const PrivacyExplanation = styled(Typography)`
    color: ${ ({ theme }) => theme.palette.secondary.default };
    opacity: 0.4;
`

const ProfileName = styled(FormikFieldGroup)`
    color: ${ ({ theme }) => theme.palette.secondary.default };
    font-size: 24px;
    font-weight: bold;
`

const SwitchWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`

const SaveButton = styled(Button)`
    font-size: 16px;
    margin: 20px 0;
    text-transform: capitalize;
`

const ResendConfirmation = styled(Typography)`
    align-self: flex-end;
    cursor: pointer;
    font-size: 13px;
    height: 20px;
    margin-right: 6px;
    position: relative;
    text-align: right;
    top: -16px;

    span {
        color: ${({ theme }) => theme.palette.primary.main };

        &:first-child {
            color: ${({ theme }) => theme.palette.error.main };
        }
    }
`

export default () => {
    return <>
        <ProfileProvider>
            <EditProfileScreen />
        </ProfileProvider>
    </>
}