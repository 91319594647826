import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import NoteIcon from '@material-ui/icons/Note'
import AdvancedDesc from '../../shared/components/AdvancedDesc'
import PropTypes from 'prop-types'

function EventAdvancedDesc({ attachments }) {

    return (
        <EventAttachmentSection elevation={0}>
            {attachments && attachments.length > 1 && <Grid container spacing={2} justify='flex-start' >
                <Grid item > <NoteIcon></NoteIcon></Grid>
                <AdvancedDesc attachments={attachments}></AdvancedDesc>
            </Grid>}
        </EventAttachmentSection>
    )
}

EventAdvancedDesc.propTypes = {
    attachments: PropTypes.array
}

const EventAttachmentSection = styled(Paper)`
    ${ ({ theme }) => `
        padding-bottom: ${theme.spacing(3)}px;
        margin-right : ${theme.spacing(2)}px;  
    ` };
`

export default EventAdvancedDesc
