/* eslint-disable */
import React from 'react'

export const AllcalIcon = ({
      width = 20,
      height = 20,
      filled
}) => <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20'>
            {filled
                  ? <g fill='none' fillRule='evenodd'>
                        <path fill='#0075B8'
                              d='M18.536 10l.731-.732c.473-.473.733-1.1.733-1.77 0-.667-.26-1.295-.733-1.768l-5-4.999a2.502 2.502 0 0 0-3.536 0l-.732.73-.73-.73c-.945-.945-2.593-.945-3.538 0l-5 5a2.503 2.503 0 0 0 0 3.536l.732.732-.731.731a2.503 2.503 0 0 0 0 3.537l5 5a2.493 2.493 0 0 0 1.768.732c.64 0 1.28-.244 1.768-.732l.731-.731.732.73v.002c.487.487 1.128.73 1.768.73.64 0 1.281-.243 1.768-.731l5-5A2.486 2.486 0 0 0 20 12.5c0-.668-.26-1.297-.733-1.769l-.731-.73z' />
                        <path fill='#FFF'
                              d='M18.088 13.088l-5 5a.834.834 0 0 1-1.179 0l-5-5a.836.836 0 0 1 0-1.179l3.666-3.666c.005-.005.012-.006.016-.01.005-.005.006-.011.011-.016l1.307-1.308a.835.835 0 0 1 1.179 0l.002.001 4.998 5a.828.828 0 0 1 .245.589.828.828 0 0 1-.245.59z' />
                        <path fill='#FFF'
                              d='M8.09 18.088a.834.834 0 0 1-1.18 0l-5-5a.834.834 0 0 1 0-1.179l5-5a.834.834 0 0 1 1.18 0l.73.732-3.09 3.09A2.485 2.485 0 0 0 5 12.498c0 .668.26 1.296.732 1.768l3.09 3.09-.732.731z' />
                        <path fill='#FFF'
                              d='M1.91 8.088a.834.834 0 0 1 0-1.179l5-4.999a.853.853 0 0 1 1.18 0l3.338 3.34c-.25.119-.49.273-.697.48l-.732.732-.73-.732a2.503 2.503 0 0 0-3.538 0l-3.09 3.09-.73-.732zM11.91 1.91a.834.834 0 0 1 1.178 0l5 5a.828.828 0 0 1 0 1.178l-.73.732-3.09-3.09-.005-.004-3.085-3.085.731-.73z' />
                  </g>
                  : <path fill='#1B1F21' fillRule='evenodd'
                        d='M18.088 13.088l-5 5a.834.834 0 0 1-1.179 0l-5-5a.836.836 0 0 1 0-1.179l3.666-3.666c.005-.005.012-.006.016-.01.005-.005.006-.011.011-.016l1.307-1.308a.835.835 0 0 1 1.179 0l.002.001 4.998 5a.828.828 0 0 1 .245.589.828.828 0 0 1-.245.59zm-9.999 5a.834.834 0 0 1-1.179 0l-5-5a.834.834 0 0 1 0-1.179l5-5a.834.834 0 0 1 1.18 0l.73.732-3.09 3.09A2.485 2.485 0 0 0 5 12.498c0 .668.26 1.296.732 1.768l3.09 3.09-.732.731zm-6.179-10a.834.834 0 0 1 0-1.179l5-4.999a.853.853 0 0 1 1.18 0l3.338 3.34c-.25.119-.49.273-.697.48l-.732.732-.73-.732a2.503 2.503 0 0 0-3.538 0l-3.09 3.09-.73-.732zm10-6.178a.834.834 0 0 1 1.178 0l5 5a.828.828 0 0 1 0 1.178l-.73.732-3.09-3.09-.005-.004-3.085-3.085.731-.73zM18.535 10l.731-.732c.473-.473.733-1.1.733-1.77 0-.667-.26-1.295-.733-1.768l-5-4.999a2.502 2.502 0 0 0-3.536 0l-.732.73-.73-.73c-.945-.945-2.593-.945-3.538 0l-5 5a2.503 2.503 0 0 0 0 3.536l.732.732-.731.731a2.503 2.503 0 0 0 0 3.537l5 5a2.493 2.493 0 0 0 1.768.732c.64 0 1.28-.244 1.768-.732l.731-.731.732.73v.002c.487.487 1.128.73 1.768.73.64 0 1.281-.243 1.768-.731l5-5A2.486 2.486 0 0 0 20 12.5c0-.668-.26-1.297-.733-1.769l-.731-.73z'
                        opacity='.4' />

            }
      </svg>
