import React, { createContext, useContext, useEffect, useState } from 'react'
import propType from 'prop-types'
import { useHistory, useLocation } from 'react-router'
import { useStore } from 'react-redux'

import { authEmailPassword, authLoginToken, createAccount, logout, updateAccount, resetPassword } from '..'
import { Firebase, Pusher } from '../../sockets'
import { Agora } from '../../livestream'
import { useTranslation } from 'react-i18next'

import i18n from '../../../i18n'
import { useAlerts } from '../../alerts'

const AccountContext = createContext({})

const AccountProvider = ({ children }) => {


    const { t } = useTranslation('', i18n)
    const location = useLocation()
    const history = useHistory()
    const store = useStore()
    const { showSnack } = useAlerts();

    const storedAccountString = localStorage.getItem('account')
    const storedAccount = storedAccountString ? JSON.parse(storedAccountString) : null

    if (storedAccount) {
        Pusher.connect(storedAccount.loginToken, storedAccount.userId)
        updateAccount(storedAccount)
    }

    const [account, setAccount] = useState(storedAccount)

    useEffect(() => {
        if (account) {
            Firebase.messagingInit()
        }

        return () => {
            if (account) {
                Agora.leave()
            }
            Pusher.disconnect()
            Firebase.logout()
        }
    }, [account])

    useEffect(() => {
        Agora.storeAttach(store)
    }, [store])

    const authWithLoginToken = async (loginToken) => {
        const userResponse = await authLoginToken(loginToken)
        localStorage.setItem('account', JSON.stringify(userResponse))
        return setAccount(userResponse)
    }

    const isLoggedInWithSameLoginToken = (loginToken) => {
        return account && account.loginToken === loginToken
    }

    const autoLoginUser = async () => {
        const loginToken = location.pathname.split('auto-login/')[1].split('/')[0]

        if (!isLoggedInWithSameLoginToken()) {
            await authWithLoginToken(loginToken)
            history.push('/')
        }

        return account
    }

    const clearData = () => {
        localStorage.clear()
        logout().finally()
        updateAccount(null)
        setAccount(null)
    }

    const handleLoginSubmit = (values, {
        setFieldError
    }) => {
        authEmailPassword(values.email, values.password)
            .then(data => {
                localStorage.setItem('account', JSON.stringify(data))
                setAccount(data)
            }).catch(error => {
                console.error('Failed logging in!', error)
                error.statusCode === 404 && setFieldError('password', t('loginScreen.error.emailNotFound'))
                error.statusCode === 403 && setFieldError('password', t('loginScreen.error.wrongCredentials'))
            })
    }

    const handleRegister = (values, {
        setFieldError
    }) => {
        createAccount({ ...values, name: values.name.trim() })
            .then(data => {
                localStorage.setItem('account', JSON.stringify(data))
                setAccount(data)
            }).catch(error => {
                console.error('Failed Register!', error)
                showErrorMessage(error.statusCode, setFieldError)
            })
    }

    const showSnackBar = (msg, type) => {
        showSnack(msg, type)
    }

    const handleResetPwd = (values, { setFieldError }) => {


        resetPassword({ ...values }).then(data => {
            showSnackBar(t('registerScreen.resetPwd.success'), 'success');
            history.push('/login');

        }).catch(error => {
            setFieldError('email', t('registerScreen.resetPwd.error'))
        })

    }

    const showErrorMessage = (status, setFieldError) => {
        switch (status) {
            case 401: return setFieldError('password', t('registerScreen.error.deletedAccount'))
            case 403: return setFieldError('email', t('registerScreen.error.emailUsed'))
            default: return setFieldError('password', t('registerScreen.error.authFailed'))
        }
    }

    const updateAccountInLocalStorage = updatedAccount => {
        setAccount(updatedAccount)
        localStorage.setItem('account', JSON.stringify(updatedAccount))
    }

    return (
        <AccountContext.Provider value={{ account, clearData, autoLoginUser, handleLoginSubmit, handleRegister, handleResetPwd, updateAccountInLocalStorage }}>
            {children}
        </AccountContext.Provider>
    )
}

AccountProvider.propTypes = {
    children: propType.object
}

export const useAccount = () => useContext(AccountContext)

export default AccountProvider