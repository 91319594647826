import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  button: props => ({
    margin: theme.spacing(1),
    color: props.variant === 'contained' ? props.color : props.textColor,
    backgroundColor: props.variant === 'contained' ? props.textColor : props.color,
    border: props.textColor === theme.palette.background.paper ? '1px solid ' + props.color : '1px solid ' + props.textColor,
    '&:hover': {
      backgroundColor: props.variant === 'contained' ? props.textColor : props.color,
      '@media (hover: none)': {
        backgroundColor: props.variant === 'contained' ? props.textColor : props.color
      },
    },
  }),
  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1) + 'px',
    textTransform: 'capitalize',
    fontSize: theme.spacing(2)
  }

}))

function ButtonVertical(props) {
  const classes = useStyles(props)
  return (
    <div>
      <Button
        variant={props.variant ? props.variant : 'contained'}
        className={classes.button}
        disableElevation={true}
        classes={{ label: classes.label }}
        startIcon={props.icon}
        onClick={props.onClick}
      >
        {props.text ? props.text : 'Button'}
      </Button>

    </div>
  )
}

ButtonVertical.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  color: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string
}

export default ButtonVertical