import { isEmpty } from '../../shared/'
import { createRsvp } from '../service/events.rsvp.datasource'
import { inviteUser, removeUser } from '../service/events.datasource'

const getEventPermissions = (account, event, openedCalendar) => {
    const originatingCalendarUser = event.originatingCalendar?.userLink

    return {
        isEventOwner: event.owner === account.userId,
        isCalendarOwner: openedCalendar?.isOwner,
        originatingCalendarUser,
        originatingCalendarPermissions: originatingCalendarUser?.permissions ?? {}
    }
}

const canEditEvent = (account, event, openedCalendar) => {
    // I created the event OR
    // I joined the event, I have it in another calendar but I also have the originating calendar with edit permissions OR
    // I have the originating calendar with edit permissions
    const { isEventOwner, isCalendarOwner, originatingCalendarPermissions } = getEventPermissions(account, event, openedCalendar)
    return isEventOwner || (isCalendarOwner && originatingCalendarPermissions.canEdit) || originatingCalendarPermissions.canEdit
}

const getRemovalAction = (account, event, openedCalendar) => {
    const { isEventOwner, originatingCalendarPermissions } = getEventPermissions(account, event, openedCalendar)
    const openedCalendarPermissions = openedCalendar?.permissions ?? {}

    if (isEventOwner || originatingCalendarPermissions.canEdit) {
        return 'delete'
    }

    return openedCalendarPermissions.canEdit ? 'remove' : ''
}

const getCalendarIdFromEvent = ({ originatingCalendar }) => {
    // this is populated only if user is shared the calendar
    const userLink = originatingCalendar?.userLink

    if (!isEmpty(userLink)) {
        return originatingCalendar.calendarId
    }

    return null
}

const buildEventUrl = (name, id) => {
    return `/events/${encodeURIComponent(name.replace(/-/g, ' ').replace(/\s+/g, '_'))}-${id}`
}

const shareEvent = async (user, eventId, calendarId) => {

    const rsvpDetails = {
        email: user?.email ?? user.name,
        name: user.name,
        groupId: calendarId,
        eventId: eventId
    }

    const rsvp = await createRsvp(rsvpDetails)

    const inviteDetails = {
        userEventStatusId: rsvp.userEventStatusId,
        groupId: calendarId,
        eventId: eventId
    }

    const inviteResponse = await inviteUser(inviteDetails)

    if (inviteResponse === 429) {
        removeUser(inviteDetails)
    }

    return inviteResponse
}

const getCalendarForShareRights = (openedCal, originatingCal, calLinks) => {
    if (!isEmpty(openedCal)) {
        return openedCal
    }

    if (!isEmpty(originatingCal)) {
        return originatingCal
    }

    if (!isEmpty(calLinks)) {
        return calLinks[0]
    }

    return {}
}

export {
    canEditEvent,
    buildEventUrl,
    getRemovalAction,
    getCalendarIdFromEvent,
    shareEvent,
    getCalendarForShareRights
}