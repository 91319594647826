export const GET_EVENTS = 'GET_EVENTS'
export const SET_EVENTS = 'SET_EVENTS'
export const REFRESH_EVENTS = 'REFRESH_EVENTS'
export const SET_ANCHOR = 'SET_ANCHOR'
export const QUERY_EVENTS = 'QUERY_EVENTS'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'
export const INITIALIZE = 'INITIALIZE'
export const REINITIALIZE = 'REINITIALIZE'
export const ADD_RT_EVENT = 'ADD_RT_EVENT'
export const UPDATE_USER_EVENT_STATUS_IN_EVENT = 'UPDATE_USER_EVENT_STATUS_IN_EVENT'

const getEvents = (pageToken, direction, calendar, isInitialLoad) => ({
    type: GET_EVENTS,
    payload: {
        pageToken: pageToken,
        direction: direction,
        calendarId: calendar,
        isInitialLoad: isInitialLoad
    }
})

const queryEvents = (searchString, calendar) => ({
    type: QUERY_EVENTS,
    payload: {
        searchString: searchString,
        calendarId: calendar,
    }
})

const updateUserEventStatusInEvent = (newUserEventStatus) => ({

    type: UPDATE_USER_EVENT_STATUS_IN_EVENT,
    payload: {
        userEventStatus: newUserEventStatus
    }
})




const setEvents = (events, direction, searchString) => ({

    type: SET_EVENTS,
    payload: events,
    direction: direction,
    searchString: searchString
})


const refreshEvents = (position) => ({
    type: REFRESH_EVENTS,
    position: position
})

const setAnchor = (index, position) => ({
    type: SET_ANCHOR,
    anchor: index,
    position: position
})

const clearEvents = () => ({
    type: CLEAR_EVENTS
})

const initialize = (initialized) => ({
    type: INITIALIZE,
    initialize: initialized
})

const reInitializeEvents = () => ({
    type: REINITIALIZE
})

const addRealTimeEventIncontext = (eventData) => ({
    type: ADD_RT_EVENT,
    payload: {
        event: eventData
    }

})

export {
    getEvents,
    setEvents,
    refreshEvents,
    setAnchor,
    queryEvents,
    clearEvents,
    initialize,
    reInitializeEvents,
    addRealTimeEventIncontext,
    updateUserEventStatusInEvent
}