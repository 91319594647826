import React from 'react'
import styled from 'styled-components'
import { EventsIcon } from '../../shared/assets/EventsIcon'
import { useTheme } from '@material-ui/styles'
import { formattedStartAndEndDates, isToday } from '../../shared/utils/dateUtils'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'

function EventDate({ startDate, endDate, startTimezone, endTimezone, isMultiDay }) {
    const theme = useTheme()
    const formattedDate = formattedStartAndEndDates(startDate, endDate, startTimezone, endTimezone, isMultiDay)
    return (
        <EventItemWrapper icon={<EventsIcon width={16} height={16} filled fill={theme.palette.text.primary}></EventsIcon>
        }>
            <TextContainer noWrap={true} color='textPrimary' gutterBottom={true}>
                <DateHeader highlight={isToday(formattedDate)}>{formattedDate.split('|')[0]}
                    <Day highlight={isToday(formattedDate)}>{formattedDate.split('|')[1]}</Day>
                </DateHeader>
            </TextContainer>
        </EventItemWrapper>
    )
}

EventDate.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTimezone: PropTypes.string,
    endTimezone: PropTypes.string,
    isMultiDay: PropTypes.bool
}

const TextContainer = styled.div`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
  ` };
`
const DateHeader = styled.div`
    display : flex;
    ${ ({ theme, highlight }) => `
    font-size : ${theme.spacing(2)}px  ;
    color : ${highlight ? theme.palette.text.secondary : theme.palette.secondary.contrastText};
    font-weight : ${highlight ? 600 : 500};
    ` }; 
`
const Day = styled.div`
    ${ ({ theme, highlight }) => `
        padding-left : ${theme.spacing(1)}px  ;
        color : ${highlight ? theme.palette.primary.highlight : theme.palette.secondary.main};
        font-weight : 500
    ` }; 
`
export default EventDate
