import {
    apiService, HttpError, uploadCroppedImg
} from '../../shared'

const fetchMyCalendars = async () => await apiService.get('/calendar/myCalendars', { ignoreDeleted: true })

const fetchCalendar = async calendarId => {
    const { data, ok, status } = await apiService.get(`/calendars/${ calendarId }`)

    if (!ok) {
        return {
            error: status
        }
    }

    return data
}

const fetchCalendarUserList = async (id, resultsPerPage, skip = 0) => {
    const { data, ok, status } =
        await apiService.get('/calendar/users?allPermissions', { calendarId: id, resultsPerPage, skipNumber: skip })
    //verifyOk(ok, status)

    return data
}

const getCalendarUser = async (id, user, resultsPerPage = 100) => {
    const { data, ok, status } =
        await apiService.get('/calendar/users?allPermissions', { calendarId: id, q: user, resultsPerPage })

    //verifyOk(ok, status)

    return data
}

const updateCalendarSettings = async (id, updatedSettings) => {
    const { data, ok, status } =
        await apiService.put(`/calendar/settings?calendarId=${ id }`, updatedSettings)
    verifyOk(ok, status)
    return data
}

const createCalendar = async (form, croppedImg) => {
    let formData = form

    if (croppedImg) {
        formData = await uploadCroppedImg(form, croppedImg)
    }

    const { data, ok, status } = await apiService.post('/calendar', formData)
    verifyOk(ok, status)
    return data
}

const editCalendar = async (form, croppedImg) => {
    let formData = form

    if (croppedImg) {
        formData = await uploadCroppedImg(form, croppedImg)
    }

    const { data, ok, status } = await apiService.put(`/calendar?calendarId=${ formData.calendarId }`, formData)
    verifyOk(ok, status)
    return data
}

const deleteCalendar = async calendarId => {
    const { ok, status } =
        await apiService.delete(`/calendar?calendarId=${ calendarId }`)
    verifyOk(ok, status)
    return status
}

const searchUser = async str => {
    const { data } = await apiService.get(`/user?searchString=${ str }`)

    return data
}

const shareMembers = async payload => {
    const { data, ok, status } =
        await apiService.post('/members', payload)
    verifyOk(ok, status)

    return { data, ok, status }
}

const shareCalendar = async payload => {
    const { data, ok, status } = await apiService.post('/share/calendar', payload)

    return { data, ok, status }
}

const removeUser = async (payload) => {
    const { data } = await apiService.delete('/share/calendar', { ...payload })

    return data
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}

export {
    fetchMyCalendars,
    fetchCalendar,
    fetchCalendarUserList,
    getCalendarUser,
    updateCalendarSettings,
    createCalendar,
    editCalendar,
    deleteCalendar,
    searchUser,
    shareCalendar,
    shareMembers,
    removeUser
}