const API_URL_DEV = 'https://dev.api.allcal.com'
const API_URL_STAGING = 'https://staging.api.allcal.com'
const API_URL_PRODUCTION = 'https://api.allcal.com'
const APP_URL_DEV = 'https://dev.app.allcal.com'
const APP_URL_STAGING = 'https://staging.app.allcal.com'
const APP_URL_PRODUCTION = 'https://app.allcal.com'

export const API_VERSION = 3.7
export const S3_GALLERY = 'https://s3.amazonaws.com/gallery.allcal.com'

const PUSHER_CLUSTER = 'mt1'

const CLASSIC_APP_URL_DEV = 'https://dev.app.allcal.com'
const CLASSIC_APP_URL_STAGING = 'https://staging.app.allcal.com'
const CLASSIC_APP_URL_PRODUCTION = 'https://app.allcal.com'

const CHAT_API_URL_DEV = 'https://api.messenger.allcal.com/dev'
const CHAT_API_URL_STAGING = 'https://api.messenger.allcal.com/staging'
const CHAT_API_URL_PRODUCTION = 'https://api.messenger.allcal.com/live'

export const SUPPORT_EMAIL = 'help@allcal.com'

export const getClassicAppUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return CLASSIC_APP_URL_DEV
        case 'staging': return CLASSIC_APP_URL_STAGING
        case 'production': return CLASSIC_APP_URL_PRODUCTION
        default: {
            return CLASSIC_APP_URL_DEV
        }
    }
}

export const getApiUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development':
            return API_URL_DEV
        case 'staging':
            return API_URL_STAGING
        case 'production':
            return API_URL_PRODUCTION
        default: {
            return API_URL_DEV
        }
    }
}

export const getChatApiUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development':
            return CHAT_API_URL_DEV
        case 'staging':
            return CHAT_API_URL_STAGING
        case 'production':
            return CHAT_API_URL_PRODUCTION
        default: {
            return CHAT_API_URL_DEV
        }
    }
}

export const APP_URL = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return APP_URL_DEV
        case 'staging': return APP_URL_STAGING
        case 'production': return APP_URL_PRODUCTION
        default: {
            return APP_URL_DEV
        }
    }
}

export const getGoogleApiKey = () => {

    let appKey
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': appKey = 'AIzaSyDkof_4LVgpcyJaPwWoBhezc9gq5yHpYFo'
            break
        case 'test': appKey = 'AIzaSyDkof_4LVgpcyJaPwWoBhezc9gq5yHpYFo'
            break
        case 'staging': appKey = 'AIzaSyDkof_4LVgpcyJaPwWoBhezc9gq5yHpYFo'
            break
        case 'production': appKey = ''
            break
        default: appKey = 'AIzaSyDkof_4LVgpcyJaPwWoBhezc9gq5yHpYFo'
    }

    return appKey

}

export const getPusherSettings = () => {
    const authUrl = `${getApiUrl()}/authoriser/pusher`
    let appKey = ''

    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development':
            appKey = '5142acaff9277044ac94'
            break
        case 'staging':
            appKey = 'a045a8af979dc4cb0080'
            break
        case 'production':
            appKey = '4933c90d776d6b44653a'
            break
        default:
            appKey = '5142acaff9277044ac94'
    }

    return { authUrl, appKey, cluster: PUSHER_CLUSTER }
}

export const getFirebaseUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return 'https://api.notifications.allcal.com/dev/devices'
        case 'test': return 'https://api.notifications.allcal.com/test/devices'
        case 'staging': return 'https://api.notifications.allcal.com/staging/devices'
        case 'production': return 'https://api.notifications.allcal.com/live/devices'
        default: return 'https://api.notifications.allcal.com/dev/devices'
    }
}

export const getAgoraSettings = () => {
    let appKey = ''

    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development':
            appKey = '0f1c84103ec14572a9772832bb80c54e'
            break
        case 'staging':
            appKey = 'a070db230bfe4396ba5b584c3d458186'
            break
        case 'production':
            appKey = 'c731651dd8934187a6624c785d71cae5'
            break
        default:
            appKey = '0f1c84103ec14572a9772832bb80c54e'
    }

    return { appKey }
}

export const getRecaptchaSiteKey = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return '6LeN-O8UAAAAAHkFLSM1-ZtzkkJvxPbU5ydAGiAU'
        // case 'test': return '6LfWZ2QUAAAAAONGvaILsgUKu170tncYqSVLsS7I'
        case 'staging': return '6LcY-e8UAAAAAFACoecQjukZZ3BuAFKWicHBV7kU'
        case 'production': return '6Lcq-e8UAAAAAOAVfPACWOb7pPKiAL0xi-KCeTiX'
        default: return '6LeN-O8UAAAAAHkFLSM1-ZtzkkJvxPbU5ydAGiAU'
    }
}

export const getInvisibleRecaptchaSiteKey = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return '6LcUj_oUAAAAAP8HEujqevIUNVuJM6Qym94Sxyeh'
        case 'staging': return '6Lc2j_oUAAAAALg5y6xfiiBNVjZVVjK_INSfUhcO'
        case 'production': return '6LdBj_oUAAAAAKy1mo0MINJvPKsgMQCcSGxEOpuh'
        default: return '6LcUj_oUAAAAAP8HEujqevIUNVuJM6Qym94Sxyeh'
    }
}