import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Info from '@material-ui/icons/Info'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import StyledLink from '../common/StyledLink'
import ConversationPhoto from '../common/ConversationPhoto'
import ConversationActions from './ConversationActions'
import { AppGlobalContext } from '../../managers/AppManager'
import { allcalAppUrl } from '../../server'

const GoBackButton = ({ onClose }) => {
    return <StyledIconButton onClick={ onClose }>
        <KeyboardArrowLeft data-testid='conversation-window-close-btn' />
    </StyledIconButton>
}

const CalendarMenu = ({ conversation, history }) => {
    const goToCalendarPeople = () => {
        // Useful for Android integration
        history.push(`/conversations/${ conversation.conversationId }?calendarId=${ conversation.linkedEntity.linkedTo }`)

        window.parent.postMessage({
            calendarId: conversation.linkedEntity.linkedTo,
            conversationId: conversation.conversationId
        }, allcalAppUrl)

        // Useful for iOS integration
        if (_.get(window.webkit, 'messageHandlers')) {
            window.webkit.messageHandlers.chatNotification.postMessage({
                calendarId: conversation.linkedEntity.linkedTo,
                conversationId: conversation.conversationId
            })
        }
    }

    return <Info onClick={ goToCalendarPeople } />
}

const TopBar = ({ closeLink, conversation, onClose, history }) => {
    const { loggedUser } = useContext(AppGlobalContext)
    const isSupportThread = !!_.get(conversation.preferences, 'isSupportThread')

    const canViewGroupInfo = () => {
        return conversation.type !== '1to1' && conversation.status !== 'unavailable'
    }

    const getOtherParticipant = () => {
        if (conversation.type !== '1to1') {
            return {}
        }
        return _.find(conversation.participants, participant => participant.userId !== loggedUser.userId)
    }

    return (
        <StyledTopBarWrapper data-testid='conversation-window-top-bar'>
            <StyledListItem>
                { closeLink ?
                    <StyledLink to={ closeLink }>
                        <GoBackButton onClose={ onClose } />
                    </StyledLink> :
                    <GoBackButton onClose={ onClose } />
                }
                <StyledListItemIcon>
                    <ConversationPhoto conversation={ conversation } otherParticipant={ getOtherParticipant() } />
                </StyledListItemIcon>
                <StyledListItemText
                    primary={ conversation.name }
                    data-testid='conversation-window-name'
                />
                <StyledActionsWrapper>
                    { canViewGroupInfo() &&
                        <Fragment>
                            { conversation.type === 'calendar' ?
                                <CalendarMenu conversation={ conversation } history={ history } /> :
                                <StyledLink to={ `/conversations/${ conversation.conversationId }/details` }>
                                    <Info />
                                </StyledLink> }
                        </Fragment>
                    }
                    { !isSupportThread && <ConversationActions conversation={ conversation } otherParticipant={ getOtherParticipant() } /> }
                </StyledActionsWrapper>
            </StyledListItem>
        </StyledTopBarWrapper>
    )
}

const StyledTopBarWrapper = styled.div`
    background-color: #efefef;
    list-style: none;
`

const StyledListItem = styled(ListItem)`
    && {
        padding: 12px 16px;
    }
`

const StyledIconButton = styled(IconButton)`
    && {
        padding: 5px;
        margin-right: 10px;
    }
`

const StyledListItemIcon = styled(ListItemIcon)`
    && {
        margin-right: 0;
    }
`

const StyledListItemText = styled(ListItemText)`
    && {
        padding-right: 5px;
        overflow: hidden;
        white-space: nowrap;
        width: 0;
        text-overflow: ellipsis;
    }
`

const StyledActionsWrapper = styled.div`
    color: #4A4A4A;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    min-width: max-content;
`

GoBackButton.propTypes = {
    onClose: PropTypes.func
}

CalendarMenu.propTypes = {
    conversation: PropTypes.object,
    history: PropTypes.object
}

TopBar.propTypes = {
    closeLink: PropTypes.string,
    conversation: PropTypes.object,
    isMobile: PropTypes.bool,
    onClose: PropTypes.func,
    history: PropTypes.object
}

TopBar.defaultProps = {
    closeLink: '/conversations',
    conversation: {},
    isMobile: false,
    onClose: () => { }
}

export default withRouter(TopBar)