import Pusher from 'pusher-js'
import { getPusherSettings } from '../shared/constants'

const instance = {
    isInitialized: false,
    client: null,
    channel: null,
}

const connect = (authToken, userId) => {
    if (instance.isInitialized) {
        return console.debug('Pusher already initialized')
    }

    const { authUrl, appKey, cluster } = getPusherSettings()
    instance.client = new Pusher(appKey, {
        cluster,
        forceTLS: true,
        authEndpoint: authUrl,
        auth: {
            headers: {
                'login-token': authToken,
            }
        }
    })

    const { client } = instance
    client.connection.bind('error', function (err) {
        if (err.error) {
            console.error('Pusher failed binding.', err.error)
        }
    })


    const userChannel = client.subscribe(`private-${userId}`)

    userChannel.bind('pusher:subscription_error', function (status) {
        console.log('Pusher Subscription error', status)
    })

    instance.channel = userChannel
    instance.isInitialized = true

    console.log('Pusher initialized')
}

const bind = (name, func) => {
    if (!instance.isInitialized) {
        return console.debug('Pusher not initialized')
    }

    instance.channel.bind(name, func)
}

const unbind = (name, func) => {
    if (!instance.isInitialized) {
        return console.error('Failed unbind pusher not initialized')
    }

    instance.channel.unbind(name, func)
}

/***
 * Should be called only on logout
 */
const disconnect = () => {
    const { channel, client } = instance
    if (!instance.isInitialized) {
        return
    }

    channel.unbind() // unbind all handlers
    client.disconnect()
    instance.isInitialized = false
}

export default {
    connect,
    bind,
    unbind,
    disconnect,

    isInitialized: () => instance.isInitialized
}