import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { humanizeSeconds } from '../../shared/utils/dateUtils'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'

function EventReminder({ reminder }) {
    const { t } = useTranslation('', i18n)
    const humanizedReminderText = reminder ?
        (reminder.fireInterval === 1 ? t(` event.details.reminders.sameTimeAsEvent`) :
            humanizeSeconds(reminder.fireInterval * 1000) + t(` event.details.reminders.beforeEventTime`))
        : t('event.details.reminders.notSet')
    return (
        <EventItemWrapper icon={<NotificationsIcon></NotificationsIcon>} >
            <TextContainer noWrap={true} color='textPrimary' gutterBottom={true}> {humanizedReminderText} </TextContainer>
        </EventItemWrapper>
    )
}

const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
  ` };
`

EventReminder.propTypes = {
    reminder: PropTypes.object
}

export default EventReminder
