import { shape, string, bool } from 'prop-types'

const permissions = shape({
    canEdit: bool,
    canAdd: bool,
    canShare: bool,
    canView: bool,
    isOwner: bool
})

const UserSchema = {
    permissions,
    isConfirmed: bool,
    createdDate: string,
    email: string,
    imageLink: string,
    memberId: string,
    userId: string,
    calendarLink: shape({
        isConfirmed: bool,
        lastActionType: string,
        isOwner: bool,
        permissions
    })
}

export {
    UserSchema
}
