import React from 'react'
import { Container, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import { LoginComponent, RegisterComponent, ResetPasswordComponent } from '..'
import { Assets } from '../../shared'

const AuthScreen = () => {
    const isSmallMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
    const isLandscapeMobile =
        useMediaQuery(' only screen and (min-device-width: 375px) and (max-device-width: 812px) and (max-device-height: 650px) and (orientation: landscape)')
    const isScreenHeightSmall = useMediaQuery('only screen and (max-height: 701px)')

    const location = useLocation()
    const isLogin = location.pathname === '/login'
    const isRegister = location.pathname === '/register'
    const isReset = location.pathname === '/reset-password'
    return <PageContainer isscreenheightsmall={isScreenHeightSmall} >
        <AuthWrapper>
            <Logo issmallmobile={isSmallMobile ? 1 : 0} alt='allcal-logo' src={Assets.APP_LOGO}
                data-test-id='logo' />
            <AuthContainer>
                {isLogin && <LoginComponent isSmallMobile={isSmallMobile} isLandscapeMobile={isLandscapeMobile} />}
                {isRegister && <RegisterComponent isSmallMobile={isSmallMobile} isLandscapeMobile={isLandscapeMobile} />}
                {isReset && <ResetPasswordComponent isSmallMobile={isSmallMobile} isLandscapeMobile={isLandscapeMobile} />}
            </AuthContainer>
        </AuthWrapper>
    </PageContainer>
}

const AuthWrapper = styled.div`
    width: 100%
`

const Logo = styled.img`
    margin: auto;
    display: block;
    width: 250px;
    height: 120px;
`

const AuthContainer = styled(Container)`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    background: ${ ({ theme }) => theme.palette.background.paper};
    border-radius: 4px;
`

const PageContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${ ({ isscreenheightsmall }) => isscreenheightsmall ? '100%' : '100vh'};
    justify-content: center;
    max-width: 600px;
    margin:auto;
`

export default AuthScreen