/* eslint-disable max-len */
import React from 'react'

export const NoMatchPageIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="121" height="82" viewBox="0 0 121 82">
    <g fill="none" fillRule="evenodd">
        <g stroke="#394952" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M5 38h14M101.962 38H115M92.877 38H97M10 42h11.045M90.969 34H107M89 29h15M116.464 30c1.953 0 3.536 1.79 3.536 4s-1.583 4-3.536 4M104.464 21c1.953 0 3.536 1.79 3.536 4s-1.583 4-3.536 4M4.536 38C2.583 38 1 39.79 1 42s1.583 4 3.536 4" />
        </g>
        <path fill="#394952" fillRule="nonzero" d="M27.9 60.4c1.6.9 3.4 1 5.1.6v19.8l.9.3c.8.3 1.7.5 2.7.5 3 0 4.5-1.5 6-2.9 1.4-1.3 2.6-2.6 5.2-2.6s3.9 1.3 5.2 2.6c1.5 1.4 3 2.9 6 2.9s4.5-1.5 6-2.9c1.4-1.3 2.6-2.6 5.2-2.6s3.9 1.3 5.2 2.6c1.5 1.4 3 2.9 6 2.9 1 0 1.9-.2 2.8-.5l.8-.3V61c.6.1 1.2.2 1.8.2 1.2 0 2.4-.3 3.5-.9 3.5-1.9 4.8-6.4 2.8-9.9L85 35.8v-5l-.1-1.2C83.4 16.6 72.3 6.5 59 6.5c-13.3 0-24.4 10.1-25.7 23.3l-.2 6.2L25 50.6c-.9 1.7-1.2 3.7-.6 5.5.6 1.8 1.8 3.3 3.5 4.3z" />
        <path fill="#FFF" d="M56 37h3v4h-3zM60 37h3v4h-3z" />
        <path fill="#A0D9D6" d="M27.2 51.7l8.4-15.2v-4.4l.1-1.2c.6-12.3 10.9-22 23.3-22S81.7 18.6 82.3 31l.1.6v4.9l8.4 15.2c1.3 2.3.4 5.2-1.9 6.5-.7.4-1.5.6-2.3.6-.8 0-1.7-.2-2.4-.6L82 57.1V79c-.3.1-.3.1-.7.1-2 0-2.9-.9-4.2-2.2-1.5-1.5-3.4-3.3-6.9-3.3-3.6 0-5.4 1.8-6.9 3.3-1.3 1.3-2.2 2.2-4.2 2.2s-2.9-.9-4.2-2.2c-1.5-1.5-3.4-3.3-6.9-3.3-3.6 0-5.4 1.8-6.9 3.3-1.3 1.3-2.2 2.2-4.2 2.2-.4 0-.6.1-.9 0v-22l-1.9 1c-1.4.8-3.2.8-4.7 0-1.1-.6-1.9-1.6-2.3-2.9-.6-1.1-.5-2.4.1-3.5zm24.885-.521c1.35-1.104 5.643-1.963 7.606-1.963s6.258.981 7.607 1.963c0 0 1.963-6.38-2.822-10.797-1.104-1.104-3.435-2.208-4.785-2.208s-3.435.981-4.662 2.085c-4.907 4.417-2.944 10.92-2.944 10.92z" />
        <path fill="#394952" fillRule="nonzero" d="M50.4 29c2.651 0 4.8 1.251 4.8-1.4a4.8 4.8 0 1 0-9.6 0c0 2.651 2.149 1.4 4.8 1.4zM67.6 29c2.651 0 4.8 1.251 4.8-1.4a4.8 4.8 0 1 0-9.6 0c0 2.651 2.149 1.4 4.8 1.4z" />
        <g>
            <path fill="#8DC63F" d="M86.292 8.813L82 4.551 86.519 0l4.292 4.261z" />
            <path fill="#49BEDB" d="M91 9.344l3.154 3.132 3.321-3.345L94.321 6zM22.321 66L19 69.345l3.154 3.132 3.321-3.345z" />
            <path fill="#8DC63F" d="M17 17.551l4.292 4.261 4.519-4.551L21.519 13z" />
            <path fill="#49BEDB" d="M23 8.344l3.154 3.132 3.321-3.344L26.321 5z" />
        </g>
    </g>
</svg>
