import React from 'react'
import { bool, func, string } from 'prop-types'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { CheckmarkCircleIcon } from '../../shared/assets/CheckmarkCircleIcon'
import { CloseCircleIcon } from '../../shared/assets/CloseCircleIcon'
import constants from '../utils/notification.constants'

const NotificationActions = ({ notificationType, status, chosen, onPositiveAction, onNegativeAction }) => {

    const isEvent = notificationType === constants.types.rsvpUpdate || notificationType === constants.types.shareEvent

    if (chosen) {
        switch (notificationType) {
            case constants.types.rsvpUpdate: {
                const isPositive = status === constants.rsvpStatus.attending
                return <ChosenButton isPositive={ isPositive }>{ isPositive ? 'Going' : 'Not Going' }</ChosenButton>
            }
            case constants.types.shareEvent: {
                const isPositive = status === constants.status.accepted
                return <ChosenButton isPositive={ isPositive }>{ isPositive ? 'Going' : 'Not Going' }</ChosenButton>
            }
            default: {
                const isPositive = status === constants.status.accepted
                return <ChosenButton isPositive={ isPositive }>{ isPositive ? 'Accepted' : 'Declined' }</ChosenButton>
            }
        }
    }

    return <Grid container direction={ 'row' } alignItems='stretch' spacing={ 1 }>
        <Grid item>
            <PositiveActionButton
                startIcon={ <CheckmarkCircleIcon width={ 20 } height={ 20 } /> }
                variant='outlined' size='small'
                onClick={ onPositiveAction }>{ isEvent ? 'Going' : 'Accept' }</PositiveActionButton>
        </Grid>
        <Grid item>
            <NegativeActionButton
                startIcon={ <CloseCircleIcon width={ 20 } height={ 20 } /> }
                variant='outlined' size='small'
                onClick={ onNegativeAction }>{ isEvent ? 'Not Going' : 'Decline' }</NegativeActionButton>
        </Grid>
    </Grid>
}

NotificationActions.propTypes = {
    notificationType: string,
    status: string,
    chosen: bool,
    requestsRsvp: bool,
    onPositiveAction: func,
    onNegativeAction: func
}

const ActionButton = styled(Button)`
    display: flex;
    justify-content: center;
    background-color: white;
    font-weight: bold;
    white-space: nowrap;
    margin-top: 4px;
`

const PositiveActionButton = styled(ActionButton)`
    margin-right: 6px;
    :hover {
        background-color: ${ ({ theme }) => theme.palette.secondaryAccent.positiveHover };
        border-color: ${ ({ theme }) => theme.palette.secondaryAccent.positive };
    }

    ${ ({ theme }) => {
        const color = theme.palette.secondaryAccent.positive
        return ({
            color,
            borderColor: color,
        })
    }
    }
`

const NegativeActionButton = styled(ActionButton)`
    :hover {
        background-color: ${ ({ theme }) => theme.palette.secondaryAccent.negativeHover };
        border-color: ${ ({ theme }) => theme.palette.secondaryAccent.negative };
    }

    ${ ({ theme }) => {
        const color = theme.palette.secondaryAccent.negative
        return ({
            color,
            borderColor: color
        })
    } }
`

const ChosenButton = ({ isPositive, ...rest }) => {
    return isPositive ?
        <PositiveActionButton
            variant='outlined' size='small'
            { ...rest } startIcon={ <CheckmarkCircleIcon width={ 20 } height={ 20 } /> } /> :
        <NegativeActionButton
            variant='outlined' size='small'
            { ...rest } style={ {
                cursor: 'default',
                pointerEvents: 'none'
            } } startIcon={ <CloseCircleIcon width={ 20 } height={ 20 } /> } />
}

ChosenButton.propTypes = {
    isPositive: bool
}

export default NotificationActions
