import React, { Fragment } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LabelTypeButton from '../../shared/components/LabelTypeButton'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

function EventTags({ tags }) {
    const theme = useTheme()
    const { t } = useTranslation('', i18n)
    return (
        <EventItemWrapper icon={<LocalOfferIcon></LocalOfferIcon>}>
            <Fragment>
                {tags && tags.length > 0 ? tags.map((tag, index) => {
                    return <TagItem key={index}>
                        <LabelTypeButton
                            type='tag'
                            variant='contained'
                            bgColor={theme.palette.background.label}
                            textColor={theme.palette.primary.main}>
                            {tag}
                        </LabelTypeButton>
                    </TagItem>
                }) : <TextContainer noWrap={true} color='textPrimary' gutterBottom={true}> {t('event.details.tags.noTags')} </TextContainer>}

            </Fragment>
        </EventItemWrapper>

    )
}

EventTags.propTypes = {
    tags: PropTypes.array
}

const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
  ` };
`
const TagItem = styled(Grid)`
${({ theme }) => `
    padding-right: ${theme.spacing(1)}px;
    padding-top: ${theme.spacing(1)}px
` };
`
export default EventTags
