import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import * as api from '../services/activity.datasource'
import { STATUS_ACCEPTED, STATUS_DECLINED, STATUS_PENDING } from '../services/activity.datasource'
import { ATTENDING, NOT_ATTENDING } from '../../rsvps/constants'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import { useHistory, useLocation } from 'react-router'
import { Pusher } from '../../sockets'
import { useDispatch } from 'react-redux'
import { modifyMyRsvp } from '../../rsvps'

import { useNavigation } from '../../navigation'
import debounce from '../../shared/hooks/debounceHook'
import propType from 'prop-types'

const ActivityContext = createContext({})

const ActivityProvider = ({ children }) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()

    const mountedRef = useRef(false)

    const currentTab = location.pathname.lastIndexOf('/requests') > 0 ? 1 : 0

    const [notifications, setNotifications] = useState([])

    const [tab, setTabIndex] = useState(currentTab)
    const [shouldFetch, setShouldFetch] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    const { clearNotifications, decreaseNotification } = useNavigation()

    const notificationStatus = tab === 1 ? STATUS_PENDING : null

    useAsyncEffect(function* () {
        const response = yield api.fetchActivityFeed(0, 50, notificationStatus)

        setHasMore(response.notifications.length > 0)
        setNotifications(prevState => {
            return ([...prevState, ...response.notifications])
        })
        mountedRef.current = true

        return () => {
            mountedRef.current = false
        }
    }, [])

    useAsyncEffect(function* () {
        if (!shouldFetch) {
            return yield
        }

        const response = yield api.fetchActivityFeed(notifications.length, 25, notificationStatus)

        setHasMore(response.notifications.length > 0)
        setNotifications(prevState => {
            return ([...prevState, ...response.notifications])
        })

        setShouldFetch(false)
    }, [shouldFetch])

    const requestUpdate = useCallback(() => { // Refresh the complete list, since that's how setting RSVP works
        api.fetchActivityFeed(0, notifications.length, notificationStatus).then(e => {
            setNotifications(e.notifications)
        })
    }, [notificationStatus, notifications])

    useEffect(() => {
        const listener = debounce(() => {
            requestUpdate()
        }, 500)

        Pusher.bind('notification', listener)
        Pusher.bind('event', listener)
        return () => {
            Pusher.unbind(null, listener)
        }
    }, [notificationStatus, requestUpdate])

    useEffect(() => {
        if (history.action === 'POP' && mountedRef.current) {
            setNotifications([])
            setTabIndex(currentTab)
            setShouldFetch(true)
        }
    }, [currentTab, history.action, location])

    const setTab = index => {
        setNotifications([])
        setTabIndex(index)
        setShouldFetch(true)

        if (index === 1) {
            history.push('/activity/requests')
        } else {
            history.push('/activity')
        }
    }

    const next = () => {
        setShouldFetch(true)
    }

    const markAsRead = notificationId => {
        api.putNotification({
            notificationId,
            hasBeenRead: true
        }).then(() => {
            if (!mountedRef.current)
                return

            setNotifications(prevState =>
                prevState.map(item => item.notificationId === notificationId ? {
                    ...item,
                    hasBeenRead: true
                } : item))

            decreaseNotification()
        })
    }

    const markAllRead = () => {
        api.markAllAsRead().then(() => {
            setNotifications(prevState => prevState.map(item => ({ ...item, hasBeenRead: true })))
            clearNotifications()
        })
    }

    const acceptShare = (id, token, completed) => {
        const notification = notifications.find(item => item.notificationId === id)

        api.acceptShare(token).then(() => {
            if (notification.rsvpId) {
                dispatch(modifyMyRsvp(ATTENDING, 'none', notification.rsvpId))
            }
            setNotifications(prevState =>
                prevState.map(item => item.notificationId === id ? {
                    ...item,
                    payload: { requiresRSVP: false },
                    hasBeenRead: true,
                    status: STATUS_ACCEPTED
                } : item))
        }).finally(completed)
    }

    const declineShare = (id, token) => {
        const notification = notifications.find(item => item.notificationId === id)

        api.declineShare(token).then(() => {
            if (notification.rsvpId) {
                dispatch(modifyMyRsvp(NOT_ATTENDING, 'none', notification.rsvpId))
            }
            setNotifications(prevState =>
                prevState.map(item => item.notificationId === id ? {
                    ...item,
                    payload: { requiresRSVP: false },
                    hasBeenRead: true,
                    status: STATUS_DECLINED
                } : item))
        })
    }

    return <ActivityContext.Provider value={{
        notifications, tab, setTab, hasMore, next, markAsRead, markAllRead, acceptShare, declineShare
    }}>
        {children}
    </ActivityContext.Provider>
}

ActivityProvider.propTypes = {
    children: propType.object
}

export const useActivity = () => useContext(ActivityContext)

export default ActivityProvider