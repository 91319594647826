import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ADD_LIVE_STREAM, GET_EVENT_DETAILS, getEventDetailsInfo, setEventDetails, setLiveStreams } from '..'
import { Agora } from '../../livestream'
import { REMOVE_LIVE_STREAM, FETCH_LIVE_STREAM } from './eventDetails.actions'

function* fetchEventDetails(action) {
    const { data, ok, status } = yield call(getEventDetailsInfo, action.payload.eventId)

    if (ok) {
        if (data.lastActionType === 'delete') {
            yield put(setEventDetails({
                error: 404
            }))
        } else {
            yield put(setEventDetails(data))
        }

    } else {
        yield put(setEventDetails({
            error: status
        }))
    }
}

function* fetchLiveStream(action) {
    const channel = Agora.getChannel()
    if (channel) {
        const { streams } = channel
        const apiStreams = action.payload.livestreams

        const merged = streams.map(s => {
            const matched = apiStreams.find(es => es.streamId === s.streamId)
            return matched ? { ...s, info: matched } : s
        })

        setLiveStreams(merged)
    }
}


function* addLiveStream(action) {
    const { eventId } = action.payload

    const { data, ok } = yield call(getEventDetailsInfo, eventId)
    const { streams } = Agora.getChannel()

    if (ok) {
        const apiStreams = data.livestreams.streams

        const merged = streams.map(s => {
            const matched = apiStreams.find(es => es.streamId === s.streamId)
            return matched ? { ...s, info: matched } : s
        })

        console.debug('+ added new stream', merged)
        yield put(setLiveStreams(merged))
    } else {
        console.error('Failed getting event info!')
    }
}

function* removeLiveStream(action) {
    const { streamId } = action.payload

    const streams = yield select(state => state.eventDetails.streams)
    const newStreams = streams.filter(s => s.streamId !== streamId)

    console.debug('- removed', streamId, 'current list', newStreams)
    yield put(setLiveStreams(newStreams))
}

export default function* eventsFetchSaga() {
    yield takeLatest(GET_EVENT_DETAILS, fetchEventDetails)
    yield takeLatest(ADD_LIVE_STREAM, addLiveStream)
    yield takeLatest(REMOVE_LIVE_STREAM, removeLiveStream)
    yield takeLatest(FETCH_LIVE_STREAM, fetchLiveStream)
}
