import React, { Fragment, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import ButtonVertical from '../../shared/components/ButtonVertical'
import SuccessIcon from '../../shared/assets/SuccessIcon'
import ErrorIcon from '../../shared/assets/ErrorIcon'
import FavouriteIcon from '../../shared/assets/FavouriteIcon'
import { modifyMyRsvp } from '../../rsvps'
import { ATTENDING, NOT_ATTENDING, IN_THE_LOOP } from '../../rsvps'
import { useTheme } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

function EventMyRsvp() {

    const myRSVPInfo = useSelector(state => state.rsvps.myRsvp)
    const myRSVP = myRSVPInfo && myRSVPInfo.statuses
    //console.log(myRSVP);
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation('', i18n)

    const findFillForIcon = (myRSVP, type) => {
        if (!myRSVP || !myRSVP.rsvp)
            return
        const status = myRSVP.rsvp
        switch (type) {

            case ATTENDING: {
                return (status === ATTENDING) ? theme.palette.background.paper : theme.palette.success.main
            }
            case NOT_ATTENDING: {
                return (status === NOT_ATTENDING) ? theme.palette.background.paper : theme.palette.error.main
            }
            case IN_THE_LOOP: {
                return (status === IN_THE_LOOP) ? theme.palette.background.paper : theme.palette.info.main
            }

            default: return
        }
    }

    const rsvpChange = (status) => {
        myRSVP.rsvp !== status && dispatch(modifyMyRsvp(status, 'none', myRSVPInfo.userEventStatusId))
    }

    return (
        <Fragment>
            <Grid container>
                <Grid item >
                    <HeaderDivLight>{t('event.yourRSVP')}</HeaderDivLight>
                </Grid>
            </Grid>
            <Gutters container justify='center'>
                <Grid item>
                    <ButtonVertical
                        onClick={() => { rsvpChange(ATTENDING) }}
                        color={theme.palette.background.paper}
                        textColor={theme.palette.success.main}
                        variant={myRSVP && myRSVP.rsvp === ATTENDING ? 'contained' : 'outlined'}
                        text={t('rsvps.going')}
                        icon={<SuccessIcon fill={findFillForIcon(myRSVP, ATTENDING)} />}>
                    </ButtonVertical>
                </Grid>
                <Grid item>
                    <ButtonVertical
                        onClick={() => { rsvpChange(IN_THE_LOOP) }}
                        color={theme.palette.background.paper}
                        textColor={theme.palette.info.main}
                        variant={myRSVP && myRSVP.rsvp === IN_THE_LOOP ? 'contained' : 'outlined'}
                        text={t('rsvps.inTheLoop')}
                        icon={<FavouriteIcon fill={findFillForIcon(myRSVP, IN_THE_LOOP)} />}>
                    </ButtonVertical>
                </Grid>
                <Grid item>
                    <ButtonVertical
                        onClick={() => { rsvpChange(NOT_ATTENDING) }}
                        color={theme.palette.background.paper}
                        textColor={theme.palette.error.main}
                        variant={myRSVP && myRSVP.rsvp === NOT_ATTENDING ? 'contained' : 'outlined'}
                        text={t('rsvps.notGoing')}
                        icon={<ErrorIcon fill={findFillForIcon(myRSVP, NOT_ATTENDING)} />}>
                    </ButtonVertical>
                </Grid>
            </Gutters>
        </Fragment>
    )
}

EventMyRsvp.propTypes = {
    eventId: PropTypes.string
}

const HeaderDivLight = styled.div`
    font-weight : 500;            
    ${({ theme }) => `
    font-size : ${ theme.spacing(2)}px;
    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px
    ` };
            `
const Gutters = styled(Grid)`
    ${({ theme }) => `
        padding-bottom: ${theme.spacing(4)}px
    ` };
            `

export default EventMyRsvp
