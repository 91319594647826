import { all } from 'redux-saga/effects'
import { CalendarsSaga } from '../../calendars'
import { EventsFetchSaga } from '../../events'
import { RsvpsFetchSaga, RsvpCountFetchSaga } from '../../rsvps'
import { EventDetailsFetchSaga } from '../../eventDetails'


export default function* root() {
    yield all([
        CalendarsSaga(),
        EventsFetchSaga(),
        RsvpsFetchSaga(),
        RsvpCountFetchSaga(),
        EventDetailsFetchSaga()
    ])
}