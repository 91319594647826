

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import CategoryGroup from './CategoryGroup'
import CustomTextField from '../components/CustomTextField'
import { getValidUrl } from '../../shared/'

const ProfileSocialProfiles = ({ profile }) => {
    const { t } = useTranslation('', i18n)
    const { privacy } = profile
    const { linkedIn, facebook } = profile.contact
    const showLinkedIn = linkedIn && privacy.linkedIn
    const showFacebook = facebook && privacy.facebook
    const showSocialProfiles = showLinkedIn || showFacebook

    return <>{ showSocialProfiles ? <CategoryGroup id='profile-social-profiles' title={ t('profile.sections.socialProfiles') }>
        <>
            { showLinkedIn && <StyledLink
                href={ getValidUrl(linkedIn) }
                target='_blank'
                rel='noopener noreferrer'
                data-test-id='linkedin-link'
            >
                <CustomTextField
                    label='LinkedIn'
                    defaultValue={ linkedIn }
                    placeholder={ t('profile.properties.empty.LinkedIn') }
                />
            </StyledLink> }
            { showFacebook && <StyledLink
                href={ getValidUrl(facebook) }
                target='_blank'
                rel='noopener noreferrer'
                data-test-id='facebook-link'
            ><CustomTextField
                    label='Facebook'
                    defaultValue={ facebook }
                    placeholder={ t('profile.properties.empty.Facebook') }
                /></StyledLink>
            }
        </>
    </CategoryGroup> : null }</>
}

const StyledLink = styled.a`
    &, input {
        cursor: pointer;
    }
    text-decoration: none;
`

ProfileSocialProfiles.propTypes = {
    profile: PropTypes.shape({
        privacy: PropTypes.object,
        contact: PropTypes.object
    })
}

export default ProfileSocialProfiles