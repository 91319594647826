/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const VolumeIcon = ({ size = 24, color = '#FFF' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={ size } height={ size } viewBox='0 0 512 512'>
        <path
            fill={ color }
            d='M264,416.19a23.92,23.92,0,0,1-14.21-4.69l-.66-.51-91.46-75H88a24,24,0,0,1-24-24V200a24,24,0,0,1,24-24h69.65l91.46-75,.66-.51A24,24,0,0,1,288,119.83V392.17a24,24,0,0,1-24,24Z'/>
        <path
            fill={ color }
            d='M352,336a16,16,0,0,1-14.29-23.18c9.49-18.9,14.3-38,14.3-56.82,0-19.36-4.66-37.92-14.25-56.73a16,16,0,0,1,28.5-14.54C378.2,208.16,384,231.47,384,256c0,23.83-6,47.78-17.7,71.18A16,16,0,0,1,352,336Z'/>
        <path
            fill={ color }
            d='M400,384a16,16,0,0,1-13.87-24C405,327.05,416,299.45,416,256c0-44.12-10.94-71.52-29.83-103.95A16,16,0,0,1,413.83,136C434.92,172.16,448,204.88,448,256c0,50.36-13.06,83.24-34.12,120A16,16,0,0,1,400,384Z'/>
    </svg>
)

VolumeIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
}