import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Skeleton from 'react-loading-skeleton'
import Paper from '@material-ui/core/Paper'
import RsvpStatus from '../../shared/components/RsvpStatus'
import UserAvatar from '../../shared/components/UserAvatar'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: theme.palette.background.default,
    alignItems: 'center'
  },
  headerRoot: {
    alignItems: 'flex-start'
  },
  avatar: {
    backgroundColor: theme.palette.text.highlight,
    width: '50px',
    height: '50px'
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  action: {
    alignSelf: 'center'
  },
  content: {
    paddingRight: theme.spacing(2)
  }
}))

ProfileCard.propTypes = {
  user: PropTypes.object,
  rsvpStatus: PropTypes.string
}

export default function ProfileCard({ user, rsvpStatus }) {
  const classes = useStyles()
  const loadingSkeleton = <Paper elevation={ 0 } style={ { width: '50%' } }>
    <Skeleton></Skeleton>
  </Paper>
  return (
    <Card className={ classes.root } elevation={ 0 } >
      <CardHeader
        classes={ { title: classes.title, root: classes.headerRoot, action: classes.action, content: classes.content } }
        avatar={
          user ? <UserAvatar user={ user } size='50' /> : null
        }
        action={
          <RsvpStatus status={ rsvpStatus }></RsvpStatus>
        }
        title={ user ? (user.name) : loadingSkeleton }
      />
    </Card>
  )
}
