import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Typography, AppBar, useMediaQuery } from '@material-ui/core'
import { ResponsiveDialog, SearchFieldBar } from '../../shared/'
import debounce from '../../shared/hooks/debounceHook'
import { getTimezoneAbbreviation, getSupportedTimezones } from '../../shared/utils/dateUtils'
import { EventSchema } from '../schemas/event.schema'
import TimezoneItem from '../components/TimezoneItem'

const TimezonePicker = ({ limit, event, recentTimezones, handleTimezoneSelect }) => {
    const { t } = useTranslation('', i18n)
    const [isTmzModalOpened, setIsTmzModalOpened] = useState(false)
    const searchFieldRef = useRef()
    const currentTimezone = event[`${ limit }Timezone`]
    const [selectedTmz, setSelectedTmz] = useState(currentTimezone)
    const supportedTimezones = getSupportedTimezones()
    const [timezonesList, setTimezonesList] = useState(supportedTimezones)
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const hasSelectedNewTmz = (action, event, update) => {
        return action === 'save' && event[`${ limit }Timezone`] !== update
    }

    const handleModalClose = (action) => {
        if (hasSelectedNewTmz(action, event, selectedTmz)) {
            handleTimezoneSelect(limit, selectedTmz)
        }

        setIsTmzModalOpened(false)
        searchFieldRef.current = ''
        setTimezonesList(supportedTimezones)
    }

    const handleModalOpen = () => setIsTmzModalOpened(true)
    const searchTimezone = debounce((ev) => {
        setTimezonesList(supportedTimezones.filter(tmz => tmz.toLowerCase().includes(ev.target.value.toLowerCase())))
    }, 500)
    const selectTimezone = tmz => { setSelectedTmz(tmz) }

    return <TmzPickerWrapper data-test-id={ `${ limit }-tmz-picker` }>
        <TmzPickerLabel onClick={ handleModalOpen } data-test-id={ `${ limit }-tmz-abbr` }>
            { getTimezoneAbbreviation(currentTimezone) }
        </TmzPickerLabel>
        <ResponsiveDialog
            open={ isTmzModalOpened }
            title={ t(`event.${ limit }TimezoneHeader`) }
            handleClose={ handleModalClose }
            headerComponent={ <TmzSearchAppBar elevation={ 0 } position='sticky'>
                <SearchFieldBar
                    searchFieldRef={ searchFieldRef }
                    size='smDown'
                    searchPlaceholder={ t('placeholder.timezones') }
                    searchCallback={ event => {
                        event.persist()
                        searchTimezone(event)
                    } }
                    data-test-id={ `${ limit }-tmz-search` }
                />
            </TmzSearchAppBar> }
        >
            <TmzList mobile={ mobile ? 1 : 0 }>
                <TmzSection>
                    <TmzSectionLabel data-test-id='tmz-section-current-label'>Current Timezone</TmzSectionLabel>
                    <TimezoneItem
                        timezone={ currentTimezone }
                        isSelected={ currentTimezone === selectedTmz }
                        handleTimezoneClick={ selectTimezone }
                        data-test-id={ `${ limit }-current-tmz` }
                    />
                </TmzSection>
                <TmzSection>
                    <TmzSectionLabel data-test-id='tmz-section-recent-label'>Recent Timezones</TmzSectionLabel>
                    { recentTimezones.map((tmz, idx) =>
                        <TimezoneItem
                            key={ idx }
                            timezone={ tmz }
                            isSelected={ tmz === selectedTmz }
                            handleTimezoneClick={ selectTimezone }
                            data-test-id={ `recent-tmz-${ idx }` }
                        />
                    ) }
                </TmzSection>
                <TmzSection>
                    <TmzSectionLabel data-test-id='tmz-section-supported-label'>Timezones</TmzSectionLabel>
                    { timezonesList.map((tmz, idx) =>
                        <TimezoneItem
                            key={ idx }
                            timezone={ tmz }
                            isSelected={ tmz === selectedTmz }
                            handleTimezoneClick={ selectTimezone }
                            data-test-id={ `supported-tmz-${ idx }` }
                        />) }
                    { !timezonesList.length && <Typography data-test-id='no-tmz-message'>No timezones found</Typography> }
                </TmzSection>
            </TmzList>
        </ResponsiveDialog>
    </TmzPickerWrapper>
}

const TmzPickerWrapper = styled.div`
    display: inline-block;
`

const TmzPickerLabel = styled.div`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    border-radius: 4px;
    color: ${ ({ theme }) => theme.palette.text.default };
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    min-width: 52px;
    padding: 1px 5px;
`
const TmzSearchAppBar = styled(AppBar)`
    background-color: #FFF;
    color: inherit;
    padding-top: 16px;
    top: -8px;

    > div {
        margin-top: 0;
    }
`

const TmzList = styled.div`
    min-width: ${({ mobile }) => mobile ? '0' : '388px' };
`

const TmzSection = styled.div`
    margin-top: 10px;
`

const TmzSectionLabel = styled(Typography)`
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
`

TimezonePicker.propTypes = {
    limit: PropTypes.string,
    event: PropTypes.shape(EventSchema),
    recentTimezones: PropTypes.arrayOf(PropTypes.string),
    handleTimezoneSelect: PropTypes.func
}

export default TimezonePicker