import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Container, Grid, Hidden, Button } from '@material-ui/core'
import { Formik, Form } from 'formik'
import TemporalSettings from '../components/TemporalSettings'
import CreateEditEventDescription from './CreateEditEventDescription'
import CalendarsDropdown from '../components/CalendarsDropdown'
import { CreateEditTopBar, FormikFieldGroup } from '../../shared/'
import { useCreateEditEvent } from '../'

const CreateEditEvent = () => {
    const { t } = useTranslation('', i18n)
    const { event, hasChanged, setHasChanged,
        handleAllDaySwitch, handleEventDateChange, handleTimezoneSelect,
        handleCalendarChange, handleEventSave, validate, isEditMode } = useCreateEditEvent()
    const getEventTextDescription = event => {
        const textItem = event.attachments.find(att => att.type === 'text')
        return textItem ? textItem.data.text : ''
    }
    const initialValues = {
        name: event.name,
        description: getEventTextDescription(event),
        startDate: event.startDate,
        endDate: event.endDate,
        startTimezone: event.startTimezone,
        endTimezone: event.endTimezone,
        isAllDay: event.isAllDay,
        originatingCalendar: event.originatingCalendar
    }

    return <CreateEventWrapper>
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleEventSave}
        >{({
            dirty, submitCount, submitForm, handleBlur
        }) => {
            setHasChanged(dirty)
            return <Form>
                <CreateEditTopBar
                    name={isEditMode ? t('event.edit.pageTitle') : t('event.create.pageTitle')}
                    saveChanges={submitForm}
                    hasChanged={hasChanged}
                    option={isEditMode ? 'save' : 'create'}
                />
                <Container>
                    <Grid item xs={12} md={6} lg={6}>
                        <EventName
                            name='name'
                            type='text'
                            multiline
                            rowsMax='2'
                            defaultbackground={1}
                            placeholder={t('event.name')}
                            onBlur={() => handleBlur('name', true, false)}
                            data-test-id='create-edit-event-name'
                            padding={12}
                        />
                        <TemporalSettings event={event} handleAllDaySwitch={handleAllDaySwitch}
                            handleEventDateChange={handleEventDateChange}
                            handleTimezoneSelect={handleTimezoneSelect}
                            handleBlur={handleBlur}
                        />

                        <CreateEditEventDescription handleBlur={handleBlur} />

                        <CalendarsDropdown event={event} handleCalendarChange={handleCalendarChange} />

                        <SaveButton
                            variant='contained'
                            color='primary'
                            size='small' fullWidth
                            type='submit'
                            data-test-id='create-edit-save-btn'
                        >
                            {isEditMode ? t('button.saveChanges') : t('button.createEvent')}
                        </SaveButton>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={6} lg={6}>
                        </Grid>
                    </Hidden>
                </Container>
            </Form>
        }}
        </Formik>
    </CreateEventWrapper >
}

const CreateEventWrapper = styled.div`
    background: ${ ({ theme }) => theme.palette.background.paper};
`

const EventName = styled(FormikFieldGroup)`
    .MuiInputBase-input {
        color: ${ ({ theme }) => theme.palette.secondary.default};
        font-weight: 700;
    }

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`

const SaveButton = styled(Button)`
    font-size: 16px;
    margin: 40px 0;
    text-transform: capitalize;
`

export default CreateEditEvent