import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import appMessages from '../../utils/appMessages'
import ConversationItem from './ConversationItem'
import PropTypes from "prop-types";

import { AppGlobalContext } from '../../managers/AppManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import { withRouter } from "react-router-dom";

import useWindowSize from '../../hooks/windowSizeHook'
import ListInfiniteScroll from '../common/ListInfiniteScroll'
import useConversations from '../../hooks/conversationsHook'
import useMessages from '../../hooks/messagesHook'


const ConversationsList = ({ history }) => {
    const { searchString, setSearchString, conversations, setConversations, isDataLoading,
        hasMoreConversations, refreshConversationsList, orderConversations,
        openedConversation, setOpenedConversation, getUpdatedConversations, closeConversation } = useContext(ConversationsContext)
    const { listMaxHeight } = useWindowSize(60)
    const { getConversationById } = useConversations()
    const { getMessages } = useMessages()
    const { userChannel } = useContext(AppGlobalContext)

    const continueToConversation = (conversationId) => {
        history.push(`/conversations/${ conversationId }`)
        setSearchString('')
        // desktop needs to refresh the list if I am already on the 'conversation' screen
        refreshConversationsList('', true)
    }

    const openConversationItem = (conversationId) => {
        if (!_.isEmpty(openedConversation)) {
            const oldConversationId = openedConversation.conversationId
            setOpenedConversation({})
            getMessages(oldConversationId, 0, 0, 1).finally(() => continueToConversation(conversationId))
        } else {
            continueToConversation(conversationId)
        }
    }

    const handleConversationCreated = (data) => {
        refreshConversationsList(searchString, true, 0, data.at)
    }

    const handleConversationDeleted = (data) => {
        setConversations(prevState => {
            const nextState = prevState.slice(0)
            _.remove(nextState, { conversationId: data.conversationId })
            return orderConversations(nextState)
        })
    }

    const handleConversationUpdated = async (data) => {
        const updatedConversations = await getUpdatedConversations(searchString, 0, data.at)
        setConversations(prevState => {
            let nextState = _.map(prevState, conv => {
                const updatedConv = _.find(updatedConversations, c => c.conversationId === conv.conversationId)
                return updatedConv || conv
            })
            return orderConversations(nextState)
        })
    }

    const handleConversationsEvent = (data) => {
        switch (data.type) {
            //case 'created':
            case 'unhidden':
                handleConversationCreated(data)
                return
            case 'updated':
                handleConversationUpdated(data)
                return
            case 'hidden':
            case 'deleted':
                handleConversationDeleted(data)
                return
            default:
                return
        }
    }

    const handleBlockedUserConversation = () => {
        refreshConversationsList(searchString, true, 0)
    }

    const handleUsersEvent = (data) => {
        switch (data.type) {
            // case 'blocked':
            case 'joined':
                handleBlockedUserConversation(data)
                return
            case 'left':
                getConversationById(data.conversationId)
                    .then(() => {
                        handleBlockedUserConversation(data)
                    })
                    .catch(closeConversation)
                return
            default:
                return
        }
    }

    const renderEmptyListMessage = () => {
        if (!_.isEmpty(searchString)) {
            return <StyledNoResultsMessage
                gutterBottom
                variant='caption'
                data-testid='no-results-message'
            >
                { appMessages.noConversationsResultsMessage }
            </StyledNoResultsMessage>
        }
    }

    const renderListContent = () => {
        if (_.isEmpty(conversations)) {
            return !isDataLoading ?
                <StyledLoaderWrapper>
                    { renderEmptyListMessage() }
                </StyledLoaderWrapper> :
                <StyledLoaderWrapper><CircularProgress color='secondary' /></StyledLoaderWrapper>
        }

        return conversations.map(conversation =>
            <ConversationItem
                key={ conversation.conversationId }
                conversation={ conversation }
                openConversationItem={ openConversationItem }
            />)
    }

    const showEmptyListMessage = () => {
        return _.isEmpty(searchString) && conversations.length === 1 &&
            _.get(conversations[0].preferences, 'isSupportThread')
    }

    useEffect(() => {
        if (_.isEmpty(userChannel)) {
            return
        }

        userChannel.bind('conversations', handleConversationsEvent) // TODO move this out of the presentation layer
        userChannel.bind('users', handleUsersEvent)

        return () => {
            userChannel.unbind('conversations', handleConversationsEvent)
            userChannel.unbind('users', handleUsersEvent)
        }
    }, [userChannel])

    return <StyledListWrapper data-testid='conversations-list'>
        <StyledList conversationslength={ conversations.length }>
            <ListInfiniteScroll
                dataLength={ conversations.length }
                height={ listMaxHeight }
                endDelimiterHeight={ 64 }
                hasMore={ hasMoreConversations }
                next={ () => refreshConversationsList(searchString, false, conversations.length) }
                refreshFunction={ () => refreshConversationsList(searchString, true) }
            >
                <>
                    { renderListContent() }
                    { showEmptyListMessage() && <StyledEmptyStateMessage
                        gutterBottom
                        variant='subtitle1'
                        data-testid='empty-conversations-list-message'
                    >
                        { appMessages.emptyConversationsListMessage }
                    </StyledEmptyStateMessage> }
                </>
            </ListInfiniteScroll>
        </StyledList>
    </StyledListWrapper>
}

const StyledListWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    width: 100%;
`

const StyledList = styled(List)`
    && {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        // 57 = height of a conversationItem
        // +2 = add a buffer so that all conversations are visible above the add button
        // min-height: ${ props => (props.conversationslength + 2) * 57 }px;
    }
`

const StyledLoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`

const StyledNoResultsMessage = styled(Typography)`
    text-align: center;
`

const StyledEmptyStateMessage = styled(Typography)`
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    position: absolute;
    top: 30%;
`

ConversationsList.propTypes = {
    history: PropTypes.object
}

export default withRouter(ConversationsList)
