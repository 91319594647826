import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import PhotoLibrary from '@material-ui/icons/PhotoLibrary'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import MobileViewMenu from '../common/MobileViewMenu'
import { CreateMessageContext } from '../../managers/CreateMessageManager'

const CreateMessageMediaActions = ({ isMobile }) => {
    const { imageFileRef, videoFileRef, isMediaLoading,
        cancelMediaUpload, handleMediaAttachmentChange } = useContext(CreateMessageContext)

    const triggerAttachImageFile = () => imageFileRef.current.click()
    const triggerAttachVideoFile = () => videoFileRef.current.click()

    const menuOptions = [{ callback: triggerAttachVideoFile, name: 'Video' }, { callback: triggerAttachImageFile, name: 'Photo' }]

    return <Fragment>
        <input style={ { display: 'none' } } onChange={ handleMediaAttachmentChange }
            ref={ imageFileRef } type='file' accept='image/*' multiple />
        <input style={ { display: 'none' } } onChange={ handleMediaAttachmentChange }
            ref={ videoFileRef } type='file' accept='video/*' multiple />

        { isMediaLoading ?
            <StyledIconButton aria-label='Close' color='primary' onClick={ cancelMediaUpload } data-testid='header-icon'><Icon>close</Icon></StyledIconButton> :
            <Fragment>
                { isMobile ? <MobileViewMenu options={ menuOptions } type='actions' position='bottomRight' /> :
                    <div>
                        <StyledIconButton aria-label='Image Attachment' data-testid='attach-image'
                            onClick={ triggerAttachImageFile }>
                            <StyledPhotoLibrary fontSize='small' />
                        </StyledIconButton>
                        <StyledIconButton aria-label='Video Attachment' data-testid='attach-video'
                            onClick={ triggerAttachVideoFile }>
                            <StyledVideoLibrary fontSize='small' />
                        </StyledIconButton>
                    </div>
                }
            </Fragment>
        }
    </Fragment>
}

const StyledIconButton = styled(IconButton)`
    && {
        color: #4a4a4a;
        padding: 8px;
    }
`

const StyledPhotoLibrary = styled(PhotoLibrary)`
    align-self: center;
`

const StyledVideoLibrary = styled(VideoLibrary)`
    align-self: center;
`

CreateMessageMediaActions.propTypes = {
    isMobile: PropTypes.bool
}

export default CreateMessageMediaActions