import { SET_RSVPS, SET_RSVP_EVENT, SET_MY_RSVP, SET_USERSTATUS_EVENT } from '..'
const initialState = {

}

export default (state = initialState, action) => {

    switch (action.type) {
        //TO-DO This will no longer be needed soon 
        case SET_RSVPS: {

            let result = action.payload.reduce((h, obj) =>
                Object.assign(h, {
                    [obj.eventId]: { ...state[obj.eventId], ...obj }
                })
                , {})
            return Object.keys(result) && Object.keys(result).length > 0 ? {
                ...result
            } : state
        }
        case SET_RSVP_EVENT: {

            if (action.payload.skipNumber > 0) {

                let modifiedStateObj = {}

                modifiedStateObj.users = state.users ?
                    [...state.users, ...action.payload.data.items]
                    : [...action.payload.data.items]

                /*if (action.payload.data.userEventStatus
                    && (action.payload.data.userEventStatus.statuses !== (state.myRsvp
                        && state.myRsvp.status))) */
                modifiedStateObj.myRsvp = { ...action.payload.data.userEventStatus }

                modifiedStateObj.hasMore = !!action.payload.data.items.length

                return {
                    ...modifiedStateObj
                }
            } else {
                let newStateObj = {}
                newStateObj.users = action.payload.data && action.payload.data.items ? [...action.payload.data.items] : []
                newStateObj.myRsvp = { ...action.payload.data.userEventStatus }
                newStateObj.hasMore = !!action.payload.data.items

                return {
                    ...newStateObj
                }
            }

        }
        case SET_USERSTATUS_EVENT: {
            return {
                ...state,
                myRsvp: { ...action.payload.userEventStatus }
            }
        }
        case SET_MY_RSVP: {
            if (state.myRsvp) {
                return {
                    ...state,
                    myRsvp: { ...action.payload }
                }
            } else {
                return state
            }
        }

        default:
            return state
    }
}