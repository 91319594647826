export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS'
export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS'
export const REFRESH_EVENT_DETAILS = 'REFRESH_EVENT_DETAILS'

export const SET_LIVE_STREAMS = 'SET_LIVE_STREAMS'
export const ADD_LIVE_STREAM = 'ADD_LIVE_STREAM'
export const REMOVE_LIVE_STREAM = 'REMOVE_LIVE_STREAM'
export const FETCH_LIVE_STREAM = 'FETCH_LIVE_STREAM'

const getEventDetails = (eventId) => ({
    type: GET_EVENT_DETAILS,
    payload: {
        eventId: eventId
    }
})

const setEventDetails = (event) => ({
    type: SET_EVENT_DETAILS,
    payload: event
})

const refreshEventDetails = () => ({
    type: REFRESH_EVENT_DETAILS
})

const setLiveStreams = streams => ({
    type: SET_LIVE_STREAMS,
    payload: {
        streams
    }
})

const fetchLiveStreams = livestreams => ({
    type: FETCH_LIVE_STREAM,
    payload: {
        livestreams: livestreams
    }
})

const addLiveStream = (eventId) => ({
    type: ADD_LIVE_STREAM,
    payload: {
        eventId,
    }
})

const removeLiveStream = (streamId) => ({
    type: REMOVE_LIVE_STREAM,
    payload: {
        streamId
    }
})


export {
    getEventDetails,
    setEventDetails,
    refreshEventDetails,

    setLiveStreams,
    fetchLiveStreams,
    addLiveStream,
    removeLiveStream
}