import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loader = () => {
    return <LoaderWrapper><CircularProgress
        color='primary'
    /></LoaderWrapper>
}

const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 16px 0;
`

export default Loader