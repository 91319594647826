import { SET_EVENTS, REFRESH_EVENTS, SET_ANCHOR, INITIALIZE, REINITIALIZE, ADD_RT_EVENT, UPDATE_USER_EVENT_STATUS_IN_EVENT } from '..'
import { CLEAR_EVENTS } from './events.actions'
import { isMultiDay } from '../../shared/utils/dateUtils'

const initialState = {
    items: []

}

export default (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_USER_EVENT_STATUS_IN_EVENT: {
            if (action.payload.userEventStatus) {
                let index = state.items.findIndex(k => action.payload.userEventStatus.eventId === k.eventId);
                return {
                    ...state,
                    items: [
                        ...state.items.slice(0, index),
                        {
                            ...state.items[index],
                            userEventStatus: action.payload.userEventStatus
                        },
                        ...state.items.slice(index + 1)
                    ]
                }
            }

        }
        case SET_EVENTS: {
            let modifiedStateObj = {}

            if (action.payload && action.payload.items && action.payload.items.length > 0) {

                //let filteredEvents = action.payload.items.filter(event => !isMultiDay(event))
                let filteredEvents = action.payload.items;
                if (action.direction) {
                    let pageTokenOfCurrentFetch = action.payload.metadata.pageToken
                    if (action.direction === 'backward' && action.payload.items.length < 50) {
                        pageTokenOfCurrentFetch = null
                    }
                    modifiedStateObj[action.direction === 'backward' ? 'pageTokenPast' : 'pageToken'] = pageTokenOfCurrentFetch
                    modifiedStateObj.items = (action.direction === 'backward') ? [
                        ...filteredEvents.reverse(),
                        ...state.items,
                    ] : [
                            ...state.items,
                            ...filteredEvents]

                    return {
                        ...state,
                        ...modifiedStateObj
                    }

                } else {
                    return {
                        initialized: action.searchString && action.searchString !== '' ? state.initialized : undefined,
                        searchActive: true, // To Recalculate Height for Virtualization &  clear happens when searchString is empty
                        pageTokenPast: null, // To prevent dummy past loading skeletons
                        items: [
                            ...filteredEvents
                        ]
                    }
                }


            } else if (action.payload && action.payload.items && action.payload.items.length === 0) {

                if (action.direction) {
                    modifiedStateObj[action.direction === 'backward' ? 'pageTokenPast' : 'pageToken'] = null

                    return {
                        ...state,
                        ...modifiedStateObj
                    }

                } else {
                    return {
                        initialized: action.searchString && action.searchString !== '' ? state.initialized : undefined,
                        searchActive: true,
                        pageTokenPast: null,
                        items: []
                    }

                }

            } else {
                return state
            }
        }
        case REFRESH_EVENTS: {

            return action.position === null || action.position >= 0 ? {
                ...state,
                index: action.position
            }
                : state
        }
        case CLEAR_EVENTS: {
            return {
                items: state.items.length > 0 ? [] : state
            }
        }
        case SET_ANCHOR: {
            return action.anchor >= 0 ? {
                ...state,
                anchor: {
                    index: action.anchor,
                    position: action.position
                }
            }
                : state
        }
        case INITIALIZE: {
            return {
                ...state,
                initialized: action.initialize
            }
        }
        case REINITIALIZE: {
            return {
                ...state,
                initialized: undefined,
                pageTokenPast: null,

            }
        }
        case ADD_RT_EVENT: {
            return {
                ...state,
                items: [],
                initialized: undefined,
                anchor: undefined,
                eventInContext: action.payload.event,
                pageToken: undefined,
                pageTokenPast: undefined
            }
        }
        default:
            return state
    }
}