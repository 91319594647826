/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const PauseIcon = ({ size = 24, color = '#FFF' }) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 512 512'>
        <path
            fill={color}
            d='M208,432H160a16,16,0,0,1-16-16V96a16,16,0,0,1,16-16h48a16,16,0,0,1,16,16V416A16,16,0,0,1,208,432Z'/>

        <path
            fill={color}
            d='M352,432H304a16,16,0,0,1-16-16V96a16,16,0,0,1,16-16h48a16,16,0,0,1,16,16V416A16,16,0,0,1,352,432Z'/>
    </svg>
)

PauseIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
}