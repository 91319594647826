import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveDialog } from '../../shared'
import ReactCrop from 'react-image-crop'

const CropImageModal = ({ title, open, onImageLoaded, imgSrc, crop, setCrop, makeClientCrop, handleModalClose }) => {

    return <ResponsiveDialog
        open={ open }
        title={ title }
        handleClose={ handleModalClose }
    >
        <div data-test-id='circular-image-cropper'>
            <ReactCrop
                src={ imgSrc }
                onImageLoaded={ onImageLoaded }
                crop={ crop }
                onChange={ setCrop }
                onComplete={ makeClientCrop }
                circularCrop
            />
        </div>
    </ResponsiveDialog>
}

CropImageModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onImageLoaded: PropTypes.func,
    setCrop: PropTypes.func,
    makeClientCrop: PropTypes.func,
    handleModalClose: PropTypes.func,
    imgSrc: PropTypes.string,
    crop: PropTypes.object
}

export default CropImageModal