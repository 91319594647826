import React from 'react'
import styled from 'styled-components'

import { Container, useMediaQuery } from '@material-ui/core'

import { useActivity } from '../screens/ActivityProvider'
import { ListInfiniteScroll } from '../../shared'
import Notification from './Notification'
import { useHistory } from 'react-router'
import { buildEventUrl } from '../../events'
import NotificationMobile from './NotificationMobile'
import { useResolver } from '../../account'
import { useAlerts } from '../../alerts'

const ActivityList = () => {
    const { notifications, next, markAsRead, hasMore, acceptShare, declineShare } = useActivity()
    const { findCalendar, fetchMyCalendars } = useResolver()
    const { showSnack } = useAlerts()

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const history = useHistory()

    const onPressView = (target, notification) => {
        markAsRead(notification.notificationId)

        switch (target) {
            case 'event': {
                const { name, eventId } = notification.event
                const { calendarId } = notification.calendar
                return history.push(buildEventUrl(name, eventId, calendarId), {
                    from: '/events'
                })
            }
            case 'calendar': {
                const { calendarId, visibility } = notification.calendar
                const calendar = findCalendar(calendarId)

                if (!calendar && visibility === 'private') {
                    return showSnack('You cannot access that calendar.', 'error')
                }

                return history.push(`/calendars/${ calendarId }/events`, {
                    from: '/calendars'
                })
            }
            default:
                break
        }
    }

    const onAction = (target, notification) => () => {
        const { notificationId, token, hasBeenRead } = notification
        switch (target) {
            case 'accept': {
                return acceptShare(notificationId, token, fetchMyCalendars)
            }
            case 'decline': {
                return declineShare(notificationId, token)
            }
            case 'touch': {
                return !hasBeenRead && markAsRead(notificationId)
            }
            default:
                break
        }
    }

    return <ActivityListContent>
        <ListInfiniteScroll data-test-id='activity-list' dataLength={ notifications ? notifications.length : 0 }
            hasMore={ hasMore }
            next={ () => next() }>
            { notifications && notifications.map(item =>
                isMobile ? <NotificationMobile
                    data-test-id={ `notification-${ item.notificationId }` }
                    key={ item.notificationId } data={ item }
                    onPressView={ onPressView }
                    onTouchAction={ onAction('touch', item) }
                    onPositiveAction={ onAction('accept', item) }
                    onNegativeAction={ onAction('decline', item) } /> :

                    <Notification
                        data-test-id={ `notification-${ item.notificationId }` }
                        key={ item.notificationId } data={ item }
                        onPressView={ onPressView }
                        onTouchAction={ onAction('touch', item) }
                        onPositiveAction={ onAction('accept', item) }
                        onNegativeAction={ onAction('decline', item) } />
            ) }
        </ListInfiniteScroll>
    </ActivityListContent>
}

export default ActivityList

const ActivityListContent = styled(Container)`
    height: 100%;
    margin-bottom: 16px;
`