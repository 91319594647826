import { apiService } from '../../shared'
import Agora from '../agora'

const authAgoraChannel = async channelId => {
    const { data, error } = await apiService.get(`/events/${channelId}/streams/auth`)

    return {
        token: data.token,
        error
    }
}

const createStream = async (eventId, streamId, streamName) => {
    let { clientId } = Agora.getChannel()

    const { status, ok, error } = await apiService.post(`/events/${eventId}/streams`, { uid: clientId, streamId, streamName })

    if (!ok)
        throw error

    return {
        error,
        status
    }
}

export {
    authAgoraChannel,
    createStream
}