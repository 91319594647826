
import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import CalendarToday from '@material-ui/icons/CalendarToday'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'
// NOT SURE WHY THIS MUCH EFFORT IS PUT IN TO MAINTAIN SCHEMA INFO ON THE F.E ON A NON SHARED COMPONENT
import { CalendarSchema } from '../../calendars'

function EventCalendar({ calendar }) {
    return (
        <EventItemWrapper icon={<CalendarToday></CalendarToday>} >
            {
                calendar && calendar.name &&
                <TextContainer color='textPrimary' gutterBottom={true}>
                    {calendar.name}
                </TextContainer>
            }
        </EventItemWrapper>
    )
}

EventCalendar.propTypes = {
    calendar: PropTypes.shape(CalendarSchema)
}

const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
  ` };
`
export default EventCalendar
