import { useState, useEffect } from 'react'
import moment from 'moment'
import { isEmpty, convertDateToUTC } from '../../shared/'

const useDates = event => {
    let startDateUTC
    let endDateUTC
    const localTimezone = moment.tz.guess()

    const getInitDates = (startDate) => {
        const nextHour = (date) => moment(date).get('hours')
        return {
            startDate: moment(startDate)
                .set('hour', nextHour(startDate) + 1)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0),
            endDate: moment(startDate)
                .set('hour', nextHour(startDate) + 2)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0)
        }
    }

    const getDuration = event => moment(event.endDate).diff(moment(event.startDate), 'minutes')

    const [duration, setDuration] = useState(1)
    const [dates, setDates] = useState(getInitDates())
    const updateDuration = ({ startDate, endDate }) => setDuration(getDuration({ startDate, endDate }))

    const handleDateChange = (limitName, { startTimezone, endTimezone, isAllDay }, newValue) => {
        let newDates = { ...dates }
        if (limitName === 'start') {
            newDates = {
                startDate: isAllDay ? moment(newValue).startOf('day') : moment(newValue),
                endDate: isAllDay ? moment(newValue).endOf('day') :
                    moment(newValue).add(duration, 'minutes')
            }
        } else {
            if (isAllDay) {
                newDates = {
                    ...newDates,
                    endDate: moment(newValue).endOf('day')
                }
                updateDuration({ startDate: dates.startDate, endDate: newValue })
            } else {

                startDateUTC = moment(convertDateToUTC(newDates.startDate, startTimezone))
                endDateUTC = moment(convertDateToUTC(newValue, endTimezone))

                if (startDateUTC.isBefore(endDateUTC)) {
                    newDates = {
                        ...newDates,
                        endDate: moment(newValue)
                    }
                    updateDuration({ startDate: dates.startDate, endDate: newValue })
                }
            }
        }

        setDates(newDates)
        return newDates
    }

    const adjustEndDate = ({ endDate, startDate, endTimezone, startTimezone }, limit, value) => {
        let newEndDate = endDate

        if (limit === 'start') {
            startDateUTC = moment(convertDateToUTC(startDate, value))
            endDateUTC = moment(convertDateToUTC(endDate, endTimezone))

            if (startDateUTC.isAfter(endDateUTC)) {
                newEndDate = startDateUTC.add(1, 'minutes').tz(endTimezone)
            }
        } else {
            startDateUTC = moment(convertDateToUTC(startDate, startTimezone))
            endDateUTC = moment(convertDateToUTC(endDate, value))

            if (startDateUTC.isAfter(endDateUTC)) {
                newEndDate = startDateUTC.add(1, 'minutes').tz(value)
            }
        }

        return newEndDate
    }

    useEffect(() => {
        if (!isEmpty(event)) {
            setDuration(getDuration(event))
            setDates({
                startDate: moment(event.startDate).tz(event.startTimezone),
                endDate: moment(event.endDate).tz(event.endTimezone)
            })
        }
    }, [event, localTimezone])

    return {
        ...dates,
        handleDateChange,
        adjustEndDate,
        getInitDates
    }
}

export default useDates