
import { create } from 'apisauce'
import { API_VERSION, getApiUrl, getChatApiUrl } from '../constants'


const api = create({
  baseURL: getApiUrl(),
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'api-version': API_VERSION
  },
  paramsSerializer: (params) => {

    let combined = Object.entries(params).reduce(
      (map, [key, value]) => {
        if (value !== undefined) {
          if (Array.isArray(value))
            map.push(key + '=' + encodeURIComponent(JSON.stringify(value)).replace(/%2C/g, ','))
          else
            map.push(key + '=' + value)
        }
        return map
      },
      []
    ).join('&')

    return combined

  }
})

export const chatApiService = create({
  baseURL: getChatApiUrl(),
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
    'service': 'Allcal'
  }
})

export default api