import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import RsvpStatus from '../../shared/components/RsvpStatus'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

const RsvpInfo = () => {
    const theme = useTheme()
    const myRSVP = useSelector(state => state.rsvps.myRsvp && state.rsvps.myRsvp.statuses)

    //console.log(myRSVP)
    return myRSVP ? <RsvpStatus status={myRSVP.rsvp} size={theme.spacing(2)} ></RsvpStatus> : <Skeleton width={100}></Skeleton>
}

RsvpInfo.propTypes = {
    eventId: PropTypes.string
}

export default RsvpInfo