import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useField } from 'formik'
import { Input, Typography, Paper } from '@material-ui/core'

const FormikProfileField = (props) => {
    const [field, meta] = useField(props)
    const { id, name, label } = props

    return (
        <FormikProfileFieldWrapper>
            <FieldGroup elevation={ 0 }>
                { label && <Label
                    htmlFor={ id || name }
                    data-test-id={ `${ label.toLowerCase() }-label` }>
                    { label }
                </Label> }
                <StyledInput disableUnderline { ...field } { ...props }
                    data-test-id={ `${ name.toLowerCase() }-input` } />
            </FieldGroup>
            <ErrorMessage>{ meta.touched && meta.error ? meta.error : null }</ErrorMessage>
        </FormikProfileFieldWrapper>
    )
}

const Label = styled.label`
    width: 52px;
`
const FormikProfileFieldWrapper = styled.div`
    position: relative;
    width: 100%;
`

const FieldGroup = styled(Paper)`
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background.default };
    display: flex;
    padding-left: 12px;
    
    label {
        color: #B6B9B9;
        margin-right: 16px;
    }
`
const StyledInput = styled(Input)`
    flex: 2;

    &.MuiInputBase-root.Mui-disabled {
        color: #1b1f21;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;
    }
`
const ErrorMessage = styled(Typography)`
    color: ${({ theme }) => theme.palette.error.main };
    height: 20px;
    font-size: 12px;
    position: relative;
    right: 0;
    text-align: right;
`

FormikProfileField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string
}

export default FormikProfileField