export const verifyFile = (files, imageMaxSize, acceptedFileTypesArray) => {
    if (files && files.length > 0) {
        const currentFile = files[0]
        const currentFileType = currentFile.type
        const currentFileSize = currentFile.size
        if (currentFileSize > imageMaxSize) {
            return false
        }
        if (!acceptedFileTypesArray.includes(currentFileType)) {
            return false
        }
        return true
    }
}

export const createCropPreview = async (image, crop) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const originWidth = crop.width * scaleX
    const originHeight = crop.height * scaleY
    // maximum width/height
    const maxWidth = 1200
    const maxHeight = 1200 / (16 / 9)
    let targetWidth = originWidth
    let targetHeight = originHeight
    if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
            targetWidth = maxWidth
            targetHeight = Math.round(maxWidth * (originHeight / originWidth))
        } else {
            targetHeight = maxHeight
            targetWidth = Math.round(maxHeight * (originWidth / originHeight))
        }
    }
    // set canvas size
    canvas.width = targetWidth
    canvas.height = targetHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        targetWidth,
        targetHeight
    )
    const base64Image = canvas.toDataURL('image/jpeg')

    return base64Image

}

export const dataURItoBlob = dataURI => {
    const binary = atob(dataURI.split(',')[1])
    const array = []
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    return new Blob([new Uint8Array(array)], {
        type: mimeString
    })
}