import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Avatar } from '@material-ui/core'
import { isEmpty } from '../../shared/utils/entityValidationUtils'
import { verifyFile } from '../../shared/utils/imgUpload'
import EditIcon from '@material-ui/icons/Edit'

const imageMaxSize = 20000000 // bytes -> 20mb
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg'
const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => { return item.trim() })

const CircularImageUpload = ({ setImgSrc, setCrop, setImgRef, setOpen, setCroppedImg, imageUrl }) => {
    useEffect(() => {
        setImgSrc(imageUrl)
    }, [imageUrl, setImgSrc])

    const handleFileSelect = event => {
        const files = event.target.files
        if (!isEmpty(files)) {
            const isVerified = verifyFile(files, imageMaxSize, acceptedFileTypesArray)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener('load', () => {
                    imageUrl && clearPhoto(false)
                    const myResult = myFileItemReader.result
                    setImgSrc(myResult)
                    setOpen(true)
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            }
        }
    }

    const clearPhoto = () => {
        setCroppedImg(null)
        setCrop({ aspect: 1 })
        setImgRef(null)
        setImgSrc(null)
    }

    return <>
        <label htmlFor='upload-button'>
            <ImageLayerWrapper>
                <EditLayer data-test-id='edit-layer'>
                    <EditIcon data-test-id='edit-icon' fontSize='large' />
                </EditLayer>
                <CircularImage name='imageLink' data-test-id='circular-image' src={ imageUrl } />
            </ImageLayerWrapper>
        </label>
        <UploadInput
            type='file'
            accept={ acceptedFileTypes }
            id='upload-button'
            onClick={ e => e.target.value = '' }
            onChange={ handleFileSelect }
            data-test-id='upload-button'
        />
    </>
}

const CircularImage = styled(Avatar)`
    height: 128px;
    margin: 0 auto;
    width: 128px;
`

const UploadInput = styled.input`
    display: none;
`

const ImageLayerWrapper = styled.div`
    position: relative;
`

const EditLayer = styled.div`
    align-items: center;
    background-color: #000000;
    color: white;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 1;
`

CircularImageUpload.propTypes = {
    setCroppedImg: PropTypes.func,
    setImgSrc: PropTypes.func,
    setCrop: PropTypes.func,
    setImgRef: PropTypes.func,
    setOpen: PropTypes.func,
    imageUrl: PropTypes.string,
}

export default CircularImageUpload