import React from 'react'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { FormikFieldGroup } from '../../shared'

const ProfileNotes = ({ notes, handleSaveNotes, setIsViewProfileMode }) => {
    const { t } = useTranslation('', i18n)

    return <>
        <Formik
            initialValues={ { notes: notes || '' } }
            onSubmit={ handleSaveNotes }
        >
            <Form>
                <div data-test-id='profile-notes-wrapper'>
                    <InfoMessage data-test-id='profile-notes-message'>{ t('profile.other.notes.notesMessage') }</InfoMessage>
                    <NotesLabel>{ t('profile.other.notes.label') }</NotesLabel>
                    <FormikFieldGroup
                        name='notes'
                        type='text'
                        placeholder={ t('profile.other.notes.notesPlaceholder') }
                        defaultbackground={ 1 }
                        multiline
                        rowsMax={ 5 }
                        padding={ 12 }
                    />
                    <NotesActions>
                        <CapitalizedButton
                            color='primary'
                            type='submit'
                            size='small'
                            variant='contained'
                            data-test-id='profile-notes-save'>
                            { t('button.save') }
                        </CapitalizedButton>
                        <CapitalizedButton
                            variant='outlined'
                            size='small'
                            onClick={ () => setIsViewProfileMode(true) }
                            color='primary'
                            data-test-id='profile-notes-cancel'>
                            { t('button.cancel') }
                        </CapitalizedButton>
                    </NotesActions>
                </div>
            </Form>
        </Formik>
    </>
}

const InfoMessage = styled(Typography)`
    color:  #9FA2A4;
    margin-bottom: 16px;
`

const NotesLabel = styled.div`
    color: ${ ({ theme }) => theme.palette.secondary.main };
    font-weight: 700;
    padding-left: 12px;
    text-transform: uppercase;
`

const NotesActions = styled.div`
    display: flex;
    flex-direction: row-reverse;
`

const CapitalizedButton = styled(Button)`
    text-transform: capitalize;
    margin-left: 12px;
`

ProfileNotes.propTypes = {
    notes: PropTypes.string,
    setIsViewProfileMode: PropTypes.func,
    handleSaveNotes: PropTypes.func
}

export default ProfileNotes