import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import NoteIcon from '@material-ui/icons/Note'
import PropTypes from 'prop-types'

function FileAttachment({ attachment }) {
    return (
        <Document color='textPrimary'>
            <DocumentTitleContainer gutterBottom={true}> {attachment.data.title} </DocumentTitleContainer>
            <DocumentDescContainer gutterBottom={true}> {attachment.data.description} </DocumentDescContainer>
            <Button
                variant='contained'
                color='default'
                disableElevation
                target='_blank'
                href={attachment.data.url}
                download
                startIcon={<FileIcon></FileIcon>}
            >
                {attachment.data.fileName}
            </Button>
        </Document>
    )
}

FileAttachment.propTypes = {
    attachment: PropTypes.object
}

const Document = styled.div`
  ${ ({ theme }) => `
      display: flex;
      width : 100%;
      justify-content: center;
      flex-direction: column;
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
      padding : ${theme.spacing(2)}px;
      background : ${theme.palette.background.default};
      white-space : pre-wrap
  ` };
`
const FileIcon = styled(NoteIcon)`
    margin-right : ${ ({ theme }) => theme.spacing(1)}px;
`
const DocumentTitleContainer = styled(Typography)`
  ${ ({ theme }) => `
      width : 100%;
      font-size : ${ theme.spacing(2)}px;
      font-weight : 600;
      background : ${theme.palette.background.default};
      white-space : pre-wrap
  ` };
`
const DocumentDescContainer = styled(Typography)`
  ${ ({ theme }) => `
      width : 100%;
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
      background : ${theme.palette.background.default};
      white-space : pre-wrap
  ` };
`
export default FileAttachment