import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import CategoryGroup from './CategoryGroup'

const ProfileDescription = ({ description, title }) => {

    return <>{ description ? <CategoryGroup id='profile-about-me' title={ title }>
        <CategoryData data-test-id='profile-description'>
            { description }
        </CategoryData>
    </CategoryGroup> : null }
    </>
}

const CategoryData = styled(Grid)`
    border-radius: 4px;
    padding: 6px 6px 6px 12px;
    margin: 6px 6px 0 0;
    flex-wrap: nowrap;
    max-height: 132px;
    overflow: auto;
    background: ${ ({ theme }) => theme.palette.background.default };
    word-break: break-word;
    white-space: break-spaces;
`

ProfileDescription.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string
}

export default ProfileDescription