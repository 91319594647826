import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getOrderedCalendarsWithAddRights } from '../../calendars'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { EventSchema } from '../schemas/event.schema'
import { Dropdown } from '../../shared/'

const CalendarsDropdown = ({ event, handleCalendarChange }) => {
    const calendars = useSelector(state => state.calendars.items)
    const calendarsWithAddRights = getOrderedCalendarsWithAddRights(calendars)
    const changeOriginatingCalendar = ev => {
        handleCalendarChange(calendarsWithAddRights.find(cal =>
            cal.calendarId === ev.currentTarget.dataset.identifier))
    }

    return <CalendarsDropdownWrapper>
        <TodayIcon data-test-id='calendars-dropdown-icon' />
        <Dropdown
            collection={ calendarsWithAddRights }
            identifier='calendarId'
            displayValue='name'
            selectedValue={ event.originatingCalendar }
            selectionHandler={ changeOriginatingCalendar }
            data-test-id='calendars-dropdown'
        />
    </CalendarsDropdownWrapper >
}

const TodayIcon = styled(CalendarTodayIcon)`
    height: 20px;
    width: 20px;
`

const CalendarsDropdownWrapper = styled.div`
    align-items: center;
    display: flex;

    div {
        margin-left: 20px;
        width: calc(100% - 40px);
    }
`

CalendarsDropdown.propTypes = {
    event: PropTypes.shape(EventSchema),
    handleCalendarChange: PropTypes.func
}

export default CalendarsDropdown