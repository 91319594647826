import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'

// TODO: Bring back the cropping functionality later
const ImageCropper = ({ selectedImage, setCroppedImage }) => {
    const [src, setSrc] = useState('')
    const [isRotationFixed, setIsRotationFixed] = useState(false)
    // const [crop, setCrop] = useState({})
    const [imageElement, setImageElement] = useState()
    const [imageFileName, setImageFileName] = useState('')
    const refImageElement = useRef(null)

    const onImageLoaded = image => fixImageOrientation(image.target)
    // Enable when bringing back the crop functionality
    // const onCropComplete = crop => makeClientCrop(crop)
    // const onCropChange = crop => setCrop(crop)

    const displaySelectedImage = (selectedImage) => {
        if (typeof selectedImage === 'object') {
            setImageFileName(selectedImage.name)
            readFile(selectedImage)
            return
        }

        fetch(selectedImage)
            .then(res => res.blob())
            .then(blob => {
                readFile(blob)
                let fileName = _.last(selectedImage.split('/'))
                setImageFileName(fileName)
            })
    }

    const readFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setSrc(reader.result)
        })
        reader.readAsDataURL(file)
    }

    const fixImageOrientation = async (image) => {
        if (isRotationFixed) {
            return
        }

        let allMetaData
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        let width = image.width
        let height = image.height
        let exifOrientation = ''

        window.EXIF.getData(image, function () {
            allMetaData = window.EXIF.getAllTags(this)
            exifOrientation = allMetaData.Orientation
        })

        if (_.includes([5, 6, 7, 8], exifOrientation)) {
            canvas.width = height
            canvas.height = width
        } else {
            canvas.width = width
            canvas.height = height
        }

        switch (exifOrientation) {
            case 2:
                ctx.setTransform(-1, 0, 0, 1, width, 0)
                break
            case 3:
                ctx.setTransform(-1, 0, 0, -1, width, height)
                break
            case 4:
                ctx.setTransform(1, 0, 0, -1, 0, height)
                break
            case 5:
                ctx.setTransform(0, 1, 1, 0, 0, 0)
                break
            case 6:
                ctx.setTransform(0, 1, -1, 0, height, 0)
                break
            case 7:
                ctx.setTransform(0, -1, -1, 0, height, width)
                break
            case 8:
                ctx.setTransform(0, -1, 1, 0, 0, width)
                break
            default:
                ctx.setTransform(1, 0, 0, 1, 0, 0)
        }

        ctx.drawImage(image, 0, 0, width, height)

        let dataUrl = canvas.toDataURL()
        let imgElement = document.createElement('img')
        imgElement.src = dataUrl
        imgElement.style.width = `${canvas.width}px`
        imgElement.style.height = `${canvas.height}px`
        setImageElement(imgElement)
        setSrc(dataUrl)
        setIsRotationFixed(true)
        await substituteCroppedImage(canvas)
        displaySelectedImage(dataUrl)
    }

    const substituteCroppedImage = (canvas) => {
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.log('Canvas is invalid')
                    return
                }
                blob.name = imageFileName
                setCroppedImage(blob)
            }, 'image/jpeg')
        })
    }

    // const makeClientCrop = async (crop) => {
    //     if (refImageElement.current && crop.width && crop.height) {
    //         const croppedImageUrl = await getCroppedImg(refImageElement.current, crop, imageFileName)
    //         setCroppedImage(croppedImageUrl)
    //     }
    // }

    // const getCroppedImg = (image, crop, fileName) => {
    //     const canvas = document.createElement('canvas')
    //     const scaleX = image.naturalWidth / image.width
    //     const scaleY = image.naturalHeight / image.height
    //     canvas.width = crop.width * 1.5
    //     canvas.height = crop.height * 1.5
    //     const ctx = canvas.getContext('2d')
    //     console.log('cropped image: ', image)
    //     ctx.drawImage(
    //         image,
    //         crop.x * scaleX,
    //         crop.y * scaleY,
    //         crop.width * scaleX,
    //         crop.height * scaleY,
    //         0,
    //         0,
    //         crop.width * 1.5,
    //         crop.height * 1.5
    //     )

    //     return new Promise((resolve, reject) => {
    //         canvas.toBlob(blob => {
    //             if (!blob) {
    //                 console.log('Canvas is invalid')
    //                 return
    //             }
    //             blob.name = fileName
    //             resolve(blob)
    //         }, 'image/jpeg')
    //     })
    // }

    useEffect(() => {
        if (selectedImage) {
            displaySelectedImage(selectedImage)
        }
    }, [])

    useEffect(() => {
        refImageElement.current = imageElement
    }, [imageElement])

    return <StyledCropperWrapper>
        {src &&
            <StyledImg style={{ visibility: `${isRotationFixed ? 'visible' : 'hidden'}` }} src={src} alt='group info avatar' onLoad={onImageLoaded} />
        }
        {/* {src && (
            <div style={{ visibility: `${isRotationFixed ? 'visible' : 'hidden'}` }} data-testid='image-cropper'>
                <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                />
            </div>
        )} */}
    </StyledCropperWrapper>
}

const StyledCropperWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const StyledImg = styled.img`
    height: 100%;
    width: 100%;
`

ImageCropper.propTypes = {
    selectedImage: PropTypes.any,
    setCroppedImage: PropTypes.func
}

export default ImageCropper