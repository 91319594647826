import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { EventsList } from '../../events/'
import { CalendarSchema } from '../schemas/calendar.schema'

const CalendarEvents = ({ calendar }) => {
    return <CalendarEventsWrapper>
        { calendar && calendar.calendarId && <EventsList calendarId={ calendar.calendarId } /> }
    </CalendarEventsWrapper>
}

const CalendarEventsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

CalendarEvents.propTypes = {
    calendar: PropTypes.shape(CalendarSchema)
}

export default CalendarEvents