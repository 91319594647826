export const GET_RSVPS = 'GET_RSVPS'
export const SET_RSVPS = 'SET_RSVPS'
export const SET_RSVP_EVENT = 'SET_RSVP_EVENT'
export const GET_RSVP_EVENT = 'GET_RSVP_EVENT'
export const GET_USERSTATUS_EVENT = 'GET_USERSTATUS_EVENT'
export const SET_USERSTATUS_EVENT = 'SET_USERSTATUS_EVENT'
export const MODIFY_MY_RSVP = 'MODIFY_MY_RSVP'
export const SET_MY_RSVP = 'SET_MY_RSVP'

const getAllRsvps = () => ({
    type: GET_RSVPS

})

const modifyMyRsvp = (status, attendanceStatus, rsvpId) => ({
    type: MODIFY_MY_RSVP,
    payload: {
        status: status,
        attendanceStatus: attendanceStatus,
        rsvpId: rsvpId
    }

})

const setMyRSVP = (rsvp) => ({
    type: SET_MY_RSVP,
    payload: rsvp

})

const setAllRsvps = (rsvps) => ({
    type: SET_RSVPS,
    payload: rsvps
})

const getRSVPForEvent = (eventId, skipNumber) => ({
    type: GET_RSVP_EVENT,
    payload: {
        eventId: eventId,
        skipNumber: skipNumber
    }
})

const getUserStatusForEvent = (eventId) => ({
    type: GET_USERSTATUS_EVENT,
    payload: {
        eventId: eventId
    }
})

const setUserEventStatus = (userEventStatus) => ({
    type: SET_USERSTATUS_EVENT,
    payload: {
        userEventStatus: userEventStatus
    }
})

const setEventRsvpData = (data, eventId, skipNumber) => ({

    type: SET_RSVP_EVENT,
    payload: {
        data: data,
        eventId: eventId,
        skipNumber: skipNumber
    }
})

export {
    getAllRsvps,
    setAllRsvps,
    getRSVPForEvent,
    setEventRsvpData,
    getUserStatusForEvent,
    setUserEventStatus,
    modifyMyRsvp,
    setMyRSVP
}