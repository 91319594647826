import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { ResponsiveDialog } from '../../shared'
import ProfileBasicInfo from './ProfileBasicInfo'
import ProfileDescription from './ProfileDescription'
import ProfileUserDetails from './ProfileUserDetails'
import ProfileSocialProfiles from './ProfileSocialProfiles'
import UserProfileActions from './UserProfileActions'
import ProfileNotes from './ProfileNotes'
import ReportModal from './ReportModal'
import { useMediaQuery, IconButton, Tooltip } from '@material-ui/core'
import ReportIcon from '@material-ui/icons/Report'
import { saveProfileNotes } from '../services/profile.datasource'

const ProfileModal = ({ open, setOpen, profile, setProfile }) => {
    const { t } = useTranslation('', i18n)
    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const [isViewProfileMode, setIsViewProfileMode] = useState(true)

    const handleCloseDialog = () => {
        setIsReportModalOpen(false)
    }

    const handleSaveNotes = values => {
        saveProfileNotes(profile.userId, values.notes)
        setProfile({ ...profile, notes: values.notes })
        setIsViewProfileMode(true)
    }

    return <ResponsiveDialog
        open={ open }
        cancelText={ t('button.close') }
        title={ t('profile.other.title') }
        handleClose={ () => setOpen(false) }
        hideSave
        hideCancel={ !isViewProfileMode }
        flexTitle={ true }
        headerComponent={ isViewProfileMode ? <ButtonWrapper>
            <Tooltip title={ t('profile.report.tooltip') } arrow>
                <ReportButton aria-label="report user" data-test-id='report-user-btn'
                    onClick={ () => setIsReportModalOpen(true) }>
                    <ReportPic />
                </ReportButton>
            </Tooltip>
        </ButtonWrapper> : null }
    >
        <>
            <ProfileWrapper sm={ sm ? 1 : 0 }>
                <ProfileBasicInfo profile={ profile } />
                { isViewProfileMode ? <>
                    <UserProfileActions profile={ profile } setIsViewProfileMode={ setIsViewProfileMode } />
                    <ProfileDescription description={ profile.notes } title={ t('profile.other.notes.label') } />
                    <ProfileDescription description={ profile.description } title={ t('profile.sections.aboutMe') } />
                    <ProfileUserDetails profile={ profile } />
                    <ProfileSocialProfiles profile={ profile } />
                </> : <ProfileNotes
                        notes={ profile.notes }
                        handleSaveNotes={ handleSaveNotes }
                        setIsViewProfileMode={ setIsViewProfileMode } /> }
            </ProfileWrapper>
            { isReportModalOpen &&
                <ReportModal
                    userId={ profile.userId }
                    isReportModalOpen={ isReportModalOpen }
                    setIsReportModalOpen={ setIsReportModalOpen }
                    handleCloseDialog={ handleCloseDialog }
                /> }
        </>
    </ResponsiveDialog>
}

const ReportButton = styled(IconButton)`
    color: #d94141;
`

const ReportPic = styled(ReportIcon)`
    font-size: 28px;
`

const ButtonWrapper = styled.div`
    .MuiIconButton-root {
        padding: 2px;
    }
`

const ProfileWrapper = styled.div`
    min-width: ${({ sm }) => sm ? 'unset' : '350px' };
`

ProfileModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    setProfile: PropTypes.func,
    profile: PropTypes.object
}

export default ProfileModal