/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

export const ExpandIcon = ({ size = 24, color = '#FFF' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 512 512'>
      <path d='M336,448h56a56,56,0,0,0,56-56V336'
            style={{
                'fill':'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'
            }}/>
      <path d='M448,176V120a56,56,0,0,0-56-56H336'
            style={{
                'fill':'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'
            }}/>
      <path d='M176,448H120a56,56,0,0,1-56-56V336'
            style={{
                'fill':'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'
            }}/>
      <path d='M64,176V120a56,56,0,0,1,56-56h56'
            style={{
                'fill':'none', stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'
            }}/>
  </svg>
)

ExpandIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
}