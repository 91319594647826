import React from 'react'
import { PropTypes } from 'prop-types'

const SuccessIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M-4-4h24v24H-4z' />
        <path
          fill={props.fill ? props.fill : '#8DC63F'}
          d='M12.46 6.65c.4-.39.4-1.02 0-1.41a.996.996 
          0 00-1.41 0L6.94 9.35 4.95 7.36a.996.996 
          0 10-1.41 1.41l2.69 2.7c.2.19.46.29.71.29.26 
          0 .51-.1.71-.29l4.81-4.82zM8 0c4.42 0 8 3.58 
          8 8s-3.58 8-8 8-8-3.58-8-8 3.58-8 8-8z'
        />
      </g>
    </svg>
  )
}

SuccessIcon.propTypes = {
  fill: PropTypes.string
}

export default SuccessIcon