import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ConversationTitle from './ConversationItemTitle'
import LastMessage from './ConversationItemLastMessage'
import ConversationIndicators from './ConversationIndicators'
import { ConversationsContext } from '../../managers/ConversationsManager'
import ConversationPhoto from '../common/ConversationPhoto'

const ConversationItem = ({ conversation, openConversationItem }) => {
    const { openedConversation } = useContext(ConversationsContext)

    const triggerOpenConversation = () => {
        openConversationItem(conversation.conversationId)
    }

    const isConversationOpened = () => {
        return conversation.conversationId === openedConversation.conversationId
    }

    return (
        <div>
            <Divider light />
            <StyledListItem button
                onClick={ triggerOpenConversation }
                isactive={ isConversationOpened() ? 1 : 0 }
                data-testid='conversation-wrapper'
            >
                <ConversationPhoto conversation={ conversation } />
                <StyledListItemText
                    primary={ <ConversationTitle conversation={ conversation } /> }
                    secondary={ <LastMessage conversation={ conversation } /> }
                />
                { conversation.lastMessage &&
                    <ConversationIndicators conversation={ conversation } /> }
            </StyledListItem>
        </div>
    )
}

const StyledListItem = styled(ListItem)`
    && {
        background-color: ${props => props.isactive ? 'rgba(0, 0, 0, 0.08)' : '' };
        height: 72px;
    }
`

const StyledListItemText = styled(ListItemText)`
    &&, && > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

ConversationItem.propTypes = {
    conversation: PropTypes.object,
    triggerDeleteConversation: PropTypes.func,
    openConversationItem: PropTypes.func
}

export default withRouter(ConversationItem)