import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ListItemText from '@material-ui/core/ListItemText'
import UserAvatar from '../common/UserAvatar'
import { ContactsContext } from '../../managers/ContactsManager'

const Contact = ({ contact }) => {
    const isContactAvailable = contact.userId !== 'nonAllcalUser'
    const getContactName = () => isContactAvailable ? contact.name : `Invite "${ contact.name }" to Allcal`
    const { isEmailSearching } = useContext(ContactsContext)

    return (
        <StyledContactWrapper data-testid='contact-wrapper'>
            <UserAvatar user={ contact } />
            <StyledListItemText
                isavailable={ isContactAvailable ? 1 : 0 }
                primary={ getContactName() }
                secondary={ isEmailSearching && isContactAvailable ? contact.email : null }
                data-testid='contact-name' />
        </StyledContactWrapper>
    )
}

const StyledContactWrapper = styled.div`
    align-items: center;
    display: flex;
`

const StyledListItemText = styled(ListItemText)`
    padding: 0 16px;

    span {
        font-size: ${props => props.isavailable ? '1' : '0.8' }rem;
    }
`

Contact.propTypes = {
    contact: PropTypes.object.isRequired
}

export default Contact