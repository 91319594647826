/* eslint-disable */
import React from 'react'

export const ActivityIcon = ({
        width = 20,
        height = 20,
        filled
}) => <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20'>
                {filled
                        ? <path fill='#127AB3' fillRule='evenodd'
                                d='M7.657 2.3C7.657 1.03 8.707 0 10 0c1.294 0 2.343 1.03 2.343 2.3v1.086C15.129 4.338 17.13 6.94 17.13 10v3l2.037 4h-6.672c.034.162.051.33.051.5 0 1.381-1.14 2.5-2.546 2.5s-2.546-1.119-2.546-2.5c0-.17.017-.338.05-.5H.834l2.037-4v-3c0-3.06 2-5.661 4.787-6.613V2.3z' />
                        : <path fill='#1B1F21' fillRule='evenodd'
                                d='M7.657 2.299c0-1.27 1.05-2.3 2.343-2.3 1.294 0 2.343 1.03 2.343 2.3v1.087c2.786.951 4.787 3.553 4.787 6.613v3l2.037 4h-5.638c-.247 1.696-1.733 3-3.529 3-1.796 0-3.282-1.304-3.529-3H.833l2.037-4v-3c0-3.06 2-5.662 4.787-6.613V2.299zm8.25 10.98l1.284 2.52h-4.959c.072.22.11.456.11.7 0 1.27-1.048 2.3-2.342 2.3s-2.343-1.03-2.343-2.3c0-.244.04-.48.11-.7H2.81l1.284-2.52v-3.28c0-2.827 2.06-5.182 4.787-5.696V2.3c0-.608.501-1.1 1.12-1.1.619 0 1.12.492 1.12 1.1v2.004c2.727.514 4.787 2.869 4.787 5.696v3.28z'
                                opacity='.4' />

                }
        </svg>
