import React, { Fragment, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import ConversationsView from '../SideBar/ConversationsView'
import ConversationWindow from '../ConversationWindow/ConversationWindow'
import { ConversationsContext } from '../../managers/ConversationsManager'
import useSiblingWidth from '../../hooks/siblingWidthHook'
import useHookWithRefCallback from '../../hooks/refCallbackHook'

const MobileView = () => {
    return <Hidden mdUp>
        <Grid item xs={12} className='full-height'>
            <Slide direction='down' in>
                <div className='full-height'>
                    <ConversationsView />
                </div>
            </Slide>
        </Grid>
    </Hidden>
}

const DesktopView = () => {
    const { maxWidth, setSibling } = useSiblingWidth()
    const [refSibling] = useHookWithRefCallback(setSibling)

    return <Hidden smDown>
        <Grid item sm={5} md={5} lg={4} style={{ minWidth: '305px' }} ref={refSibling}>
            <div className='full-height' data-testid='sidebar'>
                <ConversationsView />
            </div>
        </Grid>
        <Grid item sm={7} md={7} lg={8} style={{ maxWidth: `${maxWidth}px` }}>
            <ConversationWindow />
        </Grid>
    </Hidden>
}

const ConversationsPage = ({ match, location }) => {
    const { setOpenedConversation } = useContext(ConversationsContext)

    useEffect(() => {
        if (!_.get(match.params.id)) {
            setOpenedConversation({})
        }
    }, [location])

    return <Fragment>
        <DesktopView />
        <MobileView />
    </Fragment>
}

ConversationsPage.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object
}

export default withRouter(ConversationsPage)