import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useField } from 'formik'
import { Input, Typography, Paper } from '@material-ui/core'

const FormikFieldGroup = (props) => {
    const [field, meta] = useField(props)
    const { id, name, label, defaultbackground, elevation, padding } = props

    return (
        <FormikFieldGroupWrapper>
            <FieldGroup
                padding={ padding || 0 }
                elevation={ elevation || 0 }
                defaultbackground={ defaultbackground ? 1 : 0 }
            >
                { label && <Label
                    htmlFor={ id || name }
                    data-test-id={ `${ label.toLowerCase() }-label` }>
                    { label }
                </Label> }
                <StyledInput disableUnderline { ...field } { ...props }
                    data-test-id={ `${ name.toLowerCase() }-input` } />
            </FieldGroup>
            <ErrorMessage>{ meta.touched && meta.error ? meta.error : null }</ErrorMessage>
        </FormikFieldGroupWrapper>
    )
}

const Label = styled.label`
    width: 52px;
`
const FormikFieldGroupWrapper = styled.div`
    position: relative;
    width: 100%;
`

const FieldGroup = styled(Paper)`
    align-items: center;
    display: flex;
    padding-left: ${({ padding }) => padding }px;
    
    label {
        color: #B6B9B9;
        margin-right: 16px;
    }

    ${({ defaultbackground, theme }) => defaultbackground ? `background-color: ${ theme.palette.background.default }` : null };

`
const StyledInput = styled(Input)`
    flex: 2;
    
    textarea {
        text-align: ${({ alignment }) => alignment || 'left' };
        padding-left: 6px;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
        -webkit-transition-delay: 9999s;
    }
`
const ErrorMessage = styled(Typography)`
    color: ${({ theme }) => theme.palette.error.main };
    height: 20px;
    font-size: 12px;
    position: relative;
    right: 0;
    text-align: right;
`

FormikFieldGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    defaultbackground: PropTypes.number,
    elevation: PropTypes.number,
    padding: PropTypes.number,
    alignment: PropTypes.string
}

export default FormikFieldGroup