import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'

const useIntegrationCompat = () => {
    const isIntegrated = process.env.REACT_APP_CHAT_INTEGRATED

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const [siblingOffset, setSiblingOffset] = useState(0)

    useEffect(() => {
        if (!isIntegrated) {
            return setSiblingOffset(0)
        }

        setSiblingOffset(isMobile ? 60 : 40)
    }, [siblingOffset, isMobile])

    return { siblingOffset, isIntegrated, isMobile }
}

export default useIntegrationCompat