import { apiService } from '../../shared'

export const STATUS_PENDING = 'pending'
export const STATUS_ACCEPTED = 'accepted'
export const STATUS_DECLINED = 'rejected'

export const fetchActivityFeed = async (skip, limit, notificationStatus) => {
    const { data, ok, status } = await apiService.get('notification/history', {
        skipNumber: skip,
        resultsPerPage: limit,
        status: notificationStatus ? notificationStatus : undefined
    })

    if (!ok) {
        return {
            notifications: [],
            error: status
        }
    }

    return data
}

export const markAllAsRead = async () => {
    const { status } = await apiService.put('notification/markAllAsRead')

    return {
        status
    }
}

export const putNotification = async (notification) => {
    const { status } = await apiService.put('notification', notification)

    return {
        status
    }
}

export const acceptShare = async (token) => {
    const { status } = await apiService.get('notification', { token: token })

    return {
        status
    }
}

export const declineShare = async (token) => {
    const { status } = await apiService.delete('notification', { token: token })

    return {
        status
    }
}