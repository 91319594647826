import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const SimpleDialog = ({ children, open, title, handleClose, handleOk, cancelText, saveText }) => {
    const { t } = useTranslation('', i18n)

    return <Dialog
        open={ open }
        onClose={ () => handleClose() }
        aria-labelledby='simple-dialog-title'
        data-test-id='simple-dialog-body'
        fullWidth={ true }
        maxWidth='sm'
    >
        <DialogTitle id='simple-dialog-title' data-test-id='simple-dialog-title'>
            { title }
        </DialogTitle>
        <DialogContent data-test-id='simple-dialog-content'>
            { children }
        </DialogContent>
        <DialogActions>
            <CapitalizedButton
                autoFocus
                variant='outlined'
                size='small'
                onClick={ () => handleClose() }
                color='primary'
                data-test-id='simple-dialog-cancel'>
                { cancelText || t('button.cancel') }
            </CapitalizedButton>
            <CapitalizedButton
                variant='contained'
                size='small'
                onClick={ () => handleOk() }
                color='primary'
                data-test-id='simple-dialog-save'>
                { saveText || t('button.save') }
            </CapitalizedButton>
        </DialogActions>
    </Dialog>
}

const CapitalizedButton = styled(Button)`
    text-transform: capitalize;
`

SimpleDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.shape(),
    handleClose: PropTypes.func,
    handleOk: PropTypes.func,
    cancelText: PropTypes.string,
    saveText: PropTypes.string
}

export default SimpleDialog