import React, { Fragment, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import ConversationsView from '../SideBar/ConversationsView'
import ConversationWindow from '../ConversationWindow/ConversationWindow'
import { AppGlobalContext } from '../../managers/AppManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import ConversationsDetailsView from '../../components/SideBar/ConversationsDetailsView'
import useSiblingWidth from '../../hooks/siblingWidthHook'
import useHookWithRefCallback from '../../hooks/refCallbackHook'

const MobileView = ({ areDetailsOpened }) => {
    return <Hidden mdUp>
        <Grid item xs={ 12 } className='full-height'>
            <Slide direction='up' in>
                <div className='full-height'>
                    { areDetailsOpened ? <ConversationsDetailsView /> : <ConversationWindow isMobile /> }
                </div>
            </Slide>
        </Grid>
    </Hidden>
}

const DesktopView = ({ areDetailsOpened }) => {
    const { maxWidth, setSibling } = useSiblingWidth()
    const [refSibling] = useHookWithRefCallback(setSibling)

    return <Hidden smDown>
        <Grid item sm={ 5 } md={ 5 } lg={ 4 } style={ { minWidth: '305px' } } ref={ refSibling }>
            { areDetailsOpened ? <ConversationsDetailsView /> : <ConversationsView /> }
        </Grid>
        <Grid item sm={ 7 } md={ 7 } lg={ 8 } style={ { maxWidth: `${ maxWidth }px` } }>
            <ConversationWindow />
        </Grid>
    </Hidden>
}

const ConversationPage = ({ location, match }) => {
    const { areHeadersSet } = useContext(AppGlobalContext)
    const { openConversation } = useContext(ConversationsContext)
    const routeIdParam = match.params.id
    const areDetailsOpened = match.path.indexOf('details') !== -1

    useEffect(() => {
        if (!areHeadersSet || !routeIdParam) {
            return
        }
        openConversation(routeIdParam)
    }, [areHeadersSet, location])

    return <Fragment>
        <DesktopView areDetailsOpened={ areDetailsOpened } />
        <MobileView areDetailsOpened={ areDetailsOpened } />
    </Fragment>
}

ConversationPage.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
}

DesktopView.propTypes = {
    areDetailsOpened: PropTypes.bool
}

MobileView.propTypes = {
    areDetailsOpened: PropTypes.bool
}

export default withRouter(ConversationPage)