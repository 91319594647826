import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'
import CameraIcon from '../../shared/assets/CameraIcon'

const EventActiveStreams = ({ streamsSize = 0, isActive, onClick }) => {

    return (
        <EventItemWrapper icon={<CameraIcon size={16} color={streamsSize > 0 ? '#d4715d' : '#000'} />} isActive={isActive} onClick={onClick}>
            <EventTimeText>
                <TextContainer
                    noWrap={true}
                    color='textPrimary'
                    gutterBottom={true}>
                    {streamsSize > 0 ? streamsSize : null} Live streams
                </TextContainer>
            </EventTimeText>
        </EventItemWrapper>
    )
}

EventActiveStreams.propTypes = {
    streamsSize: PropTypes.number,
    isActive: PropTypes.bool,
    onClick: PropTypes.func
}

const TextContainer = styled(Typography)`
  ${ ({ theme }) => `
      font-size : ${ theme.spacing(2)}px;
      font-weight : 500;
       
  ` };
`
const EventTimeText = styled.div`
    display : flex;
    flex-direction : column
`
export default EventActiveStreams
