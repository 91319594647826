import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { ConfirmationDialog } from '../../shared/'

const DeleteEventBtn = ({ isDialogOpen, isMobile, openDialog, closeDialog, deleteEvent }) => {
    const { t } = useTranslation('', i18n)

    return <>
        <DeleteButton
            ismobile={ isMobile ? 1 : 0 }
            variant='outlined'
            onClick={ openDialog }
            data-test-id='delete-event-btn'
        >{ isMobile ? t('button.deleteEvent') : t('button.delete') }</DeleteButton>
        <ConfirmationDialog
            open={ isDialogOpen }
            title={ t('event.deleteModal') }
            onClose={ closeDialog }
            confirm={ deleteEvent }
        />
    </>
}

const DeleteButton = styled(Button)`
    border-color: ${ ({ theme }) => theme.palette.error.main };
    color: ${ ({ theme }) => theme.palette.error.main };
    margin: 0 10px;
    padding: 0 10px;
    width: ${({ ismobile }) => ismobile ? 'calc(100% - 20px)' : '100px' };

    &: hover {
        background-color: rgba(244, 67, 54, 0.1);
    }
`

DeleteEventBtn.propTypes = {
    isDialogOpen: PropTypes.bool,
    isMobile: PropTypes.bool,
    openDialog: PropTypes.func,
    closeDialog: PropTypes.func,
    deleteEvent: PropTypes.func,
}

export default DeleteEventBtn