import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CardMedia, Container, Typography } from '@material-ui/core'
import { CalendarSchema, useCalendar } from '../'

const CalendarCover = () => {
    const { calendar } = useCalendar()

    return <>{ calendar.coverPhoto &&
        <Container data-test-id='calendar-cover-wrapper'>
            <StyledCardMedia
                component='img'
                image={ calendar.coverPhoto.croppedUrl }
                title={ calendar.coverPhoto.croppedUrl }
                data-test-id='calendar-photo'
                onError={ e => e.target.src = calendar.coverPhoto.url }
            />
            <CalendarTitle gutterBottom variant='h5' component='p' data-test-id='calendar-title'>
                { calendar.name }
            </CalendarTitle>
        </Container>
    }</>
}

const StyledCardMedia = styled(CardMedia)`
    height: 200px;
    margin: 0 0 20px 0;
`

const CalendarTitle = styled(Typography)`
    font-weight: 700;
    max-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

CalendarCover.propTypes = {
    calendar: PropTypes.shape(CalendarSchema),
}

export default CalendarCover