import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Avatar } from '@material-ui/core'
import { getInitialsFromName } from '../../shared/'

const ProfileBasicInfo = ({ profile }) => {
    return <>
        <UserAvatar data-test-id='profile-avatar' src={ profile.imageLink }>
            { getInitialsFromName(profile.name) }
        </UserAvatar>
        <ProfileName data-test-id='profile-name'>{ profile.name }</ProfileName>
    </>
}

const UserAvatar = styled(Avatar)`
    height: 128px;
    font-size: 30px;
    margin: 0 auto;
    width: 128px;
`

const ProfileName = styled.p`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`

ProfileBasicInfo.propTypes = {
    profile: PropTypes.shape({
        name: PropTypes.string,
        imageLink: PropTypes.string
    })
}

export default ProfileBasicInfo
