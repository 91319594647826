import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import romanian from '../src/languages/ro.json'
import english from '../src/languages/en.json'

const resources = {
    en: { translation: english },
    ro: { translation: romanian }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: [ 'navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain' ],
        },
        whitelist: [ 'en', 'ro' ],
        nonExplicitWhitelist: true,
        load: 'languageOnly',
        resources,
        fallbackLng: 'en',
        debug: true,
        useSuspense: true,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n