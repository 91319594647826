import React from 'react'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { NotificationSchema } from '../schemas/notification.schema'

import NotificationActions from './NotificationActions'
import UserAvatar from '../../shared/components/UserAvatar'

import { CircleIcon } from '../../shared/assets/CircleIcon'
import { getBodyContent, getTitleContinuation } from './contents'
import { notificationMoment } from '../utils/notification.utils'
import constants from '../utils/notification.constants'

const Notification = ({ data, onPressView, onPositiveAction, onNegativeAction, onTouchAction }) => {
  const { sender, initiator, notificationType, status, payload, ...notification } = data
  const chosen = status && status !== constants.status.pending
  const showActions = status || payload?.requiresRSVP !== undefined

  return <CardWrapper>
    <ContentContainer read={ notification.hasBeenRead ? 1 : 0 } onClick={ onTouchAction }>
      <Grid
        container
        spacing={ 1 }
        wrap='nowrap'
      >
        <AvatarWrapper item>
          <UserAvatar user={ { ...sender, userId: initiator } } size='40' />
        </AvatarWrapper>

        <Grid item sm={ 10 } xl={ 12 }>
          <div>
            <SenderName data-test-id='notification-name'>{ sender.name }</SenderName>
            <span data-test-id='notification-title'>{ getTitleContinuation(data) }</span>
          </div>

          <div>
            <Typography variant='body1' data-test-id='notification-details'>
              { getBodyContent(data, onPressView) }
            </Typography>
            {
              showActions &&
              <NotificationActions
                notificationType={ notificationType }
                status={ status }
                chosen={ chosen }
                onPositiveAction={ onPositiveAction }
                onNegativeAction={ onNegativeAction } />
            }
          </div>
        </Grid>

        <Grid item container xs={ 2 } direction='column' alignItems='flex-end'>
          <NotificationDot data-test-id='notification-icon'>
            { !notification.hasBeenRead ? <CircleIcon filled /> : null }
          </NotificationDot>

          <DateLabel data-test-id='notification-time' variant={ 'subtitle1' }
            noWrap>{ notificationMoment(notification.actionDate) }</DateLabel>
        </Grid>
      </Grid>

    </ContentContainer>
  </CardWrapper>
}

Notification.propTypes = {
  data: PropTypes.shape(NotificationSchema),
  onPressView: PropTypes.func,
  onPositiveAction: PropTypes.func,
  onNegativeAction: PropTypes.func,
  onTouchAction: PropTypes.func
}

export default Notification

const CardWrapper = styled(Card)`
  margin: 12px 6px 12px 6px;
`

const AvatarWrapper = styled(Grid)`
  margin-right: 6px;
`

const ContentContainer = styled(CardContent)`
  display: flex;
  background: ${ ({ theme, read }) => read ? theme.palette.background.default : theme.palette.background.label };
`

const SenderName = styled.span`
  font-weight: 700;
`

const NotificationDot = styled.div`
  height: 12px;
`

const DateLabel = styled(Typography)`
  width: 100%;
  text-align: end; 
`