import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { Switch, Typography } from '@material-ui/core'

const FormikSwitchFieldGroup = (props) => {
    const [field] = useField(props)
    const { id, name, label } = props

    return (
        <>
            { label && <Typography
                htmlFor={ id || name }
                data-test-id={ `${ name.toLowerCase() }-label` }>
                { label }
            </Typography> }
            <Switch { ...field } { ...props }
                data-test-id={ `${ name.toLowerCase() }-switch` } />
        </>
    )
}

FormikSwitchFieldGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string
}

export default FormikSwitchFieldGroup