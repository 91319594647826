import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { Container, Button, Typography, Link } from '@material-ui/core'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import { useAccount, Header, Footer } from '..'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { FormikFieldGroup, FormikPasswordField, EMAIL_REGEX, PASSWORD_REGEX, getRecaptchaSiteKey } from '../../shared'

const RegisterComponent = ({ isSmallMobile, isLandscapeMobile }) => {
    const { handleRegister } = useAccount()

    const { t } = useTranslation('', i18n)

    const validate = values => {
        const errors = {}

        if (!values.name) {
            errors.name = t('registerScreen.error.nameRequired')
        } else if (values.name.trim().length < 3) {
            errors.name = t('registerScreen.error.nameTooShort')
        } else if (values.name.trim().length > 40) {
            errors.name = t('registerScreen.error.nameTooLong')
        }

        if (!values.email) {
            errors.email = t('registerScreen.error.emailRequired')
        } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = t('registerScreen.error.emailInvalid')
        }

        if (!values.password) {
            errors.password = t('passwordValidations.passwordRequired')
        } else if (values.password.length < 6) {
            errors.password = t('passwordValidations.passwordTooShort')
        } else if (values.password.length > 30) {
            errors.password = t('passwordValidations.passwordTooLong')
        } else if (!PASSWORD_REGEX.test(values.password)) {
            errors.password = t('passwordValidations.passwordInvalid')
        }

        if (!values.recaptchaCode) {
            errors.recaptchaCode = t('recaptcha.recaptchaRequired')
        }

        return errors
    }

    return <>
        <Header isSmallMobile={ isSmallMobile }
            isLandscapeMobile={ isLandscapeMobile }
            title={ t('registerScreen.title') }
            marginValue={ isSmallMobile ?
                '25px;' : (isLandscapeMobile ? '25px;' : '60px 0 40px 0;') } />

        <Formik
            initialValues={ {
                name: '',
                email: '',
                password: '',
                recaptchaCode: ''
            } }
            validate={ validate }
            onSubmit={ handleRegister }
        >{ ({
            setFieldValue, errors, submitCount, setFieldError
        }) => {
            const isRecaptchaInvalid = (errors.recaptchaCode && submitCount !== 0) || (errors.recaptchaCode === t('recaptcha.recaptchaError'))
            return <FormContainer issmallmobile={ isSmallMobile ? 1 : 0 }>
                <StyledForm>
                    <FormikFieldGroup
                        label='Name'
                        name='name'
                        type='text'
                        placeholder='John Doe'
                        elevation={ 2 }
                        padding={ 12 }
                    />
                    <FormikFieldGroup
                        label='Email'
                        name='email'
                        type='text'
                        placeholder='johndoe@gmail.com'
                        elevation={ 2 }
                        padding={ 12 }
                    />
                    <FormikPasswordField label={ t('loginScreen.password') } />
                    <Terms>
                        { t('recaptcha.recaptchaPolicies_1') }
                        <Link
                            target="_blank"
                            underline='none'
                            rel="noopener"
                            href='https://policies.google.com/privacy'
                            color='primary'
                            data-test-id='recaptcha-privacy-link'>
                            { t('registerScreen.TC.link_privacy') }
                        </Link> { t('recaptcha.recaptchaPolicies_2') }
                        <Link
                            target="_blank"
                            underline='none'
                            rel="noopener"
                            href='https://policies.google.com/terms'
                            color='primary'
                            data-test-id='recaptcha-terms-link'>
                            { t('registerScreen.TC.link_tc') }
                        </Link> { t('recaptcha.recaptchaPolicies_3') }
                    </Terms>
                    <div>
                        <ReCAPTCHA
                            sitekey={ getRecaptchaSiteKey() }
                            onChange={ value => setFieldValue('recaptchaCode', value) }
                            size='normal'
                            onErrored={ () => setFieldError('recaptchaCode', t('recaptcha.recaptchaError')) }
                            data-test-id='recaptcha-checkbox'
                        />
                        { isRecaptchaInvalid && (<ErrorMsg data-test-id='recaptcha-error'>{ errors.recaptchaCode }</ErrorMsg>) }
                    </div>

                    <Terms data-test-id='tc-privacy'>
                        { t('registerScreen.TC.text_1') }
                        <Link
                            target="_blank"
                            underline='none'
                            rel="noopener"
                            href='https://allcal.com/terms/'
                            color='primary'
                            data-test-id='terms-link'>
                            { t('registerScreen.TC.link_tc') }
                        </Link>
                        { t('registerScreen.TC.text_2') }
                        <Link
                            target="_blank"
                            underline='none'
                            rel="noopener"
                            href='https://allcal.com/privacy-policy/'
                            color='primary'
                            data-test-id='privacy-policy-link'>
                            { t('registerScreen.TC.link_privacy') }
                        </Link>
                    </Terms>

                    <RegisterButton
                        color='primary'
                        type='submit'
                        size='small'
                        variant='contained'
                        data-test-id='register-btn'
                        islandscapemobile={ isLandscapeMobile ? 1 : 0 }>{ t('registerScreen.registerBtn') }</RegisterButton>
                </StyledForm>
            </FormContainer>
        }
            }
        </Formik>
        <Footer
            isSmallMobile={ isSmallMobile }
            text={ t('registerScreen.loginHere.text') }
            linkText={ t('registerScreen.loginHere.link') }
            goToPath='login'
        />
    </>
}

const ErrorMsg = styled(Typography)`
    color: ${({ theme }) => theme.palette.error.main };
    font-size: 12px;
    text-align: right;
`

const StyledForm = styled(Form)`
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const FormContainer = styled(Container)`
    max-width: ${ ({ issmallmobile }) => issmallmobile ? 'unset' : '368px' };
`

const Terms = styled(Typography)`
    font-size: 15px;
    margin-top: 14px;
    text-align: center;
`

const RegisterButton = styled(Button)`
    margin-top: ${ ({ islandscapemobile }) => islandscapemobile ? '10px' : '16px' };
    width: 100%;
`

RegisterComponent.propTypes = {
    isSmallMobile: PropTypes.bool,
    isLandscapeMobile: PropTypes.bool
}

export default RegisterComponent