import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { CalendarsReducer } from '../calendars'
import { EventsReducer } from '../events'
import { RsvpsReducer } from '../rsvps'
import { EventDetailsReducer } from '../eventDetails'
import mainSaga from './middleware/main-saga'

const sagaMiddleware = createSagaMiddleware()

// TODO make this so reducers can lazily register themselves
const store = createStore(combineReducers({
    calendars: CalendarsReducer,
    events: EventsReducer,
    eventDetails: EventDetailsReducer,
    rsvps: RsvpsReducer
}), applyMiddleware(
    sagaMiddleware
))

sagaMiddleware.run(mainSaga)
export default store