import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Loader, SettingCard, ConfirmationDialog } from '../../shared'
import { CalendarSchema, updateCalendar, deleteCalendar, deleteCalendarById } from '../'
import { useCalendar } from '../context/CalendarProvider'

import { useAlerts } from '../../alerts'
const CalendarSettings = () => {
    const history = useHistory()
    const { calendar, dispatch, setCalendar } = useCalendar()
    const { t } = useTranslation('', i18n)
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
    const openDialog = () => setIsDeleteConfirmationOpen(true)
    const closeDialog = () => setIsDeleteConfirmationOpen(false)

    const { showSnack } = useAlerts()

    const calendarSettings = calendar.calendarSettings

    const handleChange = name => event => {
        const newSettings = { ...calendarSettings, [name]: event.target.checked }
        setCalendar({ ...calendar, calendarSettings: newSettings })
        dispatch(updateCalendar(calendar.calendarId, newSettings))
    }
    const hasChatThread = () => calendar.linkedEntities?.chatId

    const currentCalendar = useSelector(state => state.calendars.items.find(cal => cal.calendarId === calendar.calendarId))
    const hasDeletePermission = () => calendar.currentUser?.isOwner && !currentCalendar?.isPersonal

    const deleteCal = async () => {
        const status = await deleteCalendar(calendar.calendarId)

        const handleSuccess = () => {
            dispatch(deleteCalendarById(calendar.calendarId))
            history.replace('/calendars')
            showSnack(t('calendar.settings.deleteCalendar.success'))
        }

        status === 204 ? handleSuccess() : showSnack(t('calendar.settings.deleteCalendar.fail'), 'error')
    }

    return (
        <StyledContainer data-test-id='setting-card-list'>
            { calendarSettings ?
                (<>
                    <SettingCard
                        checked={ calendarSettings.enableNotices }
                        onChange={ handleChange('enableNotices') }
                        title={ t('calendar.settings.notices.title') }
                        description={ t('calendar.settings.notices.text') }
                        name={ 'enableNotices' }
                    />

                    { hasChatThread() &&
                        <SettingCard checked={ calendarSettings.enableChatPushNotifications }
                            onChange={ handleChange('enableChatPushNotifications') }
                            title={ t('calendar.settings.chat.title') }
                            description={ t('calendar.settings.chat.text') }
                            name={ 'enableChatNotif' }
                        /> }

                    <SettingCard checked={ calendarSettings.enableETA } onChange={ handleChange('enableETA') }
                        title={ t('calendar.settings.eta.title') }
                        description={ t('calendar.settings.eta.text') }
                        name={ 'enableETA' }
                    />

                    { hasDeletePermission() && <>
                        <SettingCard
                            isDeleteCard={ true }
                            handleDelete={ openDialog }
                            title={ t('calendar.settings.deleteCalendar.title') }
                            description={ t('calendar.settings.deleteCalendar.text') }
                            name='delete-calendar'
                        />
                        <ConfirmationDialog
                            open={ isDeleteConfirmationOpen }
                            title={ t('calendar.settings.deleteCalendar.text') + t('calendar.settings.deleteCalendar.confirmation') }
                            onClose={ closeDialog }
                            confirm={ deleteCal }
                        />
                    </> }

                </>)
                : <Loader /> }
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    margin-top: 20px;
`

CalendarSettings.propTypes = {
    calendar: PropTypes.shape(CalendarSchema)
}

export default CalendarSettings
