import React from 'react'
import PropTypes from 'prop-types'

function ErrorIcon(props) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M-4-4h24v24H-4z' />
        <path
          fill={props.fill ? props.fill : '#E76D65'}
          d='M11.6 11.59c-.39.39-1.03.39-1.42 0L8.01 9.42 5.9 
          11.53c-.39.39-1.03.39-1.42 0a1.001 1.001 0 010-1.41L6.6 
          8 4.66 6.07c-.2-.2-.29-.45-.29-.71a.996.996 0 011.7-.71l1.94 1.94 2-2a.996.996 
          0 011.41 0c.39.39.39 1.03 0 1.42L9.42 8l2.18 2.18c.39.39.39 1.02 0 1.41M8 0C3.58 
          0 0 3.59 0 8c0 4.42 3.58 8 8 8s8-3.58 8-8c0-4.41-3.58-8-8-8'
        />
      </g>
    </svg>
  )
}

ErrorIcon.propTypes = {
  fill: PropTypes.string
}

export default ErrorIcon
