import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
    GET_RSVPS, GET_RSVP_EVENT, MODIFY_MY_RSVP, GET_USERSTATUS_EVENT,
    setAllRsvps, getRsvps, getEventUserStatusData, getEventRsvpData, setEventRsvpData, postMyRsvp, setMyRSVP, setUserEventStatus
} from '..'

function* fetchRsvps(action) {
    const response = yield call(getRsvps)
    const rsvpsData = response.data
    yield put(setAllRsvps(rsvpsData))

}

function* fetchUserEventStatus(action) {
    const response = yield call(getEventUserStatusData, action.payload.eventId)
    const userEventStatus = response.data
    yield put(setUserEventStatus(userEventStatus))

}

function* postMyRsvpInfo(action) {
    const response = yield call(postMyRsvp, action.payload.status, action.payload.attendanceStatus, action.payload.rsvpId)
    const rsvpsData = response.data
    yield put(setMyRSVP(rsvpsData))

}

export function* RsvpsFetchSaga() {
    yield takeLatest(GET_RSVPS, fetchRsvps)
    yield takeLatest(MODIFY_MY_RSVP, postMyRsvpInfo)
    yield takeLatest(GET_USERSTATUS_EVENT, fetchUserEventStatus)
}

function* fetchRsvpForEvent(action) {
    const response = yield call(getEventRsvpData, action.payload.eventId, action.payload.skipNumber)
    const rsvpsData = response.data
    yield put(setEventRsvpData(rsvpsData, action.payload.eventId, action.payload.skipNumber))
}

export function* RsvpCountFetchSaga() {
    yield takeEvery(GET_RSVP_EVENT, fetchRsvpForEvent)
}
