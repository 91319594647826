import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchAccount, resendConfirmationEmail, updateProfile } from '../services/profile.datasource'
import { useAccount } from '../../account'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import { useAlerts } from '../../alerts'
import { useNavigation } from '../../navigation'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { NAME_REGEX, PHONE_REGEX } from '../../shared'
import { isEmptyString } from '../../shared/utils/entityValidationUtils'
import { useHistory } from 'react-router'
const ProfileContext = createContext({})

const ProfileProvider = ({ children }) => {
    const history = useHistory()
    const { setIsCompleteProfile } = useNavigation()
    const { t } = useTranslation('', i18n)
    const { account, clearData, updateAccountInLocalStorage } = useAccount()
    const { showSnack } = useAlerts()
    const [profile, setProfile] = useState()
    const [hasChanged, setHasChanged] = useState(false)
    const [croppedImg, setCroppedImg] = useState()
    const isLoading = !profile

    useAsyncEffect(function* () {
        const profile = yield fetchAccount(account.userId)
        setProfile(profile)
    }, [])

    const logout = () => clearData()

    const resendConfEmail = async () => {
        const emailSent = await resendConfirmationEmail(profile.email)
        if (emailSent) {
            showSnack(t('profile.resendConfirmationSuccess'), 'success')
        } else {
            showSnack(t('profile.resendConfirmationFailure'), 'error')
        }
    }

    const validate = values => {
        const errors = {}
        if (!values.name) {
            errors.name = t('profile.errors.nameRequired')
        } else if (values.name.trim().length < 3) {
            errors.name = t('profile.errors.nameTooShort')
        } else if (!NAME_REGEX.test(values.name)) {
            errors.name = t('profile.errors.nameInvalid')
        } else if (values.name.trim().length > 40) {
            errors.name = t('profile.errors.nameTooLong')
        }

        if (values.description) {
            if (isEmptyString(values.description)) {
                errors.description = t('profile.errors.descriptionInvalid')
            }

            if (values.description.length > 300) {
                errors.description = t('profile.errors.descriptionTooLong')
            }
        }

        if (values.phoneNumber && (isEmptyString(values.phoneNumber) || !PHONE_REGEX.test(values.phoneNumber))) {
            errors.phoneNumber = t('profile.errors.phoneInvalid')
        }

        if (values.linkedIn && isEmptyString(values.linkedIn)) {
            errors.linkedIn = t('profile.errors.linkedInInvalid')
        }

        if (values.facebook && isEmptyString(values.facebook)) {
            errors.facebook = t('profile.errors.facebookInvalid')
        }

        return errors
    }

    const handlePrivacySwitch = (value, property) => {
        setProfile({
            ...profile,
            privacy: {
                ...profile.privacy,
                [property]: value
            }
        })
    }

    const handlePictureChange = () => {
        setProfile({ ...profile, imageLink: croppedImg })
        setHasChanged(true)
    }

    const handleProfileSave = async (formValues) => {
        const { name, description, phoneNumber, linkedIn, facebook } = formValues

        const updatedProfile = await updateProfile(profile.userId, {
            name, description, imageLink: profile.imageLink,
            contact: { phoneNumber, linkedIn, facebook },
            privacy: { ...profile.privacy }
        }, croppedImg)

        // updating the account object in local storage
        if (updatedProfile) {
            account.contact = updatedProfile.contact
            account.description = updatedProfile.description
            account.hasUpdatedProfile = updatedProfile.hasUpdatedProfile
            updateAccountInLocalStorage(account)
            setIsCompleteProfile(true)
        }

        setHasChanged(false)
        history.goBack()
    }

    return <ProfileContext.Provider
        value={ {
            profile, validate, handleProfileSave,
            hasChanged, setHasChanged, isLoading,
            logout, resendConfEmail, handlePrivacySwitch,
            croppedImg, setCroppedImg, handlePictureChange
        } }>
        { children }
    </ProfileContext.Provider>
}

ProfileProvider.propTypes = {
    children: PropTypes.object
}

export const useProfile = () => useContext(ProfileContext)

export default ProfileProvider