import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Link } from '@material-ui/core'
import styled from 'styled-components'
import { useHistory } from 'react-router'

const Footer = ({ isSmallMobile, text, linkText, goToPath }) => {
    const history = useHistory()

    const changePage = page => e => {
        e.preventDefault()
        history.push(page)
    }

    return <FooterText data-test-id='footer-text' issmallmobile={ isSmallMobile ? 1 : 0 } >
        { text }
        <StyledLink
            color='primary'
            underline='none'
            onClick={ changePage(goToPath) }
            data-test-id='footer-link'>
            { linkText }
        </StyledLink>
    </FooterText>
}

const FooterText = styled(Typography)`
    font-size: 15px;
    text-align: center;
    margin: ${ ({ issmallmobile }) => issmallmobile ? '40px 0 25px 0' : '40px 0 60px 0' };
`

const StyledLink = styled(Link)`
    cursor: pointer;
`

Footer.propTypes = {
    isSmallMobile: PropTypes.bool,
    text: PropTypes.string,
    linkText: PropTypes.string,
    goToPath: PropTypes.string
}

export default Footer