import { createMuiTheme } from '@material-ui/core'

const dpTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#0075b8',
            contrastText: '#fff'
        },
        secondary: {
            main: 'rgba(27, 31, 33, .4)',
            contrastText: '#000',
            default: '#1b1f21'
        },
        background: {
            paper: '#FFF',
            default: '#f7f9fa'
        },
        spacing: '8px',
        divider: '#dfeaf0',
        error: {
            main: '#b83200'
        }
    },
    overrides: {
        MuiInput: {
            root: {
                fontSize: '14px',
                width: '75px',
                '&:before, &:after': {
                    display: 'none'
                }
            },
            input: {
                backgroundColor: '#f7f9fa',
                borderRadius: '4px',
                color: '#1b1f21',
                cursor: 'pointer',
                height: '20px',
                marginRight: '10px',
                padding: '2px',
                textAlign: 'center'
            }
        }
    }
})

export default dpTheme