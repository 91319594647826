
import { numberOfSkeletonItems, bottomGutterHeight, eventCardHeight, gutterBetweenTwoEvents, dateHeaderHeight } from '..'

export const getRowHeightForKey = (groupedEvents, index) => {
    const keyToLookFor = Object.keys(groupedEvents)[index]
    let numberOfItems = groupedEvents[keyToLookFor].length
    return (numberOfItems * (eventCardHeight + gutterBetweenTwoEvents)) + bottomGutterHeight + dateHeaderHeight
}

export const getRowHeightIncludingDummyRows = (groupedEvents, index) => {
    return getRowHeightForKey(groupedEvents, index)
        + (numberOfSkeletonItems * (eventCardHeight + gutterBetweenTwoEvents))
        + numberOfSkeletonItems * (bottomGutterHeight + dateHeaderHeight)

}