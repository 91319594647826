import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

const EventItemWrapper = ({ children, icon, isActive, onClick }) => {
    return <PaperDiv elevation={0} onClick={onClick}>
        <Grid container spacing={2} justify='center' alignItems='center'>
            <Grid item xs={1} md={2} lg={1} >
                {icon}
            </Grid>
            <EventItemContent container item xs={11} md={10} lg={11} isactive={isActive ? 1 : 0}>
                {children}
            </EventItemContent>
        </Grid>
    </PaperDiv>
}

EventItemWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.element,
    isActive: PropTypes.bool,
    onClick: PropTypes.func
}

const PaperDiv = styled(Paper)`
    ${ ({ theme }) => `
        padding-bottom: ${theme.spacing(3)}px
    ` };
`
const EventItemContent = styled(Grid)`
${props =>
        `cursor: pointer; 
        flex-grow : 1;
        background : ${props.isactive ? props.theme.palette.background.default : 'transparent'} ;
        &: hover{
            background: ${ props.theme.palette.background.default};
        }`
    }
`

export default EventItemWrapper