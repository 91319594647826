/* eslint-disable */
import React from 'react'

export const ChatIcon = ({
    width = 20,
    height = 20,
    filled
}) => <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 20 20'>
        {filled
            ? <path fill='#127AB3' fillRule='evenodd'
                d='M20 8.889c0 4.91-4.477 8.889-10 8.889-1.053 0-2.068-.145-3.022-.413l-3.187 2.53c-.331.263-.791 0-.791-.452v-4.206c-1.851-1.613-3-3.862-3-6.348 0-4.91 4.477-8.89 10-8.89s10 3.98 10 8.89z' />
            : <path fill='#1B1F21' fillRule='evenodd'
                d='M20 8.888c0 4.91-4.477 8.89-10 8.89-1.053 0-2.068-.145-3.022-.414l-3.187 2.53c-.331.263-.791 0-.791-.452v-4.206c-1.851-1.613-3-3.861-3-6.348C0 3.978 4.477 0 10 0s10 3.98 10 8.89zm-10 7.556c-.737 0-1.454-.078-2.138-.225l-.787-.202a9.596 9.596 0 0 1-.337-.11L4.2 17.923V14.57c-1.839-1.385-3-3.418-3-5.683 0-4.173 3.94-7.556 8.8-7.556 4.86 0 8.8 3.383 8.8 7.556s-3.94 7.556-8.8 7.556z'
                opacity='.4' />
        }
    </svg>
