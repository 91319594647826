import {
    NOTIFICATION_EVENT_INFO_SHARE,
    NOTIFICATION_EVENT_SHARE,
    NOTIFICATION_SHARE_CALENDAR
} from './notification.type.constants'

import { STATUS_PENDING } from '../services/activity.datasource'
import { buildEventUrl } from '../../events'
import moment from 'moment'

const getActionOptions = (notification, history) => {
    const statusNotPending = notification.status !== STATUS_PENDING
    switch (notification.notificationType) {
        case NOTIFICATION_EVENT_SHARE:
        case NOTIFICATION_EVENT_INFO_SHARE: {
            const normalizedStatus = notification.status
                .replace('accepted', 'attending')
                .replace('rejected', 'not attending')
            const isPositive = normalizedStatus === 'attending'
            const { name, eventId } = notification.event
            return {
                positiveAction: 'Going',
                negativeAction: 'Not going',
                chosen: statusNotPending ? {
                    label: normalizedStatus === 'not attending' ? 'Not going' :
                        normalizedStatus === 'attending' ? 'Going' : 'In the loop',
                    isPositive,
                    viewFn: (e) => {
                        e.preventDefault()
                        return isPositive && history.push(buildEventUrl(name, eventId), {
                            state: { from: '/events', calendarId: notification.calendar.calendarId }
                        })
                    }
                } : {}
            }
        }
        case NOTIFICATION_SHARE_CALENDAR: {
            const normalizedStatus = notification.status
                .replace('rejected', 'declined')

            const isPositive = normalizedStatus !== 'declined'
            return {
                positiveAction: 'Accept',
                negativeAction: 'Decline',
                chosen: statusNotPending ? {
                    label: normalizedStatus,
                    isPositive,
                    viewFn: (e) => {
                        e.preventDefault()
                        return isPositive && history.push(`/calendars/${ notification.calendar.calendarId }/events`)
                    }
                } : {}
            }
        }
        default:
            return {}
    }
}

const boldString = (details, name) => {
    const startsAt = details.indexOf(name)

    if (startsAt <= -1) {
        return details
    }

    const endsAt = startsAt + name.length
    const start = details.slice(0, startsAt)
    const end = details.slice(endsAt, details.length)
    return `${ start } [b]${ name }[/b] ${ end }`
}

const boldifyDetails = (notification) => {
    let details = notification.details

    if (notification.event) {
        details = boldString(notification.event.name)
    }

    if (notification.calendar) {
        details = boldString(notification.calendar.name)
    }

    const payload = notification.payload
    if (payload && payload.changes && payload.changes.length) {
        const change = payload.changes[0]

        if (change.attribute === 'name') {
            const newValue = change.newValue
            details = boldString(newValue)
        }
    }

    return details
}

const notificationMoment = actionDate => {
    const createdAt = moment(actionDate)
    const currentDate = moment()

    if (currentDate.diff(createdAt, 'days') >= 7) {
        return createdAt.format('ddd DD MMM YY')
    }

    return createdAt.fromNow()
}

export {
    notificationMoment,
    getActionOptions,
    boldifyDetails
}