import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

import { CalendarSchema } from '../'

const CalendarAbout = ({ calendar }) => {

    const { t } = useTranslation('', i18n)

    return <>
        <Description data-test-id='calendar-details'>
            { calendar.details ? calendar.details :
                <NoDescriptionMessage>{ t('emptyMessages.calendarDescription') }</NoDescriptionMessage>
            }
        </Description>
    </>
}

CalendarAbout.displayName = 'About'

const Description = styled(Container)`
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 40px;
    margin-top: 20px;
    background-color: ${ ({ theme }) => theme.palette.background.default };
`

const NoDescriptionMessage = styled(Typography)`
    text-align: center;
`

CalendarAbout.propTypes = {
    calendar: PropTypes.shape(CalendarSchema)
}

export default CalendarAbout
