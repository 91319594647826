import React from 'react'

import { CalendarScreen, CreateEditCalendarScreen, MyCalendarsScreen } from '../calendars'
import { CreateEditEventScreen, EventsListScreen } from '../events'
import { ChatApp } from '../chat'
import { EditProfileScreen, ProfileScreen } from '../profile'
import { ActivityScreen } from '../activity'

import { AllcalIcon } from '../shared/assets/AllcalIcon'
import { ActivityIcon } from '../shared/assets/ActivityIcon'
import { EventsIcon } from '../shared/assets/EventsIcon'
import { ChatIcon } from '../shared/assets/ChatIcon'
import { ProfileIcon } from '../shared/assets/ProfileIcon'
import { EventDetailsScreen } from '../eventDetails'
import { NoMatchPage } from '../shared'
import { StreamScreen, ViewStreamScreen } from '../livestream'

export const Routes = [
    {
        path: '/calendars/:id', title: 'Calendar', component: CalendarScreen,
        routes: [
            {
                path: '/calendars/:id/events', title: 'Events'
            },
            {
                path: '/calendars/:id/people', title: 'People'
            },
            {
                path: '/calendars/:id/settings', title: 'Settings'
            },
            {
                path: '/calendars/:id/about', title: 'About'
            }
        ]
    },
    {
        path: '/calendars', title: 'Calendars', component: MyCalendarsScreen,
        icon: <AllcalIcon />, active: <AllcalIcon filled='true' />
    },
    {
        path: '/calendar/create', title: 'Create Calendar', component: CreateEditCalendarScreen
    },
    {
        path: '/calendar/edit/:id', title: 'Edit Calendar', component: CreateEditCalendarScreen
    },
    {
        path: '/events/:eventId/stream', title: 'Streaming', component: StreamScreen
    },
    {
        path: '/events/:eventId/watch/:streamId', title: 'Watching Live', component: ViewStreamScreen
    },
    {
        path: '/events/:eventId', title: 'Event', component: EventDetailsScreen
    },
    {
        path: '/events', title: 'Events', component: EventsListScreen,
        icon: <EventsIcon />, active: <EventsIcon filled='true' />
    },
    {
        path: '/event/create', title: 'Create event', component: CreateEditEventScreen
    },
    {
        path: '/event/edit/:id', title: 'Edit event', component: CreateEditEventScreen
    },
    {
        path: '/chat', title: 'Chat', component: ChatApp,
        icon: <ChatIcon />, active: <ChatIcon filled='true' />
    },
    {
        path: '/activity', title: 'Activity', component: ActivityScreen,
        icon: <ActivityIcon />, active: <ActivityIcon filled='true' />
    },
    {
        path: '/profile', title: 'Profile', component: ProfileScreen,
        icon: <ProfileIcon />, active: <ProfileIcon filled='true' />
    },
    {
        path: '/profile/edit', title: 'Edit Profile', component: EditProfileScreen
    },
    {
        path: '/404', title: 'Nope.', component: NoMatchPage
    },
    {
        path: '/', title: 'Calendars', component: MyCalendarsScreen
    },

]
