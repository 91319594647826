import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ConversationsPage from 'chat-app/src/components/pages/ConversationsPage'
import ConversationPage from 'chat-app/src/components/pages/ConversationPage'
import NewConversationPage from 'chat-app/src/components/pages/NewConversationPage'
import AppLoader from 'chat-app/src/components/common/AppLoader'

import styled from 'styled-components'
import { Grid, useMediaQuery } from '@material-ui/core'
import Overrides from 'chat-app/src/utils/overrides'
import useCustomAppTheme from 'chat-app/src/utils/themeProvider'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import useIntegrationCompat from 'chat-app/src/hooks/integrationCompatHook'

const ChatApp = () => {
  const { themeOptions } = useCustomAppTheme()
  const { siblingOffset } = useIntegrationCompat()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const iPad = navigator.userAgent.match(/(iPad)/) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

  return <ThemeProvider theme={ createMuiTheme(themeOptions) }>
    <StyledGrid container spacing={ 0 } sibling_offset={ siblingOffset } ismobile={ isMobile ? 1 : 0 } isipad={ iPad ? 1 : 0 }>
      <Overrides>
        <Switch>
          <Route exact path='/chat' component={ ConversationsPage } />
          <Route exact path='/chat/conversation/:id' component={ ConversationPage } />
          <Route exact path='/chat/conversation/:id/details' component={ ConversationPage } />
          <Route exact path='/chat/new' component={ NewConversationPage } />
        </Switch>
        <AppLoader />
      </Overrides>
    </StyledGrid>
  </ThemeProvider >
}

export default ChatApp

const StyledGrid = styled(Grid)`
  flex-wrap: unset;
  margin-top: ${({ ismobile }) => ismobile ? '0px' : '-20px' };
  overflow: hidden;

  &, & > div {
   height: ${ ({ isipad, ismobile, sibling_offset }) => !isipad && `calc(100vh - ${ sibling_offset }px - ${ ismobile ? '0px' : '40px' })` };
  }

  & > div {
    &:first-child {
      box-shadow:  15px 0px 60px 25px #ffffff inset, 
        0px 0px 10px -5px #000000 inset;
    }
  }
`
