/* eslint-disable */
import React from 'react'

export const CheckmarkCircleIcon = ({
                                        width = 512,
                                        height = 512,
                                        fill = '#3dab38'
                                    }) =>
  <svg xmlns="http://www.w3.org/2000/svg" width={ width } height={ height }
       viewBox="0 0 512 512">
      <path fill={ fill }
            d='M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z'/>
  </svg>
