import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Button, Tooltip } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import { ChatIcon } from '../../shared/assets/ChatIcon'
import { EventsIcon } from '../../shared/assets/EventsIcon'
import { createConversation } from '../services/profile.datasource'
import { useHistory } from 'react-router'

const UserProfileActions = ({ profile, setIsViewProfileMode }) => {
    const history = useHistory()
    const { t } = useTranslation('', i18n)

    const isBlocked = !profile.permissions.canCreateChat
    const openChatConversation = async () => {
        const conversationInfo = {
            type: 'group',
            photo: '',
            description: '',
            users: [profile.userId]
        }
        const conversation = await createConversation(conversationInfo)
        history.push(`/chat/conversation/${ conversation.conversationId }`)
    }

    const createMeet = () => {
        history.push('/event/create', { user: profile })
    }

    return <UserActions data-test-id='profile-user-actions'>
        <Tooltip
            disableFocusListener={ !isBlocked }
            disableHoverListener={ !isBlocked }
            disableTouchListener={ !isBlocked }
            title={ t('profile.other.blockedUser') }
            placement='bottom'
        >
            <span>
                <Action
                    disabled={ !profile.permissions.canCreateChat }
                    onClick={ openChatConversation }
                    data-test-id='profile-chat-btn'>
                    <ChatIcon filled={ profile.permissions.canCreateChat } />
                    <span>Chat</span>
                </Action>
            </span>
        </Tooltip>
        <Action
            onClick={ createMeet }
            data-test-id='profile-meet-btn'
        >
            <EventsIcon filled='true' />
            <span>{ t('profile.other.meet') }</span>
        </Action>
        <Action
            onClick={ () => setIsViewProfileMode(false) }
            data-test-id='profile-notes-btn'
        >
            <DescriptionIcon filled='true' />
            <span>{ t('profile.other.notes.title') }</span>
        </Action>
    </UserActions>
}

const UserActions = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    margin: 12px auto;
`

const Action = styled(Button)`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    text-transform: capitalize;
    width: 80px;

    &, svg, icon {
        color: ${ ({ theme }) => theme.palette.primary.main };
    }

    > span {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

UserProfileActions.propTypes = {
    profile: PropTypes.object,
    setIsViewProfileMode: PropTypes.func
}

export default UserProfileActions