import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Agora } from '../index'
import { useHistory, useParams } from 'react-router'
import { Button, Grid } from '@material-ui/core'
import { VolumeIcon } from '../../shared/assets/VolumeIcon'
import { VolumeMuteIcon } from '../../shared/assets/VolumeMutedIcon'
import { PlayIcon } from '../../shared/assets/PlayIcon'
import { PauseIcon } from '../../shared/assets/PauseIcon'
import { ArrowShrinkIcon } from '../../shared/assets/ArrowShrinkIcon'
import { removeLiveStream } from '../../eventDetails/redux/eventDetails.actions'
import { useDispatch } from 'react-redux'
import { useAlerts } from '../../alerts'

const ViewStreamScrean = () => {
    const dispatch = useDispatch()
    const { showSnack } = useAlerts()

    const history = useHistory()
    const params = useParams()
    const [audioMuted, setAudioMuted] = useState(false)
    const [autoPlayError, setAutoPlayError] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [stream, setStream] = useState(false)

    const returnToEvent = useCallback(() => {
        if (stream && stream.isPlaying) {
            stream.stop()
        }

        history.replace(`/events/${ params.eventId }`)
    }, [history, params, stream])

    useEffect(() => {
        const channel = Agora.getChannel()

        let stream
        if (channel) {
            stream = channel.streams.find(e => e.streamId === params.streamId)

            if (stream) {
                stream.unmuteAudio()
                setStream(stream)
                setPlaying(true)
            } else
                returnToEvent()
        } else
            returnToEvent()

    }, [history, params, returnToEvent])

    useEffect(() => {
        const unlisten = Agora.listen(evt => {
            switch (evt.type) {
                case 'connection-state-change': {
                    const { currState } = evt
                    if (currState === 'DISCONNECTED') {
                        returnToEvent()
                    }
                    break
                }
                case 'stream-subscribed': {
                    const channel = Agora.getChannel()
                    const stream = channel.streams.find(e => e.streamId === params.streamId)

                    if (stream) {
                        setStream(stream)
                    }
                    break
                }
                case 'peer-leave': {
                    if (evt.uid === stream.streamId) {
                        dispatch(removeLiveStream(evt.uid))
                        returnToEvent()
                    }
                    break
                }
                default:
                    break
            }
        })

        return () => {
            unlisten()
        }
    }, [dispatch, params, returnToEvent, stream])

    const streamResume = useCallback(() => {
        stream.resume().then(
            () => {
                setAutoPlayError(false)
            }).catch(
            reason => {
                showSnack('Failed resuming stream! Please use a different browser.')
                console.error('Failed resuming stream!', reason)
            }).finally(() => setAutoPlayError(false))
    }, [showSnack, stream])

    useEffect(() => {
        if (!stream) {
            return
        }

        if (!stream.isPlaying()) {
            stream.play('stream-player', { fit: 'contain' }, errState => {
                if (errState && errState.isErrorState) {
                    setAutoPlayError(true)
                    console.error('Failed playback!', errState)

                    // The playback fails. Guide the user to resume the playback by clicking.
                    document.querySelector('stream-player').onclick= () => {
                        streamResume()
                    }
                }
            })
        }
        setPlaying(true)

        return () => {
            if (stream.isPlaying())
                stream.stop()
        }
    }, [stream, streamResume])

    const toggleAudio = () => {
        if (autoPlayError) {
            return streamResume()
        }

        if (stream) {
            if (!audioMuted) {
                stream.muteAudio()
            } else {
                stream.unmuteAudio()
            }
        }

        setAudioMuted(!audioMuted)
    }

    const togglePlay = () => {
        if (stream) {
            if (playing) {
                stream.stop()
            } else {
                stream.play('stream-player', { fit: 'contain' })
            }
        }

        setPlaying(!playing)
    }

    return (
        <FullscreenContainer>
            <PlayerContainer id='stream-player'/>

            <ControlsBottom bottom={ 1 } container justify={ 'space-between' }>
                <Controls item xs={ 12 }>
                    <ControlButton startIcon={ playing ? <PauseIcon/> : <PlayIcon/> }
                                   onClick={ () => togglePlay() }><div/></ControlButton>

                    <Grid container direction={ 'row-reverse' }>
                        <ControlButton
                            startIcon={ <ArrowShrinkIcon/> }
                            onClick={ returnToEvent }><div/></ControlButton>

                        <ControlButton
                            startIcon={ audioMuted || autoPlayError ? <VolumeMuteIcon/> : <VolumeIcon/> }
                            onClick={ toggleAudio }><div/></ControlButton>
                    </Grid>

                </Controls>
            </ControlsBottom>

        </FullscreenContainer>
    )
}

export default ViewStreamScrean

const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: (100% + (100vh - 100%));
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  overflow: hidden;
  
  background-color: black;
  color: white;
`

const PlayerContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`

const Controls = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ControlButton = styled(Button)`
  min-width: auto;
`

const ControlsBackground = styled(Grid)`
  display: flex;
  width: 100%;
  z-index: 3;
  padding: 24px;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.0));
`

const ControlsBottom = styled(ControlsBackground)`
  position: absolute;
  bottom: 0;
  padding: 24px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.40));
`
