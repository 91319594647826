import { URL_REGEX } from './regexUtils'

export const getInitialsFromName = name => {
    const initials = name.split(/\s/).map(item => item[0])
    return initials.length > 1 ? [initials[0], initials[initials.length - 1]] : initials
}

export const getValidUrl = url => {
    if (!URL_REGEX.test(url)) {
        return null
    }
    if (!url.includes('http://') && !url.includes('https://')) {
        return `http://${ url }`
    }
    return url
}