/* eslint-disable */
import { instance } from './agora'

const notifyListeners = evt => {
    const { listeners } = instance
    listeners.forEach(l => {
        try {
          l(evt)
        } catch (e) {
            console.error(e)
        }
    })
}

const onConnectionState = evt => {
    console.debug('== Connection state change =====================')
    console.debug('Event', evt)

    notifyListeners(evt)
}

const onStreamPublished = evt => {
    console.debug('== On stream publish ===========================')
    console.debug('Event', evt)

    notifyListeners(evt)
}

const onAddRemoteStream = evt => {
    console.debug('== On add remote stream ========================')
    console.debug('Event', evt)

    const { client, channel } = instance

    if (channel.localStream?.streamId !== evt.stream.streamId)
        client.subscribe(evt.stream)

    notifyListeners(evt)
}

const onRemoveStream = evt => {
    console.debug('== On remove stream ============================')
    console.debug('Event', evt)

    const { client, channel } = instance

    if (channel.localStream?.streamId !== evt.stream.streamId) {
        client.unsubscribe(evt.stream)

        const index = channel.streams.findIndex(item => item.streamId === evt.stream.streamId)
        channel.streams.splice(index, 1)
    }

    notifyListeners(evt)
}

const onStreamSubscribed = evt => {
    console.debug('== On subscribe stream =========================')
    console.debug('Event', evt)

    const { channel } = instance

    if (channel.localStream?.streamId !== evt.stream.streamId) {
        channel.streams.push(evt.stream)
    }

    notifyListeners(evt)
}

const onPeerJoinLeave = evt => {
    console.debug('== On peer joined / leave ======================')
    console.debug('Event', evt)
    const { uid, reason } = evt

    if (reason === 'Quit') {
        const { channel } = instance

        const index = channel.streams.findIndex(item => item.streamId === uid)
        if (index >= 0) {
            channel.streams.splice(index, 1)
        }
    }

    notifyListeners(evt)
}

const onVideoMuted = evt => {
    console.debug('== On Video Muted ==============================')
    console.debug('Event', evt)

    notifyListeners(evt)
}

const onAudioMuted = evt => {
    console.debug('== On Audio Muted ==============================')
    console.debug('Event', evt)

    notifyListeners(evt)
}

export const subscribeEvents = () => {
    const { client } = instance

    client.on('connection-state-change', onConnectionState)

    client.on('stream-published', onStreamPublished)
    client.on('stream-added', onAddRemoteStream)
    client.on('stream-removed', onRemoveStream)
    client.on('stream-subscribed', onStreamSubscribed)
    client.on('peer-online', onPeerJoinLeave)
    client.on('peer-leave', onPeerJoinLeave)

    client.on('mute-video', onVideoMuted)
    client.on('unmute-video', onVideoMuted)

    client.on('mute-audio', onAudioMuted)
    client.on('unmute-audio', onAudioMuted)
}

export const unsubscribeEvents = () => {
    const { client } = instance
    client.off('connection-state-change', onConnectionState)
    client.off('stream-published', onStreamPublished)
    client.off('stream-added', onAddRemoteStream)
    client.off('stream-removed', onRemoveStream)
    client.off('stream-subscribed', onStreamSubscribed)
    client.off('peer-online', onPeerJoinLeave)
    client.off('peer-leave', onPeerJoinLeave)

    client.off('mute-video', onVideoMuted)
    client.off('unmute-video', onVideoMuted)

    client.off('mute-audio', onAudioMuted)
    client.off('unmute-audio', onAudioMuted)
}