import React, { Fragment } from 'react'
import styled from 'styled-components'
import PeopleIcon from '@material-ui/icons/People'
import RsvpInfo from '../../rsvps/components/RsvpInfo'
import LabelTypeButton from '../../shared/components/LabelTypeButton'
import { useTheme } from '@material-ui/styles'
import EventItemWrapper from './EventItemWrapper'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

function EventRsvp({ eventId, attendeeCount, onClick, isActive }) {
    const theme = useTheme()
    const { t } = useTranslation('', i18n)
    return (
        <EventItemWrapper icon={<PeopleIcon></PeopleIcon>} isActive={isActive} onClick={onClick}>
            <Fragment>
                <RsvpInfo eventId={eventId}></RsvpInfo>
                <LabelDiv>
                    <LabelTypeButton
                        variant="outlined"
                        textColor={theme.palette.primary.main}
                        cursor={false}>
                        {`+ ${attendeeCount} ${t('event.others')}`}
                    </LabelTypeButton>
                </LabelDiv>
            </Fragment>
        </EventItemWrapper>
    )
}

const LabelDiv = styled.div`
${
    ({ theme }) => `
        margin-left: ${theme.spacing(2)}px
    ` };
`
EventRsvp.propTypes = {
    eventId: PropTypes.string,
    attendeeCount: PropTypes.number,
    onClick: PropTypes.func,
    isActive: PropTypes.bool
}

export default EventRsvp
