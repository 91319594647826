import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Button, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'

import UserAvatar from '../../shared/components/UserAvatar'
import { ExpandIcon } from '../../shared/assets/ExpandIcon'
import { VolumeMuteIcon } from '../../shared/assets/VolumeMutedIcon'
import i18n from '../../../i18n'
import { VolumeIcon } from '../../shared/assets/VolumeIcon'

const EventStreams = ({ streams }) => {
    const placeholderInfo = {
        initiator: {
            name: 'Allcal user',
            imageLink: '/logo192.png',
        },
        streamName: 'Stream',
        userId: ''
    }

    const history = useHistory()
    const { t } = useTranslation('', i18n)

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const [streamsAudio, setStreamsAudio] = useState([])

    useEffect(() => {
        streams.forEach(stream => {
            if (!stream.isPlaying()) {
                stream.play(`stream-${ stream.streamId }`, { fit: 'contain' })
                stream.muteAudio()
            }
        })

        setStreamsAudio(streams.map(s => ({
            streamId: s.streamId,
            isMuted: true,
        })))

        return () => {
            streams.forEach(stream => {
                if (stream.isPlaying())
                    stream.stop()
            })
        }
    }, [streams])

    useEffect(() => {
        if (streamsAudio.length !== streams.length)
            return

        streams.forEach((stream, i) => {
            const { isMuted, prevState } = streamsAudio[i]

            if (isMuted) {
                !prevState && stream.muteAudio()
            } else {
                prevState && stream.unmuteAudio()
            }
        })
    }, [streams, streamsAudio])

    const openStream = stream => {
        stream.stop()
        history.push(`${ history.location.pathname }/watch/${stream.streamId}`)
    }

    const toggleMuteStream = stream => {
        setStreamsAudio(streamsAudio.map(s => ({
            ...s,
            prevState: s.isMuted,
            isMuted: stream.streamId === s.streamId ? !s.isMuted : true
        })))
    }

    return <Container center={ streams.length }>
        { !isMobile && streams.length > 0 &&
        <StreamsHeader>{ t('stream.peopleStreaming', { count: streams.length }) }</StreamsHeader> }
        { streams.map((e, i) => {
            const user = e.info ? e.info : placeholderInfo

            return <StreamCard key={ e.streamId }>
                <StreamPreview id={ `stream-${ e.streamId }` }>
                    <StreamOverlay />
                    <LiveLabel>Live</LiveLabel>
                    <StreamControls container spacing={ 1 }>
                        <Grid item>
                            <ControlButton onClick={() => openStream(e)} startIcon={<ExpandIcon size={ 16 }/>}>
                                <div/>
                            </ControlButton>
                        </Grid>
                        <Grid item>
                            <ControlButton onClick={() => toggleMuteStream(e)} startIcon={streamsAudio.length === streams.length && !streamsAudio[i].isMuted ? <VolumeIcon size={16}/> : <VolumeMuteIcon size={ 16 }/>}>
                                <div/>
                            </ControlButton></Grid>
                    </StreamControls>
                </StreamPreview>
                <StreamerInfo container justify='space-between' alignItems='center' direction='row'>
                    <Grid item>
                        <StreamName>{user.streamName}</StreamName>
                    </Grid>
                    <AvatarName item>
                        <UserAvatar user={ user.initiator } size='24'/>
                        <StreamerName>{ user.initiator.name }</StreamerName>
                    </AvatarName>
                </StreamerInfo>
            </StreamCard>
        }) }
    </Container>
}

EventStreams.propTypes = {
    streams: PropTypes.array
}

export default EventStreams

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${ ({ center }) => center ? 'center' : 'initial' };
  justify-content: center;
`

const StreamerInfo = styled(Grid)`
  padding: 6px;
`

const AvatarName = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const StreamerName = styled.div`
  margin: 0 6px 0 6px;
`

const StreamName = styled.div`
  margin: 6px 6px 0 6px;
  font-weight: 700;
  font-size: 18px;
`

const StreamCard = styled(Card)`
  width: 90%;
  height: 320px;
  margin: 10px;
  display: flex;
  flex-direction: column-reverse;
`

const StreamsHeader = styled.div`
  width: 90%;
  margin: 0 10px 0 10px;
  display: flex;
  font-size: 16px;
`

const StreamPreview = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 90%;
  z-index: 2;
`

const StreamOverlay = styled.div`
  background: radial-gradient(#0000, rgba(0,0,0, .26));
  height: 100%;
  width: 100%;
  z-index: 8;
  position: absolute;
`
const StreamControls = styled(Grid)`
  width: 100%;
  position: absolute;
  z-index: 9;
  bottom: 0;
  padding: 6px;
  display: flex;
  flex-direction: row-reverse;
`

const LiveLabel = styled.div`
  width: 48px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #f76240;
  color: #f76240;
  font-size: 16px;
  text-align: center;
  position: absolute;
  right: 0;
  z-index: 8;
  margin: 12px;
`

const ControlButton = styled(Button)`
  min-width: auto;
`
