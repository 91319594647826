import { array, arrayOf, bool, object, shape, string } from 'prop-types'

export const NotificationSchema = {
    details: string,
    payload: shape({
            changes: arrayOf < object > (shape(
                {
                    attribute: string,
                    oldValue: string,
                    newValue: string
                }))
        }
    ),
    hasBeenRead: bool,
    notificationType: string,
    createdDate: string,
    lastActionType: string,
    actionDate: string,
    notificationActionUrl: string,
    status: string,
    receiver: string,
    token: string,
    initiator: string,
    shouldShowActions: bool,
    displayProperties: array,
    userActionUrl: string,
    notificationId: string,
    sender: shape({
        name: string,
        imageLink: string
    }),
    calendar: shape({
        name: string,
        color: string,
        visibility: string,
        calendarId: string
    }),
    event: shape({
        name: string,
        timezone: string,
        startDate: string,
        endDate: string,
        isAllDay: bool,
        kind: string,
        eventId: string,
        coverPhotos: arrayOf < object > (shape({
                url: string,
                croppedUrl: string
            })
        )
    })
}