import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

const CategoryGroup = ({ id, title, children }) => {
    return <CategoryContainer>
        <CategoryTitle data-test-id={ id }>{ title }</CategoryTitle>
        { children }
    </CategoryContainer>
}

const CategoryContainer = styled(Grid)`
    width: 100%;
    padding-bottom: 16px;
`

const CategoryTitle = styled.div`
    color: ${ ({ theme }) => theme.palette.secondary.main };
    font-weight: 700;
    padding-left: 12px;
    text-transform: uppercase;
`

CategoryGroup.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.object
}

export default CategoryGroup