import React, { useContext, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import InfiniteScroll from '../../utils/react-bidirectional-infinite-scroll'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MessagesContext } from '../../managers/MessagesManager'
import { AppGlobalContext } from '../../managers/AppManager'
import { groupMessagesByTimestamp } from '../../utils/messages'
import MappedMessagesByDay from './MessagesList'
import { ConversationsContext } from '../../managers/ConversationsManager'
import useConversations from '../../hooks/conversationsHook'

const ConversationMessagesList = ({ listMaxHeight }) => {
    const { messages, isDataLoading, loadMoreMessages, hasMoreMessages,
        shouldScrollToBottom, setShouldScrollToBottom } = useContext(MessagesContext)
    const [firstMessagePosition, setFirstMessagePosition] = useState(0)
    const [loadedMore, setLoadedMore] = useState(false)
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const scrollableListRef = useRef(null)
    const refScrollToBottom = useRef(null)
    const { userChannel } = useContext(AppGlobalContext)
    const { openedConversation, openConversation, closeConversation, isSendingMessage, setIsSendingMessage } = useContext(ConversationsContext)
    const { getConversationById } = useConversations()

    const handleMessageEvent = (data) => {
        if (data.conversationId !== openedConversation.conversationId) {
            return
        }

        switch (data.type) {
            case 'created':
                // Functionality moved into the data layer, TODO remove this after managers refactor
                setIsInitialLoad(false)
                setIsSendingMessage(false)
                return
            default:
                return
        }
    }

    const handleUsersEvent = (data) => {
        if (data.conversationId !== openedConversation.conversationId) {
            return
        }

        switch (data.type) {
            case 'joined':
                openConversation(data.conversationId)
                return
            case 'left':
                getConversationById(data.conversationId)
                    .then(() => {
                        openConversation(data.conversationId)
                    })
                    .catch(closeConversation)
                return
            default:
                return
        }
    }

    const handleLoadMoreMessages = () => {
        !isDataLoading && hasMoreMessages && loadMoreMessages(null, false, messages.length)
        setLoadedMore(true)
    }

    const handleLoadedMedia = () => {
        setShouldScrollToBottom(true)
        manageMessagesListScroll(true)
    }

    const loadMessagesWithImages = () => {
        const messageImages = document.querySelectorAll(`[data-testid='message-attachment-photo']`)
        const messageVideos = document.querySelectorAll(`[data-testid='message-attachment-video'] video`)

        _.forEach(messageImages, media => media.addEventListener('load', handleLoadedMedia, false))
        _.forEach(messageVideos, media => media.addEventListener('loadeddata', handleLoadedMedia, false))
    }

    const manageMessagesListScroll = (fromLoadedMedia) => {
        const firstMessage = _.first(document.querySelectorAll(`[data-testid='message-wrapper']`))
        const scrollableListElement = _.get(scrollableListRef, 'current') ? scrollableListRef.current.scroller : {}
        const listElement = document.getElementById('list')

        const setListScrollTop = () => {
            // Stick the scroll bar where it is when loading more messages
            if (loadedMore) {
                return firstMessage.offsetTop - firstMessagePosition
            }

            // Determine if the user is scrolled up into the history
            // 1 = 100% = scrolled to the most recent message
            const currentPosition = listElement.parentElement.scrollTop / (listElement.parentElement.scrollHeight - listElement.parentElement.clientHeight)
            if (currentPosition <= 0.85 && !isInitialLoad) {
                return scrollableListElement.scrollTop
            }

            // If I am scrolled to bottom and media start reloading itself, just keep me where I am
            if (currentPosition === 1 && fromLoadedMedia) {
                return scrollableListElement.scrollTop
            }

            // Scroll to bottom by default
            return listElement.parentElement.scrollHeight
        }

        if (firstMessage) {
            scrollableListElement.scrollTop = setListScrollTop()
            setFirstMessagePosition(firstMessage.offsetTop)
            setShouldScrollToBottom(false)
        }
    }

    useEffect(() => {
        refScrollToBottom.current = shouldScrollToBottom
    }, [shouldScrollToBottom])

    useEffect(() => {
        if (!_.isEmpty(userChannel)) {
            userChannel.bind('messages', handleMessageEvent)
            userChannel.bind('users', handleUsersEvent)
        }
    }, [userChannel])

    useEffect(() => {
        setShouldScrollToBottom(!loadedMore)
        manageMessagesListScroll()
        loadMessagesWithImages()
    }, [messages])

    useEffect(() => {
        setShouldScrollToBottom(true)

        return () => {
            userChannel.unbind('messages', handleMessageEvent)
            userChannel.unbind('users', handleUsersEvent)
        }
    }, [])

    return <StyledScrollableList listmaxheight={listMaxHeight}>
        {isDataLoading &&
            <StyledLoaderWrapper>
                <CircularProgress style={{ width: '20px', height: '20px' }} color='secondary' />
            </StyledLoaderWrapper>
        }
        <InfiniteScroll position={100} onReachTop={handleLoadMoreMessages} ref={scrollableListRef}>
            <StyledConversationMessagesListWrapper id='list'>
                <MappedMessagesByDay mappedMessages={groupMessagesByTimestamp(messages)} />
            </StyledConversationMessagesListWrapper>
            {isSendingMessage && <StyledMessageLoaderWrapper>
                <CircularProgress style={{ width: '20px', height: '20px' }} color='secondary' />
            </StyledMessageLoaderWrapper>
            }
        </InfiniteScroll>
    </StyledScrollableList>
}

const StyledScrollableList = styled.div`
    height: ${props => props.listmaxheight}px;
    position: relative;
`

const StyledConversationMessagesListWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    min-height: 100%;
    flex-direction: column-reverse;
    overflow-y: auto;
    padding: 30px 15px 10px 15px;
`

const StyledLoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 5px;
    position: absolute;
    width: 100%;
`

const StyledMessageLoaderWrapper = styled(StyledLoaderWrapper)`
    bottom: 0;
`

ConversationMessagesList.propTypes = {
    listMaxHeight: PropTypes.number
}

ConversationMessagesList.defaultProps = {
    listMaxHeight: 0
}

export default ConversationMessagesList