import React from 'react'
import CreateEditEventProvider from '../context/CreateEditEventProvider'
import CreateEditEvent from '../components/CreateEditEvent'

const CreateEditEventScreen = () => {

    return <CreateEditEventProvider>
        <CreateEditEvent />
    </CreateEditEventProvider>
}

export default CreateEditEventScreen