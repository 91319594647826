import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Dialog, Slide } from '@material-ui/core'
import { Agora } from '../index'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ ref } { ...props } />)

const FullScreenStreamDialog = ({ open, dismiss }) => {

    useEffect(() => {
        const stream = Agora.getCurrentStream()
        stream.play('mainPlayer')

        return () => {
            if (stream)
                Agora.stopStreaming(stream)
        }
    }, [])

    return <>
        <Dialog fullScreen open={ open } onClose={dismiss} TransitionComponent={ Transition }>
            <FullscreenContainer id='mainPlayer'/>
        </Dialog>
    </>
}

export default FullScreenStreamDialog

FullScreenStreamDialog.propTypes = {
    open: PropTypes.bool,
    dismiss: PropTypes.func
}

const FullscreenContainer = styled.div`
  height: 100%;
  width: 100%;
`
