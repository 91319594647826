import { apiService, HttpError } from '../../shared'

const createRsvp = async (rsvpDetails) => {
    const { data, ok, status } = await apiService.post('/userEventStatuses', { ...rsvpDetails })

    verifyOk(ok, status)

    return data
}

const getEventRsvpData = async (eventId) => {
    return await apiService.get('/rsvp', { eventId: eventId })
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}

export {
    createRsvp,
    getEventRsvpData
}   