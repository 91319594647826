import React from 'react'
import AgoraDialogProvider from './AgoraDialogProvider'
import CreateStreamModal from './CreateStreamModal'
import PropTypes from 'prop-types'

const CreateLiveStreamDialog = (props) => {
    return <AgoraDialogProvider>
        <CreateStreamModal { ...props }/>
    </AgoraDialogProvider>
}

CreateLiveStreamDialog.propTypes = {
    props: PropTypes.object,
}

export default CreateLiveStreamDialog