import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Menu, MenuItem, Button, Typography, useMediaQuery } from '@material-ui/core'

const Dropdown = ({ collection, identifier, displayValue, selectionHandler, selectedValue }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const openDropdown = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = ev => {
        openDropdown(ev)
        selectionHandler(ev)
        handleClose()
    }

    return <div>
        <DropDownButton
            aria-label='custom-dropdown'
            aria-controls='dropdown-menu'
            aria-haspopup='true'
            onClick={ openDropdown }
            data-test-id='dropdown-button'
        >
            <Typography variant='inherit' noWrap>{ selectedValue[displayValue] }</Typography>
        </DropDownButton>
        <Menu
            id='dropdown-menu'
            anchorEl={ anchorEl }
            keepMounted
            open={ open }
            onClose={ handleClose }
            PaperProps={ {
                style: {
                    maxHeight: 36 * 4.5,
                    maxWidth: isMobile ? 'calc(100% - 32px)' : 380
                }
            } }
            data-test-id='dropdown-menu'
        >
            { collection.map(item => {
                const id = item[identifier]
                return <MenuItem
                    key={ id }
                    value={ id }
                    selected={ id === selectedValue[identifier] }
                    onClick={ handleClick }
                    data-identifier={ id }
                    data-test-id='menu-item'
                >
                    <Typography variant='inherit' noWrap>{ item[displayValue] }</Typography>
                </MenuItem>
            }) }
        </Menu>
    </div>
}

const DropDownButton = styled(Button)`
    font-size: 1rem;
    justify-content: start;
    text-transform: none;
    width: 100%;
`

Dropdown.propTypes = {
    collection: PropTypes.arrayOf(PropTypes.shape()),
    identifier: PropTypes.string,
    displayValue: PropTypes.string,
    selectionHandler: PropTypes.func,
    selectedValue: PropTypes.shape()
}

export default Dropdown
