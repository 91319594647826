import React, { useState, useEffect, useCallback } from 'react'
import propType from 'prop-types'
import { Typography, Box } from '@material-ui/core'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import ImageIcon from '@material-ui/icons/Image'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { verifyFile, createCropPreview } from '..'

const imageMaxSize = 20000000 // bytes -> 20mb
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg'
const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => { return item.trim() })
const placeholderImageLink = 'https://gallery.allcal.com/photos/live/placeholder_cover_image%402x.png'

const ImageUpload = ({ croppedImg, setCroppedImg, text, imageUrl }) => {
    const { t } = useTranslation('', i18n)
    const [imgSrc, setImgSrc] = useState(null)
    const [imgRef, setImgRef] = useState(null)
    const [crop, setCrop] = useState({ aspect: 2 })

    const [isImgPlaceholder, setIsImgPlaceholder] = useState(imageUrl === placeholderImageLink)
    const [userUploaded, setUserUploaded] = useState(false)
    const [editHover, setEditHover] = useState(false)

    const calendarPhotoAlreadyExists = !isImgPlaceholder && imageUrl
    const hasImage = imgSrc && !isImgPlaceholder

    useEffect(() => {
        calendarPhotoAlreadyExists && setImgSrc(imageUrl)
    }, [imageUrl, calendarPhotoAlreadyExists])

    const handleFileSelect = (event) => {
        const files = event.target.files
        if (files && files.length > 0) {
            const isVerified = verifyFile(files, imageMaxSize, acceptedFileTypesArray)
            if (isVerified) {
                const currentFile = files[0]
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener('load', () => {
                    setIsImgPlaceholder(false)
                    imageUrl && clearPhoto(false)
                    const myResult = myFileItemReader.result
                    setImgSrc(myResult)
                    setUserUploaded(true)
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            }
        }
    }

    const clearPhoto = () => {
        setCroppedImg(null)
        setCrop({ aspect: 2 })
        setImgRef(null)
        setUserUploaded(false)
        setImgSrc(null)
    }

    const onLoad = useCallback(img => {
        setImgRef(img)
        setCrop({ unit: '%', width: 100, height: 100, aspect: 2 })
        return false
    }, [])

    const onLoadEdit = useCallback(img => {
        setImgRef(img)
    }, [])

    const makeClientCrop = async crop => {
        if (imgRef && crop.width && crop.height) {
            const base64img = await createCropPreview(imgRef, crop)
            setCroppedImg(base64img)
        }
    }

    const handleMouseLeaveEnter = isHover => calendarPhotoAlreadyExists ? (() => setEditHover(isHover)) : undefined

    const ShowUploadIfCoverPhotoExists = () => <PhotoOptions>
        { userUploaded && <UploadBtnWrapper>
            <label htmlFor="upload-button" data-test-id="change-photo-label">{ t('calendar.imgUpload.changePhoto') }</label>
        </UploadBtnWrapper> }
    </PhotoOptions >

    return <>
        <ShowUploadIfCoverPhotoExists />
        <ImageUploadWrapper hasimage={ imgSrc ? 1 : 0 }>
            <Wrapper data-test-id="upload-image-wrapper">
                <label htmlFor={ croppedImg ? '' : 'upload-button' }
                    onMouseLeave={ handleMouseLeaveEnter(false) }
                    onMouseEnter={ handleMouseLeaveEnter(true) }
                >
                    {
                        hasImage ?
                            (imageUrl ? (
                                <ReactCropWithEditLayer>
                                    { editHover && !userUploaded && <EditLayer data-test-id="edit-layer-on-hover">
                                        <EditIcon data-test-id="edit-icon" fontSize='large' />
                                    </EditLayer> }
                                    <div data-test-id="image">
                                        <ReactCrop
                                            src={ imgSrc }
                                            onImageLoaded={ userUploaded ? onLoad : onLoadEdit }
                                            crop={ crop }
                                            disabled={ !userUploaded }
                                            onChange={ c => setCrop(c) }
                                            onComplete={ makeClientCrop }
                                        />
                                    </div>
                                </ReactCropWithEditLayer>
                            ) : (<ReactCrop
                                src={ imgSrc }
                                onImageLoaded={ onLoad }
                                crop={ crop }
                                onChange={ c => setCrop(c) }
                                onComplete={ makeClientCrop }
                            />)
                            ) : (
                                <PreSelect>
                                    <Icon />
                                    <Typography variant='h6' data-test-id="image-text">{ text }</Typography>
                                </PreSelect>
                            )
                    }
                </label>
                <UploadInput
                    type='file'
                    accept={ acceptedFileTypes }
                    id="upload-button"
                    onClick={ e => e.target.value = '' }
                    onChange={ e => handleFileSelect(e) }
                />
            </Wrapper>
        </ImageUploadWrapper >
    </>

}

const ReactCropWithEditLayer = styled.div`
    position: relative;
`

const EditLayer = styled.div`
    align-items: center;
    background-color: #000000;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    font-size: 40px;
    height: 100%;
    justify-content: center;
    opacity: 0.5;
    color: white;
    z-index:1;
    position: absolute;
    width: 100%;
`

const PhotoOptions = styled.div`
    text-align:end;
`

const UploadBtnWrapper = styled.span`
   label {
        color: ${ ({ theme }) => theme.palette.primary.main };
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        margin-bottom: 5px;
        margin-right: 20px;
    }
`

const ImageUploadWrapper = styled(Box)`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    text-align: center;
    border-radius: 4px;
    height :${({ hasimage }) => hasimage ? 'unset' : '320px' };
    label {
        width: 100%;
        height: 100%;
            &: hover {
                cursor: pointer;
                }
        align-self: center;
    }
`

const Icon = styled(ImageIcon)`
    width: 53px;
    height: 70px;
`

const Wrapper = styled(Box)`
    height:100%;
    width: 100%;
`

const PreSelect = styled(Box)`
    display:flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #9D9E9F;
`

const UploadInput = styled.input`
    display: none;
`

ImageUpload.propTypes = {
    croppedImg: propType.string,
    setCroppedImg: propType.func,
    text: propType.string,
    imageUrl: propType.string
}

export default ImageUpload