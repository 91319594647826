import React, { createContext, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import styled from 'styled-components'
import { useMediaQuery } from '@material-ui/core'

const AlertContext = createContext({})

const AlertProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [snackbar, setCurrentSnackbar] = useState({})

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const showSnack = useCallback ((content, type, duration) => {
        setCurrentSnackbar({
            content,
            duration: duration ? duration : 6000,
            type: type ? type : 'info'
        })

        setOpen(true)
    }, [])

    const closeSnack = () => {
        setOpen(false)
    }

    return <AlertContext.Provider value={{ showSnack }}>
        { children }

        <BottomAwareSnackbar open={open} mobile={isMobile ? 1 : 0} autoHideDuration={snackbar.duration} onClose={closeSnack}>
            <Alert onClose={closeSnack} severity={snackbar.type}>
                {snackbar.content}
            </Alert>
        </BottomAwareSnackbar>
    </AlertContext.Provider>
}

AlertProvider.propTypes = {
    children: PropTypes.object
}

const BottomAwareSnackbar = styled(Snackbar)`
  bottom: ${({ mobile }) => mobile ? '64px' : '8px'};
`

export const useAlerts = () => useContext(AlertContext)

export default AlertProvider