/* eslint-disable */
import React from 'react'

export const CircleIcon = ({
    width = 12,
    height = 12,
    filled
}) => <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 12 12'>
        {filled
            ? <circle cx='6' cy='6' r='5' fill='#0075b8' />
            : <circle cx='6' cy='6' r='5' stroke='#0075b8' strokeWidth='1' fillOpacity='0.0' />
        }
    </svg>
