import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Button, Container, Typography } from '@material-ui/core'
import { ArrowBack, KeyboardArrowRight } from '@material-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import { CalendarSchema, getCalendarPermissions, useCalendar } from '../'

const CalendarTopBar = () => {
    const { calendar } = useCalendar()
    const { t } = useTranslation('', i18n)
    const history = useHistory()

    const userCanEdit = () => {
        const permissions = getCalendarPermissions(calendar)

        return permissions.canEdit
    }

    return <CalendarTopBarWrapper data-test-id='calendar-top-bar'>
        <StyledLink
            to={ { pathname: '/calendars', state: { calendarId: calendar.calendarId } } }
            data-test-id='back-to-calendars-btn'>
            <StyledButton variant='outlined' color='primary' size='small' >
                <ArrowBack />
                { t('button.back') }
            </StyledButton>
        </StyledLink>
        <StyledBreadcrumbs
            separator={ <KeyboardArrowRight /> }
            aria-label='breadcrumb'
            data-test-id='calendar-breadcrumbs'>
            <Crumb data-test-id='crumb-calendars-root'>
                { t('screenTitle.calendars') }
            </Crumb>
            <CalendarName data-test-id='crumb-calendars-current'>
                { calendar.name }
            </CalendarName>
        </StyledBreadcrumbs>

        { userCanEdit() &&
            (<EditButton
                data-test-id='edit-btn'
                variant="outlined"
                color="primary"
                onClick={ () => history.push(`/calendar/edit/${ calendar.calendarId }`) }
            > { t('button.edit') }
            </EditButton>)
        }

    </CalendarTopBarWrapper>
}

const EditButton = styled(Button)`
    margin-left: auto;
    padding: 0 10px;
    width: 100px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
`

const CalendarTopBarWrapper = styled(Container)`
    align-items: center;
    background-color: white;
    display: flex;
    left: auto;
    position: sticky;
    padding-bottom: 20px;
    padding-top: 20px;
    right: 0;
    top: 0;
    z-index: 10;
`

const StyledButton = styled(Button)`
    font-size: 16px;
    text-transform: capitalize;

    svg {
        font-size: 1em;
    }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
    font-size: 16px;
    margin-left: 12px;

    svg {
        color: ${ ({ theme }) => theme.palette.primary.main };
    }

    li {
        display: grid;
        overflow: hidden;
        white-space: nowrap;
    }
`

const Crumb = styled(Typography)`
    color: ${ ({ theme }) => theme.palette.primary.main };
    font-size: 18px;
`

const CalendarName = styled(Crumb)`
    overflow: hidden;
    text-overflow: ellipsis;
`

CalendarTopBar.propTypes = {
    calendar: PropTypes.shape(CalendarSchema)
}

export default CalendarTopBar