import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const Header = ({ isSmallMobile, isLandscapeMobile, title }) => {

    return <HeaderTitle issmallmobile={ isSmallMobile ? 1 : 0 }
        islandscapemobile={ isLandscapeMobile ? 1 : 0 }
        data-test-id='auth-header-title'>
        { title }
    </HeaderTitle>
}

const HeaderTitle = styled(Typography)`
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -1px;
    text-align: center;
    margin: ${ ({ issmallmobile }) => issmallmobile ?
        '25px' : (({ islandscapemobile }) => islandscapemobile ? '25px' : '60px 0') };
`

Header.propTypes = {
    isSmallMobile: PropTypes.bool,
    isLandscapeMobile: PropTypes.bool,
    title: PropTypes.string
}

export default Header