import React from 'react'
import PropTypes from 'prop-types'

function ClockIcon(props) {
  return (
    <svg width={22} height={24} viewBox='0 0 22 24' {...props}>
      <path
        fill={props.fill ? props.fill : '#1B1F21'}
        fillRule='evenodd'
        d='M7.118 1.2c0-.663.579-1.2 1.294-1.2h5.176c.715 0 
        1.294.537 1.294 1.2 0 .663-.579 1.2-1.294 1.2h-.647v1.358c1.658.274 
        3.188.892 4.5 1.772l1.716-1.591a.683.683 0 01.915 0l.915.849a.57.57 
        0 010 .848l-1.64 1.52C21.001 8.943 22 11.264 22 13.8 22 19.433 17.075 
        24 11 24S0 19.433 0 13.8c0-2.537 1-4.858 2.653-6.643l-1.639-1.52a.57.57 0 
        010-.848l.915-.849a.683.683 0 01.915 0L4.56 5.53a11.408 11.408 0 014.5-1.772V2.4h-.647c-.715 
        0-1.294-.537-1.294-1.2zm2.976 21.32v-1.88c0-.464.406-.84.906-.84s.906.376.906.84v1.88c4.494-.396 8.07-3.713 
        8.498-7.88h-2.028c-.5 0-.905-.376-.905-.84 0-.464.405-.84.905-.84h2.028c-.427-4.167-4.004-7.484-8.498-7.88v1.88c0 
        .464-.406.84-.906.84s-.906-.376-.906-.84V5.08c-4.494.396-8.07 3.713-8.498 7.88h2.028c.5 0 
        .905.376.905.84 0 .464-.405.84-.905.84H1.596c.427 4.167 4.004 7.484 8.498 
        7.88zM6.16 11.586a.685.685 0 01-.096-1.014.82.82 0 011.094-.09l3.77 
        2.934 4.8-5.304a.82.82 0 011.094-.09c.329.256.372.71.096 1.015l-5.324 5.883-.003-.002-.475.525-4.956-3.857z'
      />
    </svg>
  )
}

ClockIcon.propTypes = {
  fill: PropTypes.string
}

export default ClockIcon