import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Button, Container, Typography, useMediaQuery } from '@material-ui/core'
import SearchFieldBar from './SearchFieldBar'

const TopBar = ({ name, createCallback, searchPlaceholder,
    searchCallback, editCallback, hideCreateButton, hideEditButton,
    hideSearchBar, searchFieldRef }) => {
    const { t } = useTranslation('', i18n)
    const sm = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return <TopBarWrapper nosearch={ hideSearchBar ? 1 : 0 } sm={ sm ? 1 : 0 } data-test-id='top-bar'>
        <LeftSide>
            <TopBarLabel variant='h5' data-test-id='top-bar-label'>{ name }</TopBarLabel>
            {
                !hideCreateButton && <CreateButton
                    color='primary'
                    onClick={ createCallback }
                    size='small'
                    variant='contained'
                    data-test-id='create-btn'
                >
                    { sm ? `+ ${ t('button.new') }` : `+ ${ t('button.create') }` }
                </CreateButton>
            }
            {
                !hideEditButton && <EditButton
                    color='primary'
                    onClick={ editCallback }
                    variant='outlined'
                    data-test-id='edit-btn'
                >{ t('button.edit') }</EditButton>
            }
        </LeftSide>
        { !hideSearchBar &&
            <SearchFieldBar
                size={ sm ? 'smDown' : 'mdUp' }
                searchFieldRef={ searchFieldRef }
                searchPlaceholder={ searchPlaceholder }
                searchCallback={ searchCallback }
            />
        }
    </TopBarWrapper>
}

const LeftSide = styled.div`
    display: flex;
    align-items: center;
`

const TopBarWrapper = styled(Container)`
    align-items: center;
    background-color: white;
    justify-content: space-between;
    left: auto;
    position: sticky;
    padding-bottom: 20px;
    padding-top: 20px;
    right: 0;
    top: 0;
    z-index: 10;
    display: ${ ({ sm, nosearch }) => (sm || nosearch) ? 'block' : 'flex' };

    ${ LeftSide } {
        ${ ({ sm, nosearch }) => (sm || nosearch) && 'justify-content: space-between' };
    }
`

const TopBarLabel = styled(Typography)`
    font-weight: 700;
    margin-right: 12px;
`
const CreateButton = styled(Button)`
    font-weight: 700;
`
const EditButton = styled(Button)`
    padding: 0 6px;
`

TopBar.propTypes = {
    name: PropTypes.string,
    createCallback: PropTypes.func,
    editCallback: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    searchCallback: PropTypes.func,
    hideCreateButton: PropTypes.bool,
    hideSearchBar: PropTypes.bool,
    hideEditButton: PropTypes.bool,
    searchFieldRef: PropTypes.object,
    children: PropTypes.object
}

export default TopBar