import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ListItem, ListItemAvatar, ListItemText, Grid, IconButton, Tooltip } from '@material-ui/core'
import { UserAvatar, UserSchema } from '..'
import ClearIcon from '@material-ui/icons/Clear'

const MiniUserCard = ({ user, clickHandler, clear }) => {

    return (
        <ItemContainer data-test-id='user-card-item' onClick={ clickHandler } >
            <AvatarWrapper>
                <UserAvatar user={ user } size='40' />
            </AvatarWrapper>
            <Grid container alignItems={ 'center' } justify={ 'space-between' } wrap={ 'nowrap' }>
                <UserName primary={ user.name } disableTypography data-test-id='user-card-name' />

                { clear && <Tooltip title={ 'Clear user' } arrow placement='top'>
                    <ClearButton aria-label="Clear user" data-test-id='clear-user-btn'
                        onClick={ clear }>
                        <ClearIcon />
                    </ClearButton>
                </Tooltip> }
            </Grid>
        </ItemContainer>
    )
}

const ClearButton = styled(IconButton)`
    color: #d94141;
`

const ItemContainer = styled(ListItem)`
    background-color: ${ ({ theme }) => theme.palette.background.default };
    border-radius: 4px;
    height: 60px;
    margin-bottom: 10px;
    ${({ onClick }) => onClick && 'cursor: pointer' };
`

const AvatarWrapper = styled(ListItemAvatar)`
    margin-right: 10px;
`

const UserName = styled(ListItemText)`
    display: -webkit-box;
    font-size: 18px;
    flex: unset;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

MiniUserCard.propTypes = {
    user: PropTypes.shape(UserSchema),
    clickHandler: PropTypes.func,
    clear: PropTypes.func
}


export default MiniUserCard