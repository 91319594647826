import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Card, CardContent, Grid } from '@material-ui/core'

import UserAvatar from '../../shared/components/UserAvatar'
import { NotificationSchema } from '../schemas/notification.schema'
import { notificationMoment } from '../utils/notification.utils'
import ReturnDownForwardIcon from '../../shared/assets/ReturnDownForwardIcon'
import { CircleIcon } from '../../shared/assets/CircleIcon'
import NotificationActions from './NotificationActions'
import { canShowViewAction, getBodyContent, getTitleContinuation } from './contents'

import constants from '../utils/notification.constants'
import { isEmpty } from '../../shared'

const NotificationMobile = ({ data, onPressView, onPositiveAction, onNegativeAction, onTouchAction }) => {
  const { sender, initiator, notificationType, status, payload, ...notification } = data
  const chosen = status !== constants.status.pending

  const hasViewAction = canShowViewAction(data) // TODO fix for private calendars, should be not view-able till accepted, use with calendar links, we should do this when wer do the delta updates task.
  const hasEventInfo = !isEmpty(notification.event)
  return <CardWrapper elevation={ 0 }>
    <Content onClick={ onTouchAction }>
      <Grid container direction='row' wrap='nowrap'>
        <AvatarDateContainer item>
          <UserAvatar user={ { ...sender, userId: initiator } } size='40' />
        </AvatarDateContainer>

        <Grid item xs={ 12 }>
          <span>
            <SenderName>{ sender.name } </SenderName>
            <span data-test-id='notification-title'>{ getTitleContinuation(data) }</span>
          </span>

          <div data-test-id='notification-details'>
            { getBodyContent(data, onPressView) }
          </div>

          { !chosen
            && <NotificationActions notificationType={ notificationType }
              status={ status }
              onPositiveAction={ onPositiveAction }
              onNegativeAction={ onNegativeAction } /> }
        </Grid>

        <Grid item>
          <NotificationDot data-test-id='notification-icon'>
            { !notification.hasBeenRead ? <CircleIcon filled /> : null }
          </NotificationDot>
        </Grid>
      </Grid>
    </Content>

    <ActionBox boxShadow={ 3 }>
      <span data-test-id='notification-time'>{ notificationMoment(notification.actionDate) }</span>

      { hasViewAction && <View data-test-id='notification-mobile-view'
        onClick={ () => onPressView(hasEventInfo ? 'event' : 'calendar', notification) }>
        View
                <ReturnDownForwardIcon size={ 18 } />
      </View> }
    </ActionBox>
  </CardWrapper>
}

NotificationMobile.propTypes = {
  data: PropTypes.shape(NotificationSchema),
  onPressView: PropTypes.func,
  onPositiveAction: PropTypes.func,
  onNegativeAction: PropTypes.func,
  onTouchAction: PropTypes.func
}

export default NotificationMobile

const CardWrapper = styled(Card)`
  margin: 12px 6px 12px 6px;
  background: ${ ({ theme }) => theme.palette.background.default };
`

const AvatarDateContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-right: 6px;
`

const Content = styled(CardContent)`
  display: flex;
`

const ActionBox = styled(Box)`
  display: flex;
  padding: 6px; 
  justify-content: space-between;
`

const View = styled.div`
  display: flex;
  font-weight: 700;
  align-items: center;
  min-width: 52px;
  cursor: pointer;
  justify-content: space-between;
`
const SenderName = styled.span`
  font-weight: 700;
`

const NotificationDot = styled.div`
  height: 12px;
`
