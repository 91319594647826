// This file could use some serious refactoring, it is very hard to follow :(
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import CameraIcon from './../../shared/assets/CameraIcon'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from '@material-ui/styles'
import PropTypes from 'prop-types'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import { Box, Button, Container, Grid, Hidden, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core'
import {
  addLiveStream,
  EventAdvancedDesc,
  EventAttendees,
  EventCalendar,
  EventDate,
  EventLocation,
  EventMyRsvp,
  EventReminder,
  EventRsvp,
  EventTags,
  EventTime,
  getEventDetails,
  refreshEventDetails,
  removeLiveStream,
  fetchLiveStreams
} from '..'
import { getUserStatusForEvent } from '../../rsvps'
import LabelTypeButton from '../../shared/components/LabelTypeButton'
import FullScreenDialog from '../../shared/components/fullScreenDialog'
import GoogleMaps from '../../shared/components/Map'
import { ArrowBack } from '@material-ui/icons'
import { canEditEvent, getCalendarIdFromEvent, getRemovalAction } from '../../events/utils/event.utils'
import { useAccount } from '../../account'
import { fetchCalendar } from '../../calendars/service/calendars.datasource'
import { isEmpty, Loader } from '../../shared/'
import { deleteEvent } from '../../events'
import DeleteEventBtn from '../components/DeleteEventBtn'
import { Agora, CreateLiveStreamDialog } from '../../livestream'
import EventStreams from '../components/EventStreams'
import EventActiveStreams from '../components/EventActiveStreams'
import { useAlerts } from '../../alerts'
import ShareEventBtn from '../components/ShareEventBtn'
import { Pusher } from '../../sockets'

const EventDetailsScreen = ({ match }) => {
  const { t } = useTranslation('', i18n)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const history = useHistory()
  const dispatch = useDispatch()
  const { account } = useAccount()
  const { showSnack } = useAlerts()

  const eventId = match.params.eventId.split('-')[1]

  const eventDetail = useSelector(state => state.eventDetails.data)
  const streams = useSelector(state => state.eventDetails.streams)
  const showedAgora = useRef(false)

  const isError = useSelector(state => state.eventDetails.error)

  const attendeeCount = eventDetail && eventDetail.rsvpsStatistics &&
    eventDetail.rsvpsStatistics['pending'] +
    eventDetail.rsvpsStatistics.attending +
    eventDetail.rsvpsStatistics['not attending'] +
    eventDetail.rsvpsStatistics.none

  const [rightPaneDisplay, setRightPaneDisplay] = useState(true)
  const [modalDisplay, setModalDisplay] = useState(false)
  const [rightPaneItem, setRightPaneItem] = useState('event.eventAttendees')
  const [originatingCalendar, setOriginatingCalendar] = useState({})
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isStreamDialogOpen, setStreamDialogOpen] = useState(false)

  useEffect(() => {
    dispatch(getEventDetails(eventId))
    dispatch(getUserStatusForEvent(eventId))
    return () => {
      dispatch(refreshEventDetails(eventId))
    }
  }, [dispatch, eventId, account])


  const inititateAndFetchLiveStreams = (livestreams) => {

    Agora.join(eventId, account.userId).then(r => {
      console.debug('Joined from event!', r)
    }).finally(() => {
      dispatch(fetchLiveStreams(livestreams))
    });
  }


  useEffect(() => {
    if (Object.keys(eventDetail).length > 0) {
      if (eventDetail.livestreams && eventDetail.livestreams.streams.length > 0) {
        inititateAndFetchLiveStreams(eventDetail.livestreams.streams)
      }
    }
  }, [eventDetail]);

  useEffect(() => {
    Pusher.bind('event', (eventData) => {
      if (eventData.payload.eventId === eventId) {
        if (eventData && eventData.payload.livestreams && (eventData.payload.livestreams.streams.length > 0)) {
          inititateAndFetchLiveStreams(eventData.payload.livestreams.streams)
        }
      }

    })
  }, [dispatch])

  /* The page should start with event attendees info on the right pane
  useEffect(() => {
    if (streams.length > 0 && !showedAgora.current) {
      setRightPaneItem('event.eventStreams')
      setRightPaneDisplay(true)
      showedAgora.current = true
    }

  }, [isMobile])  */

  useEffect(() => {

    if (streams.length > 0) {
      setRightPaneItem('event.eventStreams')
      setRightPaneDisplay(true)
    }

    if (streams.length === 0) {
      setRightPaneItem('event.eventAttendees')
    }
  }, [streams])

  useEffect(() => {
    const unlisten = Agora.listen(evt => {
      switch (evt.type) {
        case 'stream-subscribed': {
          console.log("Stream subscribed");

          dispatch(addLiveStream(eventId))

          break
        }
        case 'peer-leave': {
          dispatch(removeLiveStream(evt.uid))
          break
        }
        default:
          break
      }
    })

    return () => {
      unlisten()
    }
  }, [eventId, dispatch, eventDetail])

  useAsyncEffect(function* () {
    if (!eventDetail) {
      return
    }

    const calendarId = getCalendarIdFromEvent(eventDetail)
    if (calendarId) {
      const response = yield fetchCalendar(calendarId)
      setOriginatingCalendar(response)
    }
  }, [eventDetail])

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const evtDetItemClick = (type) => {
    setRightPaneItem(type)
    setRightPaneDisplay(true)
    setModalDisplay(true)
  }

  const AdvancedDescSwitch = () => {
    switch (rightPaneItem) {
      case 'event.eventLocation': {
        return <EventLocationRightPane location={eventDetail.location} />
      }
      case 'event.eventAttendees': {
        return <EventAttendeesRightPane />
      }
      case 'event.eventStreams': {
        return <EventStreams streams={streams} />
      }
      default:
        return <></>
    }
  }

  const LiveStreamPreviews = () => {
    return streams.length > 0 && <Grid item xs={12} md={6} lg={5}>
      <EventStreams streams={streams} />
    </Grid>
  }

  const EventAttendeesRightPane = () => <Fragment>
    <EventMyRsvp userEventStatuses={eventDetail.userEventStatuses} />

    <EventRsvpHeader container justify='space-between' alignItems='center'>
      <Grid item><HeaderDivLight>{`${t('event.others')} ( ${attendeeCount && attendeeCount - 1} )`}</HeaderDivLight></Grid>
      <Grid item>
        <ShareEventBtn eventDetails={eventDetail} originatingCalendar={originatingCalendar} />
      </Grid>

    </EventRsvpHeader>

    <EventAttendees eventId={eventId} />
  </Fragment>

  const EventLocationRightPane = ({ location }) =>
    <EventLocationDiv><GoogleMaps location={location}></GoogleMaps></EventLocationDiv>

  EventLocationRightPane.propTypes = {
    location: PropTypes.object
  }

  EventDetailsScreen.propTypes = {
    match: PropTypes.object
  }

  const handleClose = () => {
    setModalDisplay(false)
    setRightPaneDisplay(false)
  }

  const backHandler = () => {
    const from = history.location.state?.from
    return from ? history.push(from) : history.goBack()
  }

  const isEventFromEventsScreen = () => !history.location.state?.calendarId

  const goToEditEvent = async event => {
    history.push(`/event/edit/${event.eventId}`,
      { event: event, calendarId: originatingCalendar.calendarId, isEventFromEventsScreen: isEventFromEventsScreen() })
  }

  const hasEditPermission = () => {
    return originatingCalendar && !isEmpty(eventDetail) && canEditEvent(account, eventDetail, originatingCalendar)
  }

  const showDeleteButton = () => {
    return !isEmpty(eventDetail) && getRemovalAction(account, eventDetail, originatingCalendar) === 'delete'
  }

  const proceedEventRemoval = () => {
    closeDeleteDialog()
    deleteEvent(eventDetail.eventId, originatingCalendar.calendarId)
    backHandler()
  }

  const openCreateStreamDialog = () => {
    const maxStreams = eventDetail.livestreams?.max ?? 4
    if (streams.length >= maxStreams) {
      return showSnack('Maximum numbers of streams reached', 'error')
    }

    setStreamDialogOpen(true)
  }

  if (isError) {
    return <Redirect to='/404' />
  }

  return !isEmpty(eventDetail) ? <EventInfoContent>
    <Hidden mdUp>
      <FullScreenDialog open={modalDisplay} close={handleClose} title={t(rightPaneItem)}>
        {modalDisplay && <AdvancedDescSwitch />}
      </FullScreenDialog>

    </Hidden>
    <TopBarWrapper>
      <Content>
        <BackBtn onClick={backHandler} data-test-id='back-arrow'>
          <ArrowBack />
        </BackBtn>

        <EventDetailsName variant='h5' data-test-id='event-details-name'>{eventDetail.name}</EventDetailsName>
      </Content>
      {hasEditPermission() &&
        <EditButton
          data-test-id='edit-btn'
          variant='outlined'
          color='primary'
          onClick={() => goToEditEvent(eventDetail)}
        > {t('button.edit')}
        </EditButton>
      }
      {showDeleteButton() && !isMobile && <DeleteEventBtn
        isDialogOpen={isDeleteDialogOpen}
        isMobile={isMobile}
        openDialog={openDeleteDialog}
        closeDialog={closeDeleteDialog}
        deleteEvent={proceedEventRemoval}
      />}
    </TopBarWrapper>


    <CustomContainer container justify='center'>
      {eventDetail && Object.keys(eventDetail).length > 0 ? <LeftPane item xs={11} md={5} lg={4}>

        {/* for FPR { isMobile && <LiveStreamPreviews /> }*/}

        {hasEditPermission() && <LiveStreamButtonContainer item>
          <Button data-test-id='live-stream-open-create' variant='contained'
            color='primary' startIcon={<CameraIcon wpadding={16} />}
            fullWidth onClick={() => openCreateStreamDialog()}>{t('stream.start')}</Button>
        </LiveStreamButtonContainer>

        }


        <EventTime
          startDate={eventDetail.startDate}
          startTimezone={eventDetail.startTimezone}
          endDate={eventDetail.endDate}
          endTimezone={eventDetail.endTimezone}
        />

        <EventDate
          startDate={eventDetail.startDate}
          startTimezone={eventDetail.startTimezone}
          endDate={eventDetail.endDate}
          endTimezone={eventDetail.endTimezone}
          isMultiDay={eventDetail.isMultiDay} />

        {isMobile && streams.length > 0 && <EventActiveStreams
          onClick={() => evtDetItemClick('event.eventStreams')} streamsSize={streams.length}
          isActive={(rightPaneItem === 'event.eventStreams') && rightPaneDisplay} />}

        <EventLocation onClick={() => { evtDetItemClick('event.eventLocation') }}
          isActive={(rightPaneItem === 'event.eventLocation') && rightPaneDisplay}
          location={eventDetail.location}>
        </EventLocation>

        <EventRsvp onClick={() => { evtDetItemClick('event.eventAttendees') }}
          isActive={(rightPaneItem === 'event.eventAttendees') && rightPaneDisplay}
          eventId={eventDetail.eventId}
          attendeeCount={attendeeCount - 1} >
        </EventRsvp>

        <EventReminder reminder={eventDetail.reminders ? eventDetail.reminders[0] : null} />
        <EventCalendar calendar={eventDetail.originatingCalendar} />
        <EventTags tags={eventDetail.tags} />
        <EventAdvancedDesc attachments={eventDetail.attachments} />

        {showDeleteButton() && isMobile && <DeleteEventBtn
          isDialogOpen={isDeleteDialogOpen}
          isMobile={isMobile}
          openDialog={openDeleteDialog}
          closeDialog={closeDeleteDialog}
          deleteEvent={proceedEventRemoval}
        />}

      </LeftPane> : <LeftPane item xs={11} md={5} lg={4}>
          {[...Array(7).keys()].map((key, index) => <IconTextComp key={index} elevation={0}>
            <Grid container spacing={1}  >
              <Grid item xs={1}> <Skeleton></Skeleton></Grid>
              <Grid item xs={5}>
                <Typography noWrap={true} color='textPrimary' gutterBottom={true}>
                  <Skeleton></Skeleton>
                </Typography>
              </Grid>
            </Grid>
          </IconTextComp>)}
        </LeftPane>}

      {rightPaneDisplay && <Hidden smDown>
        <RightPane item xs={11} md={6} lg={5}>
          <RightPaneHeader container justify='space-between' alignItems='center'>
            <Grid item> <HeaderDiv>{t(rightPaneItem)}</HeaderDiv></Grid>
            <Grid item>
              <LabelTypeButton
                variant='outlined'
                onClick={() => { setRightPaneDisplay(false) }}
                textColor={theme.palette.primary.main}>
                {t('button.close')}
              </LabelTypeButton>
            </Grid>
          </RightPaneHeader>

          <AdvancedDescSwitch />
        </RightPane>

      </Hidden>
      }



    </CustomContainer>

    {isStreamDialogOpen &&
      <CreateLiveStreamDialog open eventName={eventDetail.name} eventId={eventId} dismiss={() => setStreamDialogOpen(false)} />}
  </EventInfoContent> : <Loader />
}

const EditButton = styled(Button)`
  margin-left: auto;
  padding: 0 10px;
  width: 100px;
`

const EventInfoContent = styled.div`
  ${ ({ theme }) => `
    background: ${ theme.palette.background.paper};      
  ` };
`

const IconTextComp = styled(Paper)`
${ ({ theme }) => `
    font-weight : 500;
    padding-bottom: ${theme.spacing(3)}px;  
  ` };
`
const EventRsvpHeader = styled(Grid)`
${ ({ theme }) => `
      padding: ${theme.spacing(2)}px ${theme.spacing(2)}px;
  ` };
`
const RightPaneHeader = styled(Grid)`
${ ({ theme }) => `
      padding: ${theme.spacing(2)}px ${theme.spacing(2)}px;
  ` };
`
const CustomContainer = styled(Grid)`
  width: 100%;
  margin: auto;
`

const LeftPane = styled(Grid)`
`

const RightPane = styled(Grid)`
${ ({ theme }) => `
  background : ${ theme.palette.background.default};
  padding : ${theme.spacing(1)}px;
  ` };
`

const EventDetailsName = styled(Typography)`
  font-size : 2em;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  align-self: start;
`

const TopBarWrapper = styled(Container)`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  left: auto;
  position: sticky;
  z-index: 10;
  padding-bottom: 20px;
  padding-top: 20px;
  right: 0;
  top: 0;
`

const BackBtn = styled(IconButton)`
  color: ${ ({ theme }) => theme.palette.text.primary};
  margin-right: 22px;
  padding: 0;
`

const Content = styled(Box)`
  align-items: center;
  display: flex;
`

const HeaderDiv = styled.div`
  font-weight : 600;
  ${ ({ theme }) => `
    font-size : ${ theme.spacing(2)}px;
  ` };
`
const HeaderDivLight = styled.div`
  font-weight : 500;
  ${ ({ theme }) => `
    font-size : ${ theme.spacing(2)}px;
  ` };
`

const EventLocationDiv = styled.div`
  ${ ({ theme }) => `
    padding : ${ theme.spacing(2)}px;
  ` };
`

const LiveStreamButtonContainer = styled(Grid)`
  ${ ({ theme }) => `
    padding-bottom : ${ theme.spacing(3)}px;
  ` };
`



export default EventDetailsScreen
