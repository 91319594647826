import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

import { Grid, ListItem, ListItemText, Switch, useMediaQuery, Button } from '@material-ui/core'

const SettingCard = ({ title, description, name, checked, onChange, isDeleteCard, handleDelete }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const { t } = useTranslation('', i18n)

    return (
        <Setting data-test-id={ `setting-card-${ name }` } ismobile={ isMobile ? 1 : 0 } isdeletecard={ isDeleteCard ? 1 : 0 }>

            <TextGrid container direction={ 'column' } >
                <SettingTitle
                    primary={ title }
                    data-test-id='setting-card-title' />

                <SettingDescription
                    primary={ description }
                    data-test-id='setting-card-description'
                />
            </TextGrid >

            { isDeleteCard ?
                <DeleteButton
                    data-test-id='delete-calendar-btn'
                    variant="outlined"
                    onClick={ handleDelete }
                > { t('button.deleteCalendar') }
                </DeleteButton> : <Switch
                    edge='end'
                    color='primary'
                    onChange={ onChange }
                    checked={ checked }
                    inputProps={ { 'aria-labelledby': `switch-to-${ title }` } }
                    data-test-id='setting-card-switch'
                />
            }


        </Setting >
    )
}

const Setting = styled(ListItem)`
    background-color: ${ ({ theme, isdeletecard }) => isdeletecard ? '#fcf9f9' : theme.palette.background.default };
    border-radius: 4px;
    margin-bottom: 20px;
    ${({ ismobile }) => {
        return ismobile ?
            { 'height': 'unset' }
            : { 'height': '100px', 'padding-right': '40px', 'padding-left': '40px' }
    } }
`

const DeleteButton = styled(Button)`
    width: 162px;
    color: #d94141;
    border-color: #d94141;
`

const SettingTitle = styled(ListItemText)`
    font-size: 22px;
    flex: unset;
    margin-right: 16px;
`

const SettingDescription = styled(ListItemText)`
    font-size: 18px;
    flex: unset;
    opacity: 0.4;
`

const TextGrid = styled(Grid)`
    margin-right: 16px;
    width: -webkit-fill-available;
`

SettingCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    isDeleteCard: PropTypes.bool,
    handleDelete: PropTypes.func
}


export default SettingCard