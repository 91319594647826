import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import moment from 'moment'
import useDates from './datesHook'
import { useResolver } from '../../account/'
import { isEmpty } from '../../shared/'

const useEvent = (defaultValues, userToMeet, openedCalendarId) => {
    const { t } = useTranslation('', i18n)

    const { startDate, endDate } = useDates()
    const { personalCalendar } = useResolver()
    const calendars = useSelector(state => state.calendars.items)

    const localTimezone = moment.tz.guess()
    const eventInit = {
        occurrenceDate: moment().toISOString(),
        occurrenceId: `undefined-${ moment().format('YYYY-MM-DD') }`,
        name: '',
        isAllDay: false,
        isMultiDay: false,
        startDate: moment(startDate),
        endDate: moment(endDate),
        hexColor: '4256c1',
        startTimezone: localTimezone,
        endTimezone: localTimezone,
        originatingCalendar: {},
        attachments: [{
            type: 'details',
            data: {}
        }],
        location: { radius: 50, autoAttendance: false },
        calendarId: '',
        kind: 'classic',
        rsvpType: 1,
        tags: [],
        colorSettings: {
            type: 'dynamic'
        },
        extendedProperties: {
            shouldHideDates: false,
            shouldHideLocation: false
        }
    }

    const fillInEventValues = (eventValues, userToMeet) => {
        let populatedEvent = {}
        if (!isEmpty(eventValues)) {
            populatedEvent = {
                ...eventValues,
                startDate: eventValues.isAllDay ?
                    moment(eventValues.startDate).utc() : moment(eventValues.startDate).tz(eventValues.startTimezone),
                endDate: eventValues.isAllDay ?
                    moment(eventValues.endDate).utc() : moment(eventValues.endDate).tz(eventValues.endTimezone),
                originatingCalendar: eventValues.originatingCalendar,
                originatingCalendarId: eventValues.originatingCalendarId
            }
        }

        if (!isEmpty(userToMeet)) {
            let account = JSON.parse(localStorage.getItem('account'))
            populatedEvent.name = t('event.meet.name', { sender: account.name, receiver: userToMeet.name })
        }

        return populatedEvent
    }

    const [event, setEvent] = useState({
        ...eventInit,
        ...fillInEventValues(defaultValues, userToMeet)
    })

    useEffect(() => {
        if (!isEmpty(event.originatingCalendar)) {
            return
        }

        if (openedCalendarId) {
            const calendar = calendars.find(cal => cal.calendarId === openedCalendarId)
            if (calendar) {
                setEvent({ ...event, originatingCalendar: calendar, originatingCalendarId: calendar.calendarId })
                return
            }
        }

        if (!isEmpty(personalCalendar)) {
            setEvent({
                ...event,
                originatingCalendarId: personalCalendar.calendarId,
                originatingCalendar: personalCalendar
            })
        }
    }, [openedCalendarId, calendars, event, personalCalendar])

    return {
        event: {
            ...eventInit,
            ...event
        },
        setEvent
    }
}

export default useEvent