import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import ReCAPTCHA from 'react-google-recaptcha'
import { useAlerts } from '../../alerts'
import { shareEvent } from '../../events/utils/event.utils'
import { isEmpty, getInvisibleRecaptchaSiteKey, EMAIL_REGEX } from '../../shared/'
import { getCalendarForShareRights } from '../../events'
import InviteModal from '../../shared/components/InviteModal'
import LabelTypeButton from '../../shared/components/LabelTypeButton'
import { getRSVPForEvent } from '../../rsvps'

const ShareEventBtn = ({ eventDetails, originatingCalendar }) => {
    const { t } = useTranslation('', i18n)
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const recaptchaRef = useRef()
    const { showSnack } = useAlerts()
    const calendars = useSelector(state => state.calendars.items)
    const linkCalendarIds = eventDetails.usersWithPermissions ?
        eventDetails.usersWithPermissions.map(user => user.linkCalendarId) : []
    const openedCalendar = calendars.find(cal => cal.calendarId === history.location.state?.calendarId)
    const eventCalendarLinks = calendars.filter(cal => linkCalendarIds.includes(cal.calendarId))
    const calendarForShareRights = getCalendarForShareRights(openedCalendar, originatingCalendar, eventCalendarLinks)
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
    const [isUserValidated, setIsUserValidated] = useState(false)

    const showShareButton = () => {
        if (eventDetails.kind === 'info') {
            return false
        }

        if (!isEmpty(calendarForShareRights)) {
            return calendarForShareRights.currentUser ?
                calendarForShareRights.currentUser.permissions.canShare : calendarForShareRights.permissions.canShare
        }

        return false
    }

    const shareEventToUser = async (values, list) => {
        const { name } = values
        const isNewUser = list.noResults && EMAIL_REGEX.test(name)
        const user = isNewUser ? { name } : list.selectedUser

        const shareResponse = await shareEvent(user,
            eventDetails.eventId, calendarForShareRights.calendarId)
        if (shareResponse === 429) {
            showSnack(t('event.errors.shareLimitReached'), 'error')
        } else {
            showSnack(t('event.share.success'), 'success')
            dispatch(getRSVPForEvent(eventDetails.eventId, 0))
        }
    }

    const openShareModal = e => {

        // some recaptcha random reload triggers the onChange handler
        if (e === null) {
            return
        }

        setIsInviteModalOpen(true)
    }

    const validate = () => {
        if (isUserValidated) {
            openShareModal()
            return
        }

        recaptchaRef.current.execute()
        setIsUserValidated(true)
    }

    return showShareButton() ? <>
        <LabelTypeButton
            variant='contained'
            bgColor={theme.palette.primary.main}
            textColor={theme.palette.primary.contrastText}
            onClick={validate}
            data-test-id='share-event-btn'
        >{t('event.invite')}
        </LabelTypeButton>
        <ReCAPTCHA
            ref={recaptchaRef}
            size='invisible'
            onChange={openShareModal}
            sitekey={getInvisibleRecaptchaSiteKey()}
        />

        <InviteModal
            isInviteModalOpen={isInviteModalOpen}
            setIsInviteModalOpen={setIsInviteModalOpen}
            share={shareEventToUser} />
    </> : null
}

ShareEventBtn.propTypes = {
    eventDetails: PropTypes.object,
    originatingCalendar: PropTypes.object,
}

export default ShareEventBtn