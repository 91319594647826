import React, { createContext, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import API, { allCalAPI } from '../server'
import pusherSetup, { subscribeToUserChannel } from '../utils/pusherSetup'
import useIntegrationCompat from '../hooks/integrationCompatHook'

const AppManager = ({ children }) => {
    const [isSideBarOpened, setIsSideBarOpened] = useState(true)
    const [isMobileDevice, setIsMobileDevice] = useState(false)
    const [loggedUser, setLoggedUser] = useState({})
    const [areHeadersSet, setAreHeadersSet] = useState(false)
    const [userChannel, setUserChannel] = useState({})
    const { isIntegrated } = useIntegrationCompat()

    const getAllcalUser = async (userId) => {
        const allcalUser = await allCalAPI.get('/accounts/' + userId + '/profile')
        return allcalUser.data
    }

    const processUserData = async (urlUserData) => {
        allCalAPI.setHeader('login-token', urlUserData.loginToken)
        API.setHeader('login-token', urlUserData.loginToken)
        setAreHeadersSet(true)

        const allcalUserData = await getAllcalUser(urlUserData.userId)

        return {
            ...allcalUserData,
            ...urlUserData
        }
    }

    const getDataFromStore = async () => {
        let account = JSON.parse(localStorage.getItem('account'))
        return processUserData(account)
    }

    const getUserDataFromUrl = async () => {
        let url = window.location.href
        url = new URL(url)

        const urlUserData = {
            loginToken: url.searchParams.get('loginToken'),
            userId: url.searchParams.get('userId')
        }

        return await processUserData(urlUserData)
    }

    const setChatUserFromStore = async () => {
        const userData = await getDataFromStore()
        setLoggedUser({ ...userData })
    }

    const setAllcalUser = async () => {
        if (isIntegrated) {
            return
        }

        const userData = await getUserDataFromUrl()
        setLoggedUser({ ...userData })
    }

    const setupChatUpdatesChannel = async () => {
        pusherSetup(loggedUser.loginToken)
        setUserChannel(subscribeToUserChannel(loggedUser.userId))
    }

    useEffect(() => {

        setAllcalUser()

        setIsMobileDevice(window.orientation !== undefined)
        document.addEventListener('touchstart', {})
        window.addEventListener('touchstart', {})
    }, [])

    useEffect(() => {
        !_.isEmpty(loggedUser) && setupChatUpdatesChannel()
    }, [loggedUser])

    return <AppGlobalContext.Provider value={ {
        isMobileDevice,
        loggedUser,
        isSideBarOpened,
        setIsSideBarOpened,
        areHeadersSet,
        setChatUserFromStore,
        userChannel
    } }
    >
        { children }
    </AppGlobalContext.Provider>
}

AppManager.propTypes = {
    children: PropTypes.object
}

// TODO: init context structure is optional, unless initial values are used
export const AppGlobalContext = createContext({
    isMobileDevice: false,
    loggedUser: {},
    isSideBarOpened: true,
    setIsSideBarOpened: () => {
    },
    areHeadersSet: false,
    userChannel: {}
})

export const useChatAppContext = () => useContext(AppGlobalContext)

export default AppManager
