/* eslint-disable react/prop-types */
import React from 'react'

const ReturnDownForwardIcon = ({
                                   size = 512
                               }) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={ size } height={ size }
       viewBox='0 0 512 512'><title>ionicons-v5-c</title>
      <polyline points='400 352 464 288 400 224'
                style={ {
                    fill: 'none',
                    stroke: '#000',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: '32px'
                } }/>
      <path d='M448,288H154C95.24,288,48,238.67,48,180V160'
            style={ {
                fill: 'none',
                stroke: '#000',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '32px'
            } }/>
  </svg>

export default ReturnDownForwardIcon