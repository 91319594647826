import { apiService, chatApiService, HttpError, uploadProfilePicture } from '../../shared'

export const fetchAccount = async (userId) => {
    const { data, ok, status } = await apiService.get(`accounts/${ userId }`)

    verifyOk(ok, status)

    return { contact: {}, ...data }
}

export const getProfileNotes = async (userId) => {
    const { data, ok, status } = await apiService.get(`/users/${ userId }/notes`)

    verifyOk(ok, status)

    return data
}

export const saveProfileNotes = async (userId, notes) => {
    const { data, ok, status } = await apiService.put(`/users/${ userId }/notes`, { note: notes })

    verifyOk(ok, status)

    return data
}

export const fetchProfile = async (userId) => {
    const { data, ok, status } = await apiService.get(`accounts/${ userId }/profile`)
    verifyOk(ok, status)

    const notes = await getProfileNotes(userId)

    return { ...data, notes: notes.note }
}

export const changePassword = async (oldPassword, newPassword) => {
    const { data, status } = await apiService.post('account/changepassword', { oldPassword, newPassword })

    return { data, status }
}

export const resendConfirmationEmail = async (email) => {
    const { ok, status } = await apiService.get('account/resendEmailConfirmation',
        { email: encodeURIComponent(email) })

    verifyOk(ok, status)

    return ok
}

export const updateProfile = async (userId, updates, croppedImg) => {
    let imageLink = updates.imageLink

    if (croppedImg) {
        imageLink = await uploadProfilePicture(croppedImg)
    }

    const { data, ok, status } = await apiService.put(`accounts/${ userId }`, { ...updates, imageLink })

    verifyOk(ok, status)

    return data
}

export const createConversation = async (conversation) => {
    const { data, ok, status } = await chatApiService.post('/conversations', conversation)

    verifyOk(ok, status)

    return data
}

export const reportProfile = async (userdId, reason) => {
    const { status } = await apiService.post(`/users/${ userdId }/reports`, reason)

    return status
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}