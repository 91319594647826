import React from 'react'
import UserIcon from '../../shared/assets/UsersIcon'
import styled from 'styled-components'
import { useTheme } from '@material-ui/styles'
import PropTypes from 'prop-types'

function Attendance({ rsvpsStatistics }) {
    const theme = useTheme()
    return (
        rsvpsStatistics ? <AttendanceContainer>
            <UserIcon fill={theme.palette.text.disabled}></UserIcon>
            <AttendanceText>{rsvpsStatistics && rsvpsStatistics.attending}</AttendanceText>
        </AttendanceContainer> : ''
    )
}

const AttendanceContainer = styled.div`
        ${ ({ theme }) => `
        display : flex;
        align-items : center;
        font-size : ${theme.spacing(2)}px;
       
        ` };
`
const AttendanceText = styled.div`
        ${ ({ theme }) => `
            padding-left : ${theme.spacing(1)}px;
            color : ${theme.palette.secondary.main}
        ` };
`

Attendance.propTypes = {
    rsvpsStatistics: PropTypes.object
}

export default Attendance
