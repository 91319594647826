/* eslint-disable */
import React from 'react'

export const EventsIcon = ({
    width = 20,
    height = 20,
    filled,
    fill
}) => <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height}
    viewBox='0 0 20 20'>
        {filled
            ? <path fill={fill ? fill : '#127AB3'} fillRule='evenodd'
                d='M6.354 0c-.52 0-.937.457-.937 1.028v2.286c0 .571.416 1.028.937 1.028.521 0 .938-.457.938-1.028V1.028C7.292.457 6.875 0 6.354 0zm7.292 0c-.52 0-.938.457-.938 1.028v2.286c0 .571.417 1.028.938 1.028.52 0 .937-.457.937-1.028V1.028c0-.571-.416-1.029-.937-1.029zM20 3.313v5.028H0V3.314c0-1.006.75-1.829 1.667-1.829h2.5v1.829c0 1.326.979 2.4 2.187 2.4 1.209 0 2.188-1.074 2.188-2.4V1.485h2.916v1.829c0 1.326.98 2.4 2.188 2.4 1.208 0 2.187-1.074 2.187-2.4V1.485h2.5c.917 0 1.667.823 1.667 1.829zM0 18.17c0 1.006.75 1.828 1.667 1.828h16.666C19.25 20 20 19.177 20 18.171V9.714H0v8.457z' />

            : <path fill={fill ? fill : '#1B1F21'} fillRule='evenodd'
                d='M6.354 0C5.146 0 4.167 1.004 4.167 2.244v.428h-2.5C.75 2.673 0 3.443 0 4.385V18.288C0 19.23.75 20 1.667 20h16.666C19.25 20 20 19.23 20 18.29V4.384c0-.942-.75-1.712-1.667-1.712h-2.5v-.428c0-1.24-.979-2.246-2.187-2.246-1.209 0-2.188 1.006-2.188 2.246v.428H8.542v-.428C8.542 1.005 7.562 0 6.354 0zm.938 3.315v-1.07a.946.946 0 0 0-.938-.962.946.946 0 0 0-.937.962v2.14c0 .534.416.962.937.962.521 0 .938-.428.938-.962v-1.07zm-3.125.642v.428c0 1.24.979 2.245 2.187 2.245 1.208 0 2.188-1.005 2.188-2.245v-.428h2.916v.428c0 1.24.98 2.245 2.188 2.245 1.208 0 2.187-1.005 2.187-2.245v-.428h2.5c.23 0 .417.192.417.428V9.09H1.25V4.385c0-.236.188-.428.417-.428h2.5zm10.416-.642v-1.07a.946.946 0 0 0-.937-.962.946.946 0 0 0-.938.962v2.14c0 .534.417.962.938.962.52 0 .937-.428.937-.962v-1.07zM1.25 10.374h17.5v7.914a.424.424 0 0 1-.417.428H1.667a.424.424 0 0 1-.417-.428v-7.914z'
                opacity='.4' />
        }
    </svg>
