import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Avatar } from '@material-ui/core'
import ProfileModal from '../../profile/components/ProfileModal'
import { fetchProfile } from '../../profile/services/profile.datasource'
import { useAccount } from '../../account/'
import { getInitialsFromName } from '../'
import { useAlerts } from '../../alerts'

const UserAvatar = ({ user, size }) => {
    const [open, setOpen] = useState(false)
    const [userProfile, setUserProfile] = useState({})
    const { account } = useAccount()
    const { showSnack } = useAlerts()
    const { t } = useTranslation('', i18n)


    const openUserProfile = async () => {
        if (!user.userId) {
            showSnack(t('profile.other.invalid'), 'info')
            return
        }

        if (user.userId === account.userId) {
            return
        }

        const userProfile = await fetchProfile(user.userId)
        setUserProfile(userProfile)
        setOpen(true)
    }

    return <>
        <StyledAvatar
            onClick={ openUserProfile }
            alt={ `${ user.name } profile picture` }
            src={ user.imageLink }
            size={ size }
            data-test-id='user-avatar'
        >
            { getInitialsFromName(user.name) }
        </StyledAvatar>
        { open && <ProfileModal
            open={ open }
            setOpen={ setOpen }
            profile={ userProfile }
            setProfile={ setUserProfile } />
        }
    </>
}

UserAvatar.propTypes = {
    user: PropTypes.object,
    size: PropTypes.string
}

const StyledAvatar = styled(Avatar)`
    cursor: pointer;
    height: ${({ size }) => size }px;
    width: ${({ size }) => size }px;
`

export default UserAvatar