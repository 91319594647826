import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import CheckIcon from '@material-ui/icons/Check'
import { getTimezoneAbbreviation } from '../../shared/'

const TimezoneItem = ({ timezone, isSelected, handleTimezoneClick }) => {
    const getTimezoneOffset = () => {
        return moment.tz(timezone).format('Z')
    }

    return <TimezoneItemWrapper isselected={ isSelected ? 1 : 0 } onClick={ () => handleTimezoneClick(timezone) }>
        <Offset>GMT{ getTimezoneOffset() }</Offset>
        <Name>{ timezone }</Name>
        <Abbr>{ getTimezoneAbbreviation(timezone) }</Abbr>
        <TmzCheckIcon />
    </TimezoneItemWrapper>
}

const TmzCheckIcon = styled(CheckIcon)`
    height: 20px;
    width: 20px;
`

const TimezoneItemWrapper = styled.div`
    cursor: pointer;
    display: flex;
    font-weight: ${({ isselected }) => isselected ? '600' : '400' };
    line-height: 24px;
    ${TmzCheckIcon } {
        visibility: ${({ isselected }) => isselected ? 'visible' : 'hidden' };    
    }
`

const Offset = styled.span`
    display: inline-block;
    width: 85px;
`

const Name = styled.span`
    overflow: hidden;
    display: inline-block;
    padding-right: 10px;
    text-overflow: ellipsis;
    width: calc(100% - 170px);
    white-space: nowrap;
`

const Abbr = styled.span`
    text-align: right;
    display: block;
    float: right;
    margin-right: 12px;
    width: 65px;
`

TimezoneItem.propTypes = {
    timezone: PropTypes.string,
    isSelected: PropTypes.bool,
    handleTimezoneClick: PropTypes.func
}

export default TimezoneItem