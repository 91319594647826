import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const ResponsiveDialog = ({ open, title, children, handleClose, headerComponent, hideSave,
    hideCancel, cancelText, flexTitle, disabledBtn, confirmText, handleSave, ...props }) => {
    const { t } = useTranslation('', i18n)

    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

    return (
        <div>
            <Dialog
                fullScreen={ fullScreen }
                open={ open }
                onClose={ () => handleClose() }
                aria-labelledby='responsive-dialog-title'
                data-test-id='responsive-dialog-body'
                { ...props }
            >
                <Title id='responsive-dialog-title'
                    data-test-id='responsive-dialog-title'
                    flex={ flexTitle ? 1 : 0 }
                >
                    <span> { title }</span>
                    { headerComponent }
                </Title>
                <DialogContent data-test-id='responsive-dialog-content'>
                    { children }
                </DialogContent>
                <DialogActions>
                    { !hideCancel && <CapitalizedButton
                        autoFocus
                        variant='outlined'
                        size='small'
                        onClick={ handleClose }
                        color='primary'
                        data-test-id='responsive-dialog-cancel'>
                        { cancelText || t('button.cancel') }
                    </CapitalizedButton> }
                    { !hideSave && <CapitalizedButton
                        disabled={ disabledBtn }
                        variant='contained'
                        size='small'
                        onClick={ () => handleClose('save') }
                        color='primary'
                        data-test-id='responsive-dialog-save'>
                        { confirmText || t('button.save') }
                    </CapitalizedButton> }
                </DialogActions>
            </Dialog>
        </div>
    )
}

const Title = styled(DialogTitle)`
    ${ ({ flex }) => flex && `h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }`
    }
`

const CapitalizedButton = styled(Button)`
    text-transform: capitalize;
`

ResponsiveDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.shape(),
    handleClose: PropTypes.func,
    headerComponent: PropTypes.element,
    hideSave: PropTypes.bool,
    hideCancel: PropTypes.bool,
    cancelText: PropTypes.string,
    flexTitle: PropTypes.bool,
    disabledBtn: PropTypes.bool,
    confirmText: PropTypes.string,
    handleSave: PropTypes.func
}

export default ResponsiveDialog