import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({

    button: ({ variant, type, textColor, bgColor, cursor }) => ({
        padding: type === 'tag' ? theme.spacing(0.5) + 'px ' + theme.spacing(3) + 'px' : theme.spacing(0),
        textTransform: 'capitalize',
        fontWeight: 500,
        color: textColor,
        backgroundColor: bgColor,
        cursor: cursor === false ? 'unset' : 'pointer',
        border: variant === 'contained' ? 0 : '1px solid ' + textColor,
        '&:hover': {
            backgroundColor: cursor === false ? 'transparent' : bgColor,
            '@media (hover: none)': {
                backgroundColor: cursor === false ? 'transparent' : bgColor,
            },
        },
    }),
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}))



const LabelTypeButton = ({ variant, type, textColor, bgColor, cursor, onClick, children }) => {
    const classes = useStyles({ variant, type, textColor, bgColor, cursor })
    return (

        <Button variant={variant ? variant : 'outlined'}
            onClick={onClick} disableElevation={true}
            color="primary"
            disableRipple={true}
            size="small" className={classes.button}>
            {children}
        </Button>

    )
}

LabelTypeButton.propTypes = {
    variant: PropTypes.string,
    type: PropTypes.string,
    textColor: PropTypes.string,
    bgColor: PropTypes.string,
    cursor: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
}

export default LabelTypeButton