import { apiService } from '../../shared'

const getEventRsvpData = async (eventId, skipNumber) => {
    return await apiService.get(`/events/${eventId}/userEventStatuses`, { resultsPerPage: 10, skipNumber: skipNumber, sortKey: 'name' })
}

const getEventUserStatusData = async (eventId) => {
    return await apiService.get(`/events/${eventId}/userEventStatus`)
}

const getRsvps = async () => {
    return await apiService.get('/rsvp')
}

const postMyRsvp = async (status, attendanceStatus, rsvpId) => {
    return await apiService.put('/userEventStatuses/' + rsvpId, { statuses: { rsvp: status, attendance: attendanceStatus } })
}

export {
    getEventRsvpData,
    getEventUserStatusData,
    getRsvps,
    postMyRsvp
}   