import React from 'react'
import styled from 'styled-components'
import { NoMatchPageIcon } from '../assets/NotMatchPageIcon'
import { Container, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import i18n from '../../../i18n'

const NoMatchPage = () => {
    const { t } = useTranslation('', i18n)

    return <NoMatchContainer>
        <NoMatchPageIcon />
        <NoMatchText>{t('emptyMessages.404')}</NoMatchText>
    </NoMatchContainer>
}

const NoMatchContainer = styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
`

const NoMatchText = styled(Typography)`
    font-size: 24px;
    margin: 24px 0;
`

export default NoMatchPage