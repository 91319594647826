import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

function TabContainer({ children, dir }) {
  return (
    <Typography component='div' dir={dir} style={{ paddingTop: 8 * 3 }}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%'
  },
  bar: {
    backgroundColor: theme.palette.background.paper,

  },
  tabText: {
    textTransform: 'Capitalize',
    color: theme.palette.text.disabled,
    fontWeight: 500,
    fontSize: theme.spacing(2)
  },
  tabs: {
    paddingLeft: theme.spacing(1)
  }
}))


function FullWidthTabs({ tabItems, labels }) {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  function handleChangeIndex(index) {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <AppBar position='static' className={classes.bar} elevation={0} >
        <Tabs

          value={value}
          onChange={(e, index) => { handleChangeIndex(index) }}
          indicatorColor='primary'
          textColor='primary'
          className={classes.tabs}
          variant={null}
        >

          {labels.map((label, index) => <Tab key={index} label={label} className={classes.tabText} />
          )}

        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabItems.map((tabItem, index) => <TabContainer key={index} dir={theme.direction}>{tabItem}</TabContainer>
        )}

      </SwipeableViews>
    </div>
  )
}

FullWidthTabs.propTypes = {
  tabItems: PropTypes.array,
  labels: PropTypes.array
}

export default FullWidthTabs