import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_EVENTS, setEvents, getEventsData, QUERY_EVENTS, queryEventsData } from '..'

function* fetchEvents(action) {
    const response = yield call(getEventsData, action.payload)
    const eventsData = response.data
    yield put(setEvents(eventsData, action.payload.direction))
}

function* fetchEventsForQuery(action) {
    const response = yield call(queryEventsData, action.payload)
    const eventsData = response.data
    yield put(setEvents(eventsData, undefined, action.payload.searchString))
}

export default function* eventsFetchSaga() {
    yield takeLatest(GET_EVENTS, fetchEvents)
    yield takeLatest(QUERY_EVENTS, fetchEventsForQuery)
}


