import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import styled from 'styled-components'
import ProfileProvider, { useProfile } from '../context/ProfileProvider'
import { Container, Grid } from '@material-ui/core'
import { Loader, TopBar } from '../../shared'
import { useHistory } from 'react-router'
import ProfileBasicInfo from '../components/ProfileBasicInfo'
import ProfileDescription from '../components/ProfileDescription'
import ProfileUserDetails from '../components/ProfileUserDetails'
import ProfileSocialProfiles from '../components/ProfileSocialProfiles'
import IncompleteProfile from '../components/IncompleteProfile'

const ProfileScreen = () => {
  const { profile, isLoading } = useProfile()
  const { t } = useTranslation('', i18n)
  const history = useHistory()

  const goToEditProfile = () => history.push('/profile/edit')
  const ownProfile = {
    ...profile,
    privacy: {
      email: true,
      phoneNumber: true,
      linkedIn: true,
      facebook: true
    }
  }

  return <StyledGrid
    container
    direction='column'
    justify='center'
    alignItems='center'
  >
    <TopBar
      name={ t('screenTitle.profile') }
      hideCreateButton
      hideSearchBar
      editCallback={ goToEditProfile }
      data-test-id='profile-title'
    />

    { isLoading ? <Loader /> :
      <BodyContainer spacing={ 0 }>
        <LeftContainer item xs={ 12 } md={ 6 } lg={ 6 }>
          <ProfileBasicInfo profile={ profile } />
          <ProfileDescription description={ profile.description } title={ t('profile.sections.aboutMe') } />
          <ProfileUserDetails profile={ ownProfile } />
          <ProfileSocialProfiles profile={ ownProfile } />
          <IncompleteProfile isComplete={ profile?.hasUpdatedProfile } />
        </LeftContainer>
      </BodyContainer>
    }
  </StyledGrid>
}

const StyledGrid = styled(Grid)`
  padding-bottom: 10px;
`

const LeftContainer = styled(Grid)`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BodyContainer = styled(Container)`
  overflow: hidden;
  padding: 0px 16px;
`

export default () => {
  return <>
    <ProfileProvider>
      <ProfileScreen />
    </ProfileProvider>
  </>
}