import React, { useContext, useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { AppGlobalContext } from '../../managers/AppManager'
import MessagesManager from '../../managers/MessagesManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import CreateMessageManager from '../../managers/CreateMessageManager'
import TopBar from './TopBar'
import ConversationMessagesList from './ConversationMessagesList'
import CreateMessage from './CreateMessage'
import useWindowSize from '../../hooks/windowSizeHook'
import LoadingLayer from '../common/LoadingLayer'

import useIntegrationCompat from '../../hooks/integrationCompatHook'

const ConversationWindow = ({ isMobile }) => {
    const { isIntegrated } = useIntegrationCompat()

    const { isConversationLoading, openedConversation, setOpenedConversation, openConversation, closeConversation }
        = useContext(ConversationsContext)

    const { userChannel } = useContext(AppGlobalContext)
    const { listMaxHeight, setSiblingHeight } = useWindowSize(121)
    let refOpenedConversation = useRef(openedConversation)

    const handleConversationDeleted = (data) => {
        if (refOpenedConversation.current.conversationId === data.conversationId) {
            closeConversation()
        }
    }

    const handleConversationsEvent = (data) => {
        if (data.conversationId !== refOpenedConversation.current.conversationId) {
            return;
        }

        switch (data.type) {
            case 'hidden':
            case 'deleted':
                handleConversationDeleted(data)
                return
            case 'updated':
                openConversation(data.conversationId)
                return
            default:
                return
        }
    }

    const manageListHeight = () => {
        if (openedConversation.status === 'unavailable') {
            setSiblingHeight(62)
        } else {
            setSiblingHeight(132)
        }
    }

    useEffect(() => {
        if (_.isEmpty(userChannel)) {
            return
        }

        userChannel.bind('conversations', handleConversationsEvent)

        return () => {
            userChannel.unbind('conversations', handleConversationsEvent)
        }
    }, [userChannel])

    useEffect(() => {
        refOpenedConversation.current = openedConversation
        manageListHeight()
    }, [openedConversation])

    useEffect(() => {
        return (() => {
            setOpenedConversation({})
        })
    }, [])

    return (
        <div className='full-height' data-testid='conversation-window'>
            { !_.isEmpty(openedConversation) &&
                <div className='full-height'>
                    <TopBar
                        conversation={ openedConversation }
                        onClose={ closeConversation }
                        closeLink='/conversations'
                        isMobile={ isMobile }
                    />
                    <MessagesManager>
                        <Fragment>
                            <ConversationMessagesList
                                listMaxHeight={ listMaxHeight }
                                data-testid='conversation-content-wrapper' />
                            { openedConversation.status !== 'unavailable' &&
                                <CreateMessageManager>
                                    <CreateMessage setSiblingHeight={ setSiblingHeight } isMobile={ isMobile } />
                                </CreateMessageManager>
                            }
                        </Fragment>
                    </MessagesManager>
                </div>
            }
            { isConversationLoading && <LoadingLayer position={ isIntegrated ? 'relative' : 'absolute' } /> }
        </div>
    )
}

ConversationWindow.propTypes = {
    isMobile: PropTypes.bool
}

export default ConversationWindow