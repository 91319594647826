import { apiService, HttpError } from '../../shared'
import moment from 'moment-timezone'

const getEventsData = async (payload) => {
    let queryParams = {}
    queryParams.pageToken = payload.pageToken
    queryParams.fields = ['kind', 'location']
    queryParams.maxResults = 50
    queryParams.showDeleted = false

    queryParams[payload.direction === 'backward' ? 'timeMax' : 'timeMin'] = payload.cutOffDate ? new Date(moment(payload.cutOffDate).startOf('day').format('MM/DD/YYYY')).toISOString() : new Date().toISOString()
    queryParams.kind = 'classic'
    return !payload.calendarId ? await apiService.get('/events', queryParams)
        : await apiService.get(`/calendars/${payload.calendarId}/events`, queryParams)
}

const queryEventsData = async (payload) => {
    let queryParams = {}
    queryParams.fields = ['kind']
    queryParams.q = payload.searchString
    queryParams.kind = 'classic'
    queryParams.showDeleted = false
    return !payload.calendarId ? await apiService.get('/events', queryParams)
        : await apiService.get(`/calendars/${payload.calendarId}/events`, queryParams)
}

const createEvent = async event => {
    const { data, ok, status } = await apiService.post('event', event)

    if (!ok) {
        throw new HttpError(status)
    }

    return data
}

const editEvent = async event => {
    const { data, ok, status } = await apiService.put('event', event)

    if (!ok) {
        throw new HttpError(status)
    }

    return data
}

const deleteEvent = async (eventId, calendarId) => {
    const { ok, status } = await apiService.delete('event', {
        calendarId,
        eventId: eventId,
        parentId: '' // ruling out removing multiple instances for repeating events
    })

    if (!ok) {
        throw new HttpError(status)
    }
}

const getEventDetails = async (eventId, calendarId) => {
    const { data, ok, status } = await apiService.get('event', { eventId, calendarId })

    if (!ok) {
        throw new HttpError(status)
    }

    return data
}

const inviteUser = async (details) => {
    const { data, ok, status } = await apiService.post('share/event', { ...details })

    if (status === 429) {
        return status
    }

    verifyOk(ok, status)

    return data
}

const removeUser = async ({ groupId, eventId, rsvpId }) => {
    const { data } = await apiService.delete('share/event', { groupId, eventId, rsvpId })

    return data
}

const verifyOk = (ok, status) => {
    if (!ok) {
        throw new HttpError(status)
    }
}

export {
    getEventsData,
    queryEventsData,
    createEvent,
    editEvent,
    deleteEvent,
    getEventDetails,
    inviteUser,
    removeUser
}