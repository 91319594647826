import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/ro'
import 'moment/locale/en-gb'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import useAsyncEffect from '@n1ru4l/use-async-effect'
import ClockIcon from '../../shared/assets/ClockIcon'
import { Typography, Grid } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { EventSchema } from '../schemas/event.schema'
import TimezonePicker from '../components/TimezonePicker'
import dpTheme from '../../config/datePickerTheme'
import { getRecentTimezones, useAccount } from '../../account/'
import { FormikSwitchFieldGroup } from '../../shared/'

const TemporalSettings = ({ event, handleAllDaySwitch, handleEventDateChange, handleTimezoneSelect, handleBlur }) => {
    const [recentTimezones, setRecentTimezones] = useState([])
    const { account } = useAccount()

    const { t } = useTranslation('', i18n)
    const handleStartChange = date => handleEventDateChange('start', date)
    const handleEndChange = date => handleEventDateChange('end', date)
    let locale = 'en-gb'

    switch (navigator.language) {
        case 'en-US':
            locale = 'en-gb'
            break
        default:
            locale = navigator.language
    }

    moment.locale(locale)

    useAsyncEffect(function* () {
        const response = yield getRecentTimezones(account.userId)
        setRecentTimezones(response.recentTimezones.map(tmz => tmz.name))
    }, [])

    return <div>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
            <AllDay>
                <AllDayClockIcon width={20} height={20} data-test-id='allday-icon' />
                <AllDayContent>
                    <FormikSwitchFieldGroup
                        label={t('event.allDay')}
                        name='isAllDay'
                        color='primary'
                        onChange={ev => handleAllDaySwitch(ev.target.checked)}
                        checked={event.isAllDay}
                        inputProps={{ 'aria-labelledby': 'switch-all-day' }}
                    />
                </AllDayContent>
            </AllDay>
            <TimeLimit data-test-id='event-start-date'>
                <Grid item xs={3}>
                    <Typography data-test-id='start-date-label'>
                        {event.isAllDay ? t('event.startDate') : t('event.startTime')}
                    </Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={dpTheme}>
                        {event.isAllDay ?
                            <DatePicker
                                value={event.startDate}
                                format={'MMM DD'}
                                variant='inline'
                                onChange={handleStartChange}
                                onBlur={() => handleBlur('startDate', true, false)}
                                data-test-id='start-date-picker'
                            /> :
                            <>
                                <DateTimePicker
                                    value={event.startDate}
                                    format={'MMM DD'}
                                    openTo='date'
                                    variant='inline'
                                    onChange={handleStartChange}
                                    onBlur={() => handleBlur('startDate', true, false)}
                                    data-test-id='start-date-picker'
                                />
                                <DateTimePicker
                                    value={event.startDate}
                                    format={'hh:mm A'}
                                    openTo='hours'
                                    variant='inline'
                                    onChange={handleStartChange}
                                    onBlur={() => handleBlur('startDate', true, false)}
                                    data-test-id='start-time-picker'
                                />
                                <TimezonePicker limit='start' event={event} recentTimezones={recentTimezones}
                                    handleTimezoneSelect={handleTimezoneSelect} />
                            </>
                        }
                    </ThemeProvider>
                </Grid>
            </TimeLimit>
            <TimeLimit data-test-id='event-end-date'>
                <Grid item>
                    <Typography data-test-id='end-date-label'>
                        {event.isAllDay ? t('event.endDate') : t('event.endTime')}
                    </Typography>
                </Grid>
                <Grid item>
                    <ThemeProvider theme={dpTheme}>
                        {event.isAllDay ?
                            <DatePicker
                                value={event.endDate}
                                format={'MMM DD'}
                                variant='inline'
                                minDate={moment(event.startDate)}
                                onChange={handleEndChange}
                                onBlur={() => handleBlur('endDate', true, false)}
                                data-test-id='end-date-picker'
                            /> :
                            <>
                                <DateTimePicker
                                    value={event.endDate}
                                    format={'MMM DD'}
                                    openTo='date'
                                    variant='inline'
                                    minDate={moment(event.startDate)}
                                    onChange={handleEndChange}
                                    onBlur={() => handleBlur('endDate', true, false)}
                                    data-test-id='end-date-picker'
                                />
                                <DateTimePicker
                                    value={event.endDate}
                                    format={'hh:mm A'}
                                    openTo='hours'
                                    variant='inline'
                                    minDate={moment(event.startDate)}
                                    onChange={handleEndChange}
                                    onBlur={() => handleBlur('endDate', true, false)}
                                    data-test-id='end-time-picker'
                                />
                                <TimezonePicker limit='end' event={event} recentTimezones={recentTimezones}
                                    handleTimezoneSelect={handleTimezoneSelect} />
                            </>
                        }
                    </ThemeProvider>
                </Grid>
            </TimeLimit>
        </MuiPickersUtilsProvider>
    </div>
}

const AllDay = styled.div`
    align-items: center;
    display: flex;
        
    label {
        justify-content: space-between;
        margin: 0;
        width: 100%;
    }
`

const AllDayContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const AllDayClockIcon = styled(ClockIcon)`
    margin-right: 18px;
`

const TimeLimit = styled(Grid)`
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    margin: 10px 0 10px 38px;
`

TemporalSettings.propTypes = {
    event: PropTypes.shape(EventSchema),
    handleAllDaySwitch: PropTypes.func,
    handleEventDateChange: PropTypes.func,
    handleTimezoneSelect: PropTypes.func,
    handleBlur: PropTypes.func
}

export default TemporalSettings