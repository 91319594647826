import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'

import { DefaultTheme, ReduxStore } from './config'
import { AccountProvider, ResolverProvider } from './account'
import { AuthenticationLayer, Navigator } from './navigation'
import { AlertProvider } from './alerts'

import ChatAppManager from 'chat-app/src/managers/AppManager'
import ChatConversationsManager from 'chat-app/src/managers/ConversationsManager'

function App() {
    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={DefaultTheme}>
                <BrowserRouter>
                    <CssBaseline />

                    <StyledThemeProvider theme={DefaultTheme}>
                        <Provider store={ReduxStore}>
                            <AlertProvider>
                                <AccountProvider>
                                    { // Chat integration
                                        <ChatAppManager>
                                            <ChatConversationsManager>
                                                <AuthenticationLayer>
                                                    <ResolverProvider>

                                                        <Navigator />

                                                    </ResolverProvider>
                                                </AuthenticationLayer>
                                            </ChatConversationsManager>
                                        </ChatAppManager>
                                    }
                                </AccountProvider>
                            </AlertProvider>
                        </Provider>
                    </StyledThemeProvider>
                </BrowserRouter>
            </ThemeProvider>
        </StylesProvider>
    )
}

export default App
