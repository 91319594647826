import React from 'react'
import propType from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { ImageUpload } from '../../shared'
import { useCalendarManager } from '..'

const CalendarImgUpload = ({ imageUrl }) => {
    const { t } = useTranslation('', i18n)
    const { croppedImg, setCroppedImg } = useCalendarManager()

    return <ImageUpload
        imageUrl={ imageUrl }
        text={ t('calendar.create.image') }
        croppedImg={ croppedImg }
        setCroppedImg={ setCroppedImg }
    />

}

CalendarImgUpload.propTypes = {
    imageUrl: propType.string,
}


export default CalendarImgUpload