import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import { TextField, InputAdornment } from '@material-ui/core'

const SearchFieldBar = ({ searchFieldRef, searchPlaceholder, searchCallback, size }) => {

    return (
        <SearchBar screensize={size} data-test-id='search-bar'>
            <SearchField
                inputRef={searchFieldRef}
                placeholder={searchPlaceholder}
                size='small'
                screensize={size}
                variant='outlined'
                onChange={searchCallback}
                InputProps={{
                    'aria-label': 'search',
                    startAdornment: <InputAdornment position='start'>
                        <SearchIcon data-test-id='search-calendars-icon' />
                    </InputAdornment>,
                }}
                data-test-id='search-field'
            />
        </SearchBar>
    )
}


const SearchBar = styled.div`
    ${({ screensize }) => screensize === 'smDown' && 'margin-top: 16px;'}
`

const SearchField = styled(TextField)`
    width: 326px;
    
    && {
        ${({ screensize }) => screensize === 'smDown' && 'width: 100%;'}
    }

    input::placeholder {
        color: ${ ({ theme }) => theme.palette.text.disabled};
    }

    fieldset {
        border-color: ${ ({ theme }) => theme.palette.divider};
    }

    svg {
        color: ${ ({ theme }) => theme.palette.divider};
    }
`

SearchFieldBar.propTypes = {
    searchPlaceholder: propTypes.string,
    searchCallback: propTypes.func,
    size: propTypes.string,
    searchFieldRef: propTypes.object
}

export default SearchFieldBar