import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/**
 * A full width div
 * @constructor
 */
const FullWidth = ({ children }) => {
    return <Container>
        {children}
    </Container>
}

FullWidth.propTypes = {
    children: PropTypes.object
}

export default FullWidth

const Container = styled.div`
  width: 100%;
`
